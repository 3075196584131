import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import { fetchUserCourses } from '../../services/CourseService';
import { fetchUserDetails } from '../../services/UserService';
import CreateCourseButton from '../../components/Button/CreateCourseButton';
import CourseList from '../../components/Lists/CourseList';

export default function MainPage() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState('');

  // Load data on mount
  useEffect(() => {
    async function fetchData() {
      try {
        const userDetails = await fetchUserDetails();
        setUserName(userDetails.username);

        const fetchedCourses = await fetchUserCourses();
        setCourses(fetchedCourses);

      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const handleCourseCreated = async () => {
    setIsLoading(true);
    try {
      const fetchedCourses = await fetchUserCourses();
      setCourses(fetchedCourses);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxWidth="false"
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Top area (e.g., create course button) */}
      <Box
        alignItems="center"
        display="flex"
        sx={{ flexGrow: 1, paddingTop: 8 }}
      >
        <CreateCourseButton
          onCourseCreated={handleCourseCreated}
          color="primary"
        />
      </Box>

      {/* Course list */}
      <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12}>
          <CourseList
            courses={courses}
            isLoading={isLoading}
            onCourseSelect={handleCourseClick}
            onCourseUpdated={handleCourseCreated}
            title="Meine Kurse" // optional
          />
        </Grid>
      </Grid>
    </Container>
  );
}
