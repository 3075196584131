import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { fetchSecureImage, revokeBlobUrl } from '../utils/secureImageUtils';
import ENDPOINTS from '../utils/apiConfig';

/**
 * SecureImage component that fetches and displays images with authentication
 * 
 * @param {Object} props
 * @param {number} props.fileId - The ID of the file
 * @param {number} props.imageId - The ID of the image
 * @param {string} props.alt - Alt text for the image
 * @param {Object} props.sx - Style props for the image
 * @param {Function} props.onClick - Click handler for the image
 */
const SecureImage = ({ fileId, imageId, alt = '', sx = {}, onClick, ...props }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const loadImage = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        // Build the secure image URL
        const apiUrl = ENDPOINTS.GET_IMAGE(fileId, imageId);
        
        // Fetch the image securely
        const blobUrl = await fetchSecureImage(apiUrl);
        
        if (isMounted) {
          setImageUrl(blobUrl);
          setIsLoading(false);
        }
      } catch (err) {
        console.error('Failed to load secure image:', err);
        if (isMounted) {
          setError(err.message || 'Failed to load image');
          setIsLoading(false);
        }
      }
    };

    loadImage();

    // Cleanup function
    return () => {
      isMounted = false;
      if (imageUrl) {
        revokeBlobUrl(imageUrl);
      }
    };
  }, [fileId, imageId]);

  // Show loading state
  if (isLoading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: sx.height || '100px',
          width: sx.width || '100%',
          ...sx 
        }}
      >
        <CircularProgress size={24} />
      </Box>
    );
  }

  // Show error state
  if (error) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: sx.height || '100px',
          width: sx.width || '100%',
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          color: 'error.main',
          ...sx 
        }}
      >
        <Typography variant="caption">Image not available</Typography>
      </Box>
    );
  }

  // Show the image
  return (
    <Box
      component="img"
      src={imageUrl}
      alt={alt}
      onClick={onClick}
      sx={{ 
        objectFit: 'contain',
        ...sx 
      }}
      {...props}
    />
  );
};

export default SecureImage; 