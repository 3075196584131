import { createTheme } from '@mui/material/styles';

// Color palette definition with various shade levels
const palette = {
  // Primary colors (yellows)
  primary: {
    50: '#FFF7E6',
    100: '#FFF1D1',
    200: '#FFE4A8',
    300: '#FFD780',
    400: '#FFCA57',
    500: '#FFBD2E', // Main primary color
    600: '#F5A800',
    700: '#BD8100',
    800: '#855B00',
    900: '#4D3400',
    950: '#312100',
  },
  
  // Secondary colors (purples)
  secondary: {
    50: '#EFE1FF',
    100: '#E4CCFF',
    200: '#CFA3FF',
    300: '#B97BFF',
    400: '#A452FF',
    500: '#8E29FF', // Main secondary color
    600: '#7100F0',
    700: '#5700B8',
    800: '#3C0080',
    900: '#220048',
    950: '#15002C',
  },
  
  // Neutral colors (grays)
  neutral: {
    50: '#FBFBFB',
    100: '#F1F1F0',
    200: '#DEDEDA',
    300: '#CBC8C5',
    400: '#B7B4AF',
    500: '#A4A09A', // Main neutral color
    600: '#89847C',
    700: '#6C6862',
    800: '#4F4C47',
    900: '#312F2C',
    950: '#22211F',
  },
};

// Create theme with the palette
const publicTheme = createTheme({
  typography: {
    fontFamily: 'Nunito, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.75rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  
  palette: {
    // Standard MUI palette structure
    primary: {
      main: palette.primary[500],
      light: palette.primary[300],
      dark: palette.primary[700],
      contrastText: palette.neutral[950],
    },
    secondary: {
      main: palette.secondary[500],
      light: palette.secondary[300],
      dark: palette.secondary[700],
      contrastText: '#FFFFFF',
    },
    error: {
      main: palette.secondary[700],
      light: palette.secondary[500],
      dark: palette.secondary[900],
    },
    warning: {
      main: palette.primary[600],
      light: palette.primary[400],
      dark: palette.primary[800],
    },
    info: {
      main: palette.secondary[500],
      light: palette.secondary[300],
      dark: palette.secondary[700],
    },
    success: {
      main: palette.primary[600],
      light: palette.primary[400],
      dark: palette.primary[800],
    },
    text: {
      primary: palette.neutral[950],
      secondary: palette.neutral[800],
      disabled: palette.neutral[500],
    },
    background: {
      default: palette.neutral[50],
      paper: '#FFFFFF',
    },
    // Making the full palette accessible through a custom property
    colors: palette,
  },
  
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          padding: '8px 24px',
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          color: palette.neutral[950],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
  
  // Custom gradients - going from bottom (darker) to top (lighter)
  gradients: {
    primaryToLight: `linear-gradient(to top, ${palette.primary[700]} 0%, ${palette.primary[300]} 100%)`,
    primaryToDark: `linear-gradient(to top, ${palette.primary[700]} 0%, ${palette.primary[100]} 100%)`,
    secondaryToLight: `linear-gradient(to top, ${palette.secondary[700]} 0%, ${palette.secondary[300]} 100%)`,
    secondaryToDark: `linear-gradient(to top, ${palette.secondary[700]} 0%, ${palette.secondary[100]} 100%)`,
    primaryToSecondary: `linear-gradient(to top, ${palette.secondary[600]} 0%, ${palette.primary[300]} 100%)`,
    
    // Light gradients (from 50 to white)
    primaryLight: `linear-gradient(to top, ${palette.primary[50]} 0%, #FFFFFF 100%)`,
    secondaryLight: `linear-gradient(to top, ${palette.secondary[50]} 0%, #FFFFFF 100%)`,
  },
});

export default publicTheme;

// Helper functions to access the palette in components
export const primaryColor = (shade = 500) => palette.primary[shade];
export const secondaryColor = (shade = 500) => palette.secondary[shade];
export const neutralColor = (shade = 500) => palette.neutral[shade];

// Usage example in components:
// import { primaryColor, secondaryColor, neutralColor } from '../styles/publicTheme';
// <Box sx={{ backgroundColor: primaryColor(200), color: secondaryColor(800) }}> 