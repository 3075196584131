// src/App/App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider, useThemeContext } from '../contexts/ThemeContext';
import { AuthProvider } from '../contexts/AuthContext';
import { SidebarProvider } from '../contexts/SidebarContext';
import { FeedbackButtonProvider } from '../contexts/FeedbackButtonContext';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, CircularProgress } from '@mui/material';

import LandingPage from '../pages/LandingPage/LandingPage'; 
// ^-- This is your *single* landing page component

import RegistrationPage from '../pages/RegistrationPage/RegistrationPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import TosPage from '../pages/TosPage/TosPage';
import MainPage from '../pages/MainPage/MainPage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import ConfirmResetPasswordPage from '../pages/ConfirmResetPasswordPage/ConfirmResetPasswordPage';
import ConfirmEmailPage from '../pages/ConfirmEmailPage/ConfirmEmailPage';
import NotFound from '../pages/NotFound';
import AccountActivatedPage from '../pages/AccountActivatedPage/AccountActivatedPage';
import ActivationErrorPage from '../pages/ActivationErrorPage/ActivationErrorPage';
import CoursePage from '../pages/CoursePage/CoursePage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ImprintPage from '../pages/ImprintPage/ImprintPage';
import ProtectedRoute from '../utils/ProtectedRoute';
import AppLayout from '../layouts/AppLayout';
import PublicLayout from '../layouts/PublicLayout';
import { fetchUserDetails } from '../services/UserService';

// A simple "Desktop Only" page that auto-redirects to "/" on mobile
function MobileBlockPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to "/" after 3 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div style={{
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center',
      height: '100vh', 
      padding: '16px',
      textAlign: 'center'
    }}>
      <h2>Diese Seite ist leider nur auf dem Desktop verfügbar.</h2>
      <p>Bitte wechsle zu einem größeren Bildschirm.</p>
      <p>Du wirst in wenigen Sekunden zur Startseite weitergeleitet.</p>
    </div>
  );
}

// ThemeContent wraps the entire app to ensure theme is loaded before rendering
// This adds an extra layer of protection against seeing the default theme flash
function ThemeContent({ children }) {
  // This component will only render after the ThemeContext is ready
  return children;
}

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  // (Optional) Load user details if needed
  useEffect(() => {
    const loadUserDetails = async () => {
      try {
        const userDetails = await fetchUserDetails();
        // do something with userDetails if needed
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    loadUserDetails();
  }, []);

  return (
    <AuthProvider>
      <ThemeProvider>
        <ThemeContent>
          <SidebarProvider>
            <FeedbackButtonProvider>
              <CssBaseline />
              <Routes>
                {/* Public routes wrapped in PublicLayout (with footer) - accessible on all devices */}
                <Route element={<PublicLayout />}>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/register" element={<RegistrationPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/tos" element={<TosPage />} />
                  <Route path="/reset-password" element={<ResetPasswordPage />} />
                  <Route path="/confirm-reset-password" element={<ConfirmResetPasswordPage />} />
                  <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                  <Route path="/account-activated" element={<AccountActivatedPage />} />
                  <Route path="/error" element={<ActivationErrorPage />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                  <Route path="/imprint" element={<ImprintPage />} />
                </Route>

                {/* Protected routes that use the shared app layout */}
                {!isMobile ? (
                  <Route element={
                    <ProtectedRoute>
                      <AppLayout />
                    </ProtectedRoute>
                  }>
                    <Route path="/main" element={<MainPage />} />
                    <Route path="/course/:courseId" element={<CoursePage />} />
                  </Route>
                ) : (
                  /* Mobile fallback for protected routes */
                  <>
                    <Route path="/main" element={<MobileBlockPage />} />
                    <Route path="/course/:courseId" element={<MobileBlockPage />} />
                  </>
                )}

                {/* 404 */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </FeedbackButtonProvider>
          </SidebarProvider>
        </ThemeContent>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
