import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, CircularProgress, Menu, MenuItem, IconButton, Button } from '@mui/material';
import EditFileButton from '../Button/EditFileButton';
import DeleteFileButton from '../Button/DeleteFileButton';
import DownloadFileButton from '../Button/DownloadFileButton';
import Icon from '../../components/Icon';
import { useTheme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Helper: check if file fully processed
const isFileFullyProcessed = (file) =>
  file?.text_status === 'completed' &&
  file?.images_status === 'completed' &&
  file?.metadata_status === 'completed';

// Sorting options constants
const SORT_BY_DATE_NEWEST = 'newest';
const SORT_BY_DATE_OLDEST = 'oldest';
const SORT_BY_ALPHABET = 'alphabet';

const FileList = ({
  files,
  filesError,
  isLoading,
  inProgressFileIds = [],
  onFileClick,
  onFileUpdated,
  courseName,
  fileControlComponent,
  style,
  showEditAndDeleteButtons = true
}) => {
  const theme = useTheme();
  const [recentlyFinishedFileIds, setRecentlyFinishedFileIds] = useState([]);
  const [sortedFiles, setSortedFiles] = useState([]);
  const [sortOption, setSortOption] = useState(SORT_BY_DATE_NEWEST);
  
  // Sorting menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleSortMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };
  
  const handleSortOptionSelect = (option) => {
    setSortOption(option);
    handleSortMenuClose();
  };

  // Sort files whenever files or sortOption changes
  useEffect(() => {
    if (!files || files.length === 0) {
      setSortedFiles([]);
      return;
    }
    
    const filesCopy = [...files];
    
    if (sortOption === SORT_BY_DATE_NEWEST) {
      // Sort by upload date, newest first
      filesCopy.sort((a, b) => {
        return new Date(b.upload_date) - new Date(a.upload_date);
      });
    } else if (sortOption === SORT_BY_DATE_OLDEST) {
      // Sort by upload date, oldest first
      filesCopy.sort((a, b) => {
        return new Date(a.upload_date) - new Date(b.upload_date);
      });
    } else if (sortOption === SORT_BY_ALPHABET) {
      // Sort alphabetically by file name
      filesCopy.sort((a, b) => {
        return a.file_name.localeCompare(b.file_name);
      });
    }
    
    setSortedFiles(filesCopy);
  }, [files, sortOption]);

  useEffect(() => {
    // Whenever files or inProgressFileIds change...
    files.forEach((file) => {
      const isFinished = isFileFullyProcessed(file);
      const wasInProgress = inProgressFileIds.includes(file.id);

      if (isFinished && wasInProgress) {
        // Mark file as recently finished => show "Datei ist bereit!" for 3s
        setRecentlyFinishedFileIds((prev) =>
          prev.includes(file.id) ? prev : [...prev, file.id]
        );

        setTimeout(() => {
          setRecentlyFinishedFileIds((prev) => prev.filter((id) => id !== file.id));
        }, 3000);
      }
    });
  }, [files, inProgressFileIds]);

  return (
    <Paper
      elevation={4}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: { md: '400px', lg: '500px', xl: '600px' },
        borderRadius: '20px',
        width: '100%',
       
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        backgroundColor: 'background.paper',
        width: '100%',
        mb: 2
      }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'left',
            pl: 1,
            backgroundColor: 'background.paper',
          }}
        >
          {courseName} - Meine Dateien
        </Typography>
        
        {/* Sort menu */}
        <Box>
          <Button
            id="sort-button"
            aria-controls={open ? 'sort-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleSortMenuClick}
            endIcon={<ArrowDropDownIcon sx={{ fontSize: '1rem', ml: -0.5 }} />}
            sx={{ 
              textTransform: 'none',
              fontSize: '0.85rem',
              fontWeight: 'normal',
              color: 'text.secondary',
              padding: '2px 5px 2px 8px',
              minWidth: 'auto', 
              width: 'fit-content',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              },
              '& .MuiButton-endIcon': {
                marginLeft: '3px'
              }
            }}
          >
            Sortieren
          </Button>
          <Menu
            id="sort-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleSortMenuClose}
            MenuListProps={{
              'aria-labelledby': 'sort-button',
              dense: true
            }}
            PaperProps={{
              sx: {
                borderRadius: '20px',
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                mt: 1
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem 
              onClick={() => handleSortOptionSelect(SORT_BY_ALPHABET)}
              selected={sortOption === SORT_BY_ALPHABET}
              sx={{ 
                fontSize: '0.85rem',
                py: 0.8,
                minHeight: 0
              }}
            >
              Alphabetisch
            </MenuItem>
            <MenuItem 
              onClick={() => handleSortOptionSelect(SORT_BY_DATE_NEWEST)}
              selected={sortOption === SORT_BY_DATE_NEWEST}
              sx={{ 
                fontSize: '0.85rem',
                py: 0.8,
                minHeight: 0
              }}
            >
              Neueste zuerst
            </MenuItem>
            <MenuItem 
              onClick={() => handleSortOptionSelect(SORT_BY_DATE_OLDEST)}
              selected={sortOption === SORT_BY_DATE_OLDEST}
              sx={{ 
                fontSize: '0.85rem',
                py: 0.8,
                minHeight: 0
              }}
            >
              Älteste zuerst
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Box
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
          '&::-webkit-scrollbar': { width: '0.5em' },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            borderRadius: '4px',
          }
        }}
      >
        {/* Loading State */}
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100%',
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : filesError ? (
          <Typography
            sx={{
              mt: 2,
              textAlign: 'left',
              width: '100%',
              color: 'text.secondary',
              pl: 1,
              pb: 2
            }}
          >
            Error: {filesError}
          </Typography>
        ) : sortedFiles.length > 0 ? (
          sortedFiles.map((file) => {
            const isFinished = isFileFullyProcessed(file);
            const isInProgress = inProgressFileIds.includes(file.id);
            const showReadyMessage = recentlyFinishedFileIds.includes(file.id);

            return (
              <Box
                key={file.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 2,
                  p: 2,
                  width: '100%',
                  '&:hover': {
                    backgroundColor: 'grey.200',
                    cursor: 'pointer'
                  }
                }}
                onClick={() => onFileClick(file)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    maxWidth: 'calc(100% - 48px)',
                    overflow: 'hidden'
                  }}
                >
                  {/* If you passed a custom fileControlComponent, use it, else show default icon */}
                  {fileControlComponent ? (
                    fileControlComponent(file)
                  ) : (
                    <Icon iconName="file" sx={{ color: theme.palette.primary.main }} />
                  )}

                  {/* File Name */}
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    {file.file_name}
                  </span>

                  {/* Spinner + status text if in progress */}
                  { !isFinished && (
                    <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                      <CircularProgress
                        size={20}
                        sx={{ color: (theme) => theme.palette.grey[600] }}
                      />
                      {file.text_status !== 'completed' ? (
                        <Typography sx={{ ml: 2, color: 'grey.600' }}>
                          Texte werden verarbeitet...
                        </Typography>
                      ) : file.images_status !== 'completed' ? (
                        <Typography sx={{ ml: 2, color: 'grey.600' }}>
                          Bilder werden verarbeitet...
                        </Typography>
                      ) : file.metadata_status !== 'completed' ? (
                        <Typography sx={{ ml: 2, color: 'grey.600' }}>
                          Jetzt nur noch die Metadaten...
                        </Typography>
                      ) : null}
                    </Box>
                  )}

                  {/* If the file just finished, show "Datei ist bereit!" for 3s */}
                  {showReadyMessage && (
                    <Typography sx={{ ml: 2, color: 'green' }}>
                      Datei ist bereit!
                    </Typography>
                  )}
                </Box>

                {/* Action Buttons */}
                <Box
                  sx={{ display: 'flex', gap: 1 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {showEditAndDeleteButtons && (
                    <>
                      <EditFileButton fileId={file.id} onFileUpdated={onFileUpdated} />
                      <DeleteFileButton fileId={file.id} onFileUpdated={onFileUpdated} />
                      <DownloadFileButton fileId={file.id} fileName={file.file_name} />
                    </>
                  )}
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography
            sx={{
              mt: 2,
              textAlign: 'left',
              width: '100%',
              color: 'text.secondary',
              pl: 1,
              pb: 2
            }}
          >
            Du hast noch keine Dateien hochgeladen. Bitte lade zunächst eine Datei hoch.
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default FileList;
