import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Paper,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

import Icon from '../../components/Icon'; 
import ENDPOINTS from '../../utils/apiConfig';
import { refreshToken } from '../../utils/AuthenticationService'; // if you use token refresh

// 1) Adjust your endpoint in apiConfig.js to:
// RESET_SINGLE_RESPONSE_ASSESSMENT: (questionSetId) =>
//   `${BASE_URL}/assess/single_response/reset/${questionSetId}`

const MenuContainer = styled(Paper)(({ isPinned }) => ({
  width: '267px',
  height: isPinned ? '250px' : '58px',
  borderRadius: '15px',
  backgroundColor: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  position: 'absolute',
  bottom: 0,
  right: 0,
  transition: 'height 0.3s ease',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  // Expand on hover if not pinned
  '&:hover': isPinned
    ? {}
    : {
        height: '250px',
      },
}));

const CollapsedContent = styled(Box)(() => ({
  width: '100%',
  height: '58px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ExpandedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '16px',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
}));

// Utility: single digit/dash -> iconName
function getIconNameForChar(char) {
  switch (char) {
    case '0': return 'zero';
    case '1': return 'one';
    case '2': return 'two';
    case '3': return 'three';
    case '4': return 'four';
    case '5': return 'five';
    case '6': return 'six';
    case '7': return 'seven';
    case '8': return 'eight';
    case '9': return 'nine';
    case '-': return 'dash';
    default:  return null;
  }
}

// Renders a string like "37-50" as digit/dash icons
function renderPointsAsIcons(pointsString, iconStyle = {}) {
  return pointsString.split('').map((char, index) => {
    const iconName = getIconNameForChar(char);
    if (!iconName) {
      // If it's not 0..9 or '-', just show the character as text
      return (
        <Box
          key={index}
          sx={{ display: 'inline-block', width: '10px', textAlign: 'center' }}
        >
          {char}
        </Box>
      );
    }
    return (
      <Icon
        key={index}
        iconName={iconName}
        style={iconStyle}
      />
    );
  });
}

function getIconNameForRating(ratingKey) {
  switch (ratingKey) {
    case '0': return 'zeroCircle';
    case '1': return 'oneCircle';
    case '2': return 'twoCircle';
    case '3': return 'threeCircle';
    case '4': return 'fourCircle';
    case '5': return 'fiveCircle';
    default:  return 'questionCircle'; // "unrated"
  }
}

const ratingKeys = ['0','1','2','3','4','5','unrated'];

const AnsweringStatisticsMenu = ({
  questions,
  pinnedStateKey,
  questionSetId, 
  onRatingsReset,
}) => {
  const theme = useTheme();

  // ---------- PIN LOGIC ----------
  const [isPinned, setIsPinned] = useState(() => {
    const savedPinnedState = localStorage.getItem(pinnedStateKey);
    return savedPinnedState ? JSON.parse(savedPinnedState) : true;
  });
  const togglePinned = () => {
    setIsPinned((prev) => {
      const newState = !prev;
      localStorage.setItem(pinnedStateKey, JSON.stringify(newState));
      return newState;
    });
  };

  // ---------- RATING COUNTS + POINTS ----------
  const [ratingCounts, setRatingCounts] = useState({
    '0': 0,
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
    unrated: 0,
  });
  const [currentPoints, setCurrentPoints] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);

  // ---------- LINE STACKS (GRAPHIC) ----------
  const [rotations, setRotations] = useState({
    '0': [],
    '1': [],
    '2': [],
    '3': [],
    '4': [],
    '5': [],
    unrated: [],
  });
  const prevRatingCounts = useRef(ratingCounts);

  // ---------- CONFIRM DIALOG STATES (NEW) ----------
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  // Handler to show the confirm
  const handleOpenConfirm = () => {
    setIsConfirmOpen(true);
  };

  // Handler to close the confirm (cancel)
  const handleCloseConfirm = () => {
    setIsConfirmOpen(false);
  };

  // Handler to confirm the reset and call your endpoint
  const handleConfirmReset = async () => {
    setIsConfirmOpen(false);

    try {
      const token = localStorage.getItem('accessToken');
      const url = ENDPOINTS.RESET_SINGLE_RESPONSE_ASSESSMENT(questionSetId);

      // This is a POST with no body (as you said it works)
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // if 401, refresh token, retry
      if (!response.ok && response.status === 401) {
        const newToken = await refreshToken();
        localStorage.setItem('accessToken', newToken);
        response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        });
      }

      if (!response.ok) {
        const errData = await response.json();
        console.error('Reset ratings failed:', errData);
        return;
      }

      console.log('Ratings reset successful');

      // 2) Call the parent's callback to re-fetch questions
      onRatingsReset && onRatingsReset();

    } catch (error) {
      console.error('Network/Fetch error resetting ratings:', error);
    }
  };


  // ---------- EFFECT: Update ratingCounts + points whenever questions changes ----------
  useEffect(() => {
    if (!Array.isArray(questions)) return;
    const counts = {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      unrated: 0,
    };
    let pointsSum = 0;
    
    questions.forEach((q) => {
      const ratingStr = q.response_status;
      if (ratingStr == null || ratingStr === '') {
        counts.unrated += 1;
      } else {
        const ratingNum = parseInt(ratingStr, 10);
        counts[ratingStr] += 1;
        pointsSum += ratingNum;
      }
    });
    
    setRatingCounts(counts);
    setCurrentPoints(pointsSum);
    
    const max = questions.length * 5;
    setMaxPoints(max);
  }, [questions]);

  // ---------- EFFECT: Initialize rotations once on mount ----------
  useEffect(() => {
    const initialRotations = {};
    ratingKeys.forEach((rk) => {
      const count = ratingCounts[rk] || 0;
      initialRotations[rk] = Array.from({ length: count }, () => (Math.random() - 0.5) * 10);
    });
    setRotations(initialRotations);
    prevRatingCounts.current = ratingCounts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once

  // ---------- EFFECT: Whenever ratingCounts changes, add/remove lines in the stacks ----------
  useEffect(() => {
    ratingKeys.forEach((rk) => {
      const prevCount = prevRatingCounts.current[rk] || 0;
      const currentCount = ratingCounts[rk] || 0;
      if (currentCount > prevCount) {
        // add lines
        const numToAdd = currentCount - prevCount;
        setRotations((prevRot) => ({
          ...prevRot,
          [rk]: [
            ...prevRot[rk],
            ...Array.from({ length: numToAdd }, () => (Math.random() - 0.5) * 10),
          ],
        }));
      } else if (currentCount < prevCount) {
        // remove lines
        setRotations((prevRot) => ({
          ...prevRot,
          [rk]: prevRot[rk].slice(0, currentCount),
        }));
      }
    });
    prevRatingCounts.current = ratingCounts;
  }, [ratingCounts]);

  // ---------- RENDER LINES FOR A SINGLE RATING ----------
  const renderStack = (rotationsArray) => {
    const maxStackHeight = 80; // The maximum vertical space for the stack
    const lineSpacing = 2;
    const maxLines = 38; // Hard cap on number of lines

    // If we have more lines than maxLines, just slice them
    const displayedRotations = rotationsArray.slice(0, maxLines);
    let count = displayedRotations.length;

    let lineHeight = 1.5;
    let neededHeight = count * (lineHeight + lineSpacing);

    // If neededHeight is bigger than the max, shrink lineHeight so it fits
    if (neededHeight > maxStackHeight) {
      lineHeight = (maxStackHeight - count * lineSpacing) / count;
      // Ensure lineHeight doesn't go below 1px so lines remain visible
      if (lineHeight < 1) {
        lineHeight = 1;
      }
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: `${maxStackHeight}px`,
          overflow: 'hidden',
          mb: '4px',
        }}
      >
        {displayedRotations.map((rotation, idx) => (
          <Box
            key={idx}
            sx={{
              width: '20px',
              height: `${lineHeight}px`,
              backgroundColor: theme.palette.primary.main,
              marginBottom: `${lineSpacing}px`,
              transform: `rotate(${rotation}deg)`,
            }}
          />
        ))}
      </Box>
    );
  };

  // Build the points string, e.g. "37-50"
  const pointsString = `${currentPoints}-${maxPoints}`;

  return (
    <MenuContainer elevation={3} isPinned={isPinned}>
      {/* Collapsed */}
      <CollapsedContent>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
          <Icon
            iconName="statistic"
            style={{ color: theme.palette.primary.main }}
            sx={{ width: 22, height: 22 }}
          />
        </Box>
      </CollapsedContent>

      {/* Expanded */}
      <ExpandedContent>
        {/* ---------- TOP ROW with "Zurücksetzen", points, pin icon ---------- */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          {/* LEFT: "Zurücksetzen" button (OUTLINED & SMALLER) */}
          <Button
            variant="outlined"        // CHANGED to outlined
            color="secondary"         // or "primary"
            onClick={handleOpenConfirm}
            sx={{
              textTransform: 'none',  // keep normal casing
              fontSize: '0.7rem',     // smaller font
              width: '50px',       // smaller button width
              padding: '2px 8px',     // tighten up the spacing
            }}
          >
            Reset
          </Button>

          {/* MIDDLE: Points icon display */}
          <Box
            sx={{
              display: 'flex',
              gap: '0px',
              alignItems: 'center',
            }}
          >
            {renderPointsAsIcons(pointsString, {
              width: 20,
              height: 20,
              color: theme.palette.primary.main,
            })}
          </Box>

          {/* RIGHT: Pin button */}
          <IconButton
            onClick={togglePinned}
            sx={{
              color: isPinned ? theme.palette.primary.main : theme.palette.grey[500],
              height: 35,
              width: 35,
            }}
            aria-label={isPinned ? 'Unpin menu' : 'Pin menu'}
          >
            <Icon iconName="pin" sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>

        {/* ---------- RATINGS GRID ---------- */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexGrow: 1,
          }}
        >
          {ratingKeys.map((rk) => {
            const iconName = getIconNameForRating(rk);
            return (
              <Box
                key={rk}
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {renderStack(rotations[rk])}

                <Icon
                  iconName={iconName}
                  style={{
                    width: 22,
                    height: 22,
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    mt: 0,
                    fontSize: '0.75rem',
                    color: theme.palette.grey[500],
                  }}
                >
                  {ratingCounts[rk]}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </ExpandedContent>

      {/* ---------- CONFIRMATION DIALOG ---------- */}
      <Dialog open={isConfirmOpen} onClose={handleCloseConfirm}>
  <DialogTitle>Bewertungen zurücksetzen?</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Bist du dir sicher, dass du die Bewertung deiner Fragen
      in diesem Set zurücksetzen möchtest?
    </DialogContentText>
  </DialogContent>
  <DialogActions
    sx={{
      justifyContent: 'space-between', // Distribute buttons to the edges
      padding: '16px', // Add padding for better alignment
    }}
  >
    {/* "Abbrechen" button */}
    <Button
      onClick={handleCloseConfirm}
      color="primary"
      sx={{
        fontSize: '0.8rem',
        alignSelf: 'flex-start', // Ensure it's in the bottom-left
      }}
    >
      Abbrechen
    </Button>

    {/* "Zurücksetzen" button */}
    <Button
      onClick={handleConfirmReset}
      color="secondary"
      variant="contained"
      sx={{
        fontSize: '0.8rem',
      }}
    >
      Zurücksetzen
    </Button>
  </DialogActions>
</Dialog>

    </MenuContainer>
  );
};

export default AnsweringStatisticsMenu;
