import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import { Typography } from '@mui/material';
import { fetchUserCourses } from '../services/CourseService';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { UserMenu } from './Menus/UserMenu';
import {useTheme} from '@mui/material';
import CreateCourseDialog from '../features/CreateCourseDialog/CreateCourseDialog';
import ColorMenu from '../components/Menus/ColorMenu';
import { alpha } from '@mui/material/styles';
import Icon from '../components/Icon'; 
import { useSidebar } from '../contexts/SidebarContext';
import { subscribe, EVENT_TYPES } from '../utils/eventEmitter'; // Import event emitter


export const MainListItems = () => {
  const navigate = useNavigate();  // Create a navigation function
  const theme = useTheme(); 

    const handleMainClick = () => {
        navigate('/main');  // Assuming the main page is the root
    };

    return (
        <ListItemButton onClick={handleMainClick} sx={{
          borderRadius: '10px',
          mt: 0,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            opacity: 1
          },
          margin: '8px', // Add margin to give space around the buttons
          width: 'auto', // Ensure width fits content + margin
        }}>
            <ListItemIcon sx={{ minWidth: 0, mr: 3, height: 30, width: 30, ml:0.4 }}>
                <Icon iconName="courses" style={{ color: theme.palette.icon.main }}/>
            </ListItemIcon>
            <ListItemText primary="Meine Kurse"/>
        </ListItemButton>
    );
};


export const SecondaryListItems = ({ onPanicClick, panicButtonDisabled }) => {
  const [openColorMenu, setOpenColorMenu] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const theme = useTheme();

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleColorMenuClick = () => {
    setOpenColorMenu(true);
  };

  const handleCloseColorMenu = () => {
    setOpenColorMenu(false);
  };

  return (
    <>
   
    <ListItemButton 
      onClick={onPanicClick} 
      disabled={panicButtonDisabled}
      sx={{
        borderRadius: '10px', 
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.20)',
          opacity: 1,
        },
        margin: '8px', 
        width: 'auto',
        opacity: panicButtonDisabled ? 0.5 : 1,
        cursor: panicButtonDisabled ? 'not-allowed' : 'pointer',
      }}
    >
      <ListItemIcon sx={{ minWidth: 0, mr: 3}}>
        <Icon iconName="panic" style={{ 
          color: panicButtonDisabled ? 'rgba(144, 146, 210, 0.5)' : theme.palette.icon.main, 
          width: '24px', 
          height: '24px'
        }} />
      </ListItemIcon>
      <ListItemText primary="Panikbutton" />
    </ListItemButton>
     <ListItemButton onClick={handleColorMenuClick} sx={{
          borderRadius: '10px', 
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            opacity: 1,
          },
          margin: '8px', 
          width: 'auto', 
        }}>
        <ListItemIcon sx={{ minWidth: 0, mr: 3 }} >
          <Icon iconName="palette" style={{ color: theme.palette.icon.main }}/>
        </ListItemIcon>
        <ListItemText primary="Farbeinstellungen" />
      </ListItemButton>
      <ColorMenu open={openColorMenu} handleClose={handleCloseColorMenu} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
      <UserMenu />
    </>
  );
};



export const CourseListItems = ({ onCourseCreated }) => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  // Use the courseUpdateTrigger from context
  const { courseUpdateTrigger } = useSidebar();

  // Add debugging to see when this effect runs
  useEffect(() => {
    console.log('CourseListItems: courseUpdateTrigger changed to', courseUpdateTrigger);
    fetchCourses();
  }, [courseUpdateTrigger]); // Depend on the trigger from context instead of prop

  // Subscribe to COURSE_CREATED events
  useEffect(() => {
    console.log('CourseListItems: Setting up event subscription for COURSE_CREATED');
    
    // Subscribe to the COURSE_CREATED event
    const unsubscribe = subscribe(EVENT_TYPES.COURSE_CREATED, (newCourse) => {
      console.log('CourseListItems: Received COURSE_CREATED event', newCourse);
      fetchCourses();
    });
    
    // Clean up the subscription when component unmounts
    return () => {
      console.log('CourseListItems: Cleaning up event subscription');
      unsubscribe();
    };
  }, []); // Empty dependency array means this runs once on mount

  const fetchCourses = async () => {
    try {
      console.log('CourseListItems: Fetching courses...');
      const fetchedCourses = await fetchUserCourses();
      console.log('CourseListItems: Fetched courses:', fetchedCourses.length, 'courses');
      setCourses(fetchedCourses);
    } catch (error) {
      console.error('Failed to fetch courses:', error);
    }
  };

  const handleCourseClick = (courseId) => {
    // First, determine which course page we're coming from (if any)
    const currentPath = location.pathname;
    const isInCourse = currentPath.includes('/course/');
    const isInQuestionSetDetail = localStorage.getItem('isViewingQuestionSet') === 'true';
    
    // Always clear QuestionSetDetail state - we want a fresh state for the new course
    localStorage.removeItem('selectedQuestionSet');
    localStorage.removeItem('isViewingQuestionSet');
    
    // Determine the target tab based on circumstances:
    let targetTab = '1'; // Default to tab 1 (Files)
    
    // Only preserve the current tab if:
    // 1. We're currently in a course
    // 2. We're NOT in the QuestionSetDetail view
    // 3. A tab parameter exists in the URL
    if (isInCourse && !isInQuestionSetDetail) {
      const params = new URLSearchParams(location.search);
      const currentTab = params.get('tab');
      if (currentTab && ['1', '2', '3'].includes(currentTab)) {
        targetTab = currentTab;
      }
    }
    
    // Force a clean navigation to the new course
    navigate(`/course/${courseId}?tab=${targetTab}`, { replace: true });
  };

  return (
    <React.Fragment>
      {courses.map((course) => (
        <ListItemButton key={course.id} onClick={() => handleCourseClick(course.id)} sx={{
          borderRadius: '10px', 
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            opacity: 1,
          },
          margin: '8px', 
          width: 'auto', 
        }}>
          <ListItemIcon sx={{ minWidth: 10, justifyContent: 'center', mr: 2,}}>
            <Icon iconName="folder" style={{ color: theme.palette.icon.main, height: 32 }}/>
            <Typography
              component="span"
              sx={{
                position: 'absolute',
                color: theme.palette.icon.main,
                fontWeight: 'bold',
                fontSize: '0.6rem',
                top: '50%',
                transform: 'translateY(-35%)'
              }}
            >
              {course.name.substring(0,2).toUpperCase()}
            </Typography>
          </ListItemIcon>
          <ListItemText primary={course.name} sx={{ my: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}/>
        </ListItemButton>
      ))}
    </React.Fragment>
  );
};

export const CreateCourseListItem = ({ onCourseCreated }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    // Use the handleCourseCreated from context
    const { handleCourseCreated } = useSidebar();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Add debugging to show what onCourseCreated is 
    console.log('CreateCourseListItem: handleCourseCreated from context is', 
      typeof handleCourseCreated === 'function' ? 'a function' : handleCourseCreated);

    return (
      <>
        <ListItemButton onClick={handleClickOpen} sx={{
        ml: 0,
        borderRadius: '10px',
        color: alpha(theme.palette.icon.main, 0.5),
        '&:hover': {
          backgroundColor: theme.palette.primary.light, // Lighter and semi-transparent on hover
          color: theme.palette.icon.main, // Solid primary color on hover
        },
        margin: '8px',
        width: 'auto',
        display: 'flex',
        justifyContent: 'flex-start', // Align items to the left
        alignItems: 'center', // Ensures vertical centering
      }}>
        <ListItemIcon sx={{ minWidth: 40, color: 'inherit' , ml: 0.45}}> 
          <Icon iconName="plusCircle" />
        </ListItemIcon>
        <ListItemText primary="Neuer Kurs" sx={{ color: 'inherit', ml: 0.6
         }} /> 
      </ListItemButton>
      <CreateCourseDialog 
        open={open} 
        onClose={handleClose} 
        onCourseCreated={handleCourseCreated} 
      />
    </>
  );
};
