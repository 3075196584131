import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';
import { fetchSingleQuestion } from '../../services/GetSingleQuestionService'; // Import the new service
import ENDPOINTS from '../../utils/apiConfig';

export default function DeleteQuestionDialog({ questionId, open, onClose, onQuestionDeleted }) {
    const [questionContent, setQuestionContent] = useState('');  // State to store the question content
    const [loading, setLoading] = useState(true);  // State to manage loading status

    useEffect(() => {
        if (open) {
            const fetchQuestionDetails = async () => {
                try {
                    const data = await fetchSingleQuestion(questionId);
                    console.log('Fetched question details:', data); // Log fetched data
                    setQuestionContent(data.content);  // Set the fetched question content to state
                } catch (error) {
                    console.error('Error fetching question details:', error);
                } finally {
                    setLoading(false);  // Set loading to false after fetching data
                }
            };
            fetchQuestionDetails();
        }
    }, [open, questionId]);

    const handleDelete = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(ENDPOINTS.DELETE_QUESTION(questionId), {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to delete the question');
            }
            onClose();  // Close dialog on successful delete
            onQuestionDeleted();  // Trigger question list refresh
        } catch (error) {
            console.error('Error deleting question:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}
        PaperProps={{
            sx: {
              borderRadius: '20px' 
            }
          }}>
            <DialogTitle>Frage löschen</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Typography variant="body1">
                        Achtung! Du bist dabei die Frage "{questionContent}" zu löschen. Dieser Vorgang kann nicht rückgängig gemacht werden. Bist du dir sicher?
                    </Typography>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: '0 24px 16px 24px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={handleDelete} disabled={loading} variant="outlined"
            sx={{
              borderWidth: 2,
              borderStyle: 'dashed',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              color: 'text.primary',
              '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
                backgroundColor: 'transparent',
              },
            }}>
                        Löschen
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} disabled={loading} variant="outlined"
            sx={{
              borderWidth: 2,
              borderStyle: 'solid',
            }}>
                        Abbrechen
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
