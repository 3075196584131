// DeleteSummaryButton.jsx
import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteSummaryDialog from '../../features/DeleteSummaryDialog/DeleteSummaryDialog';
import Icon from '../Icon'; // or wherever your Icon is located
import { useTheme } from '@mui/material';

export default function DeleteSummaryButton({ summaryId, onSummaryUpdated }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="delete summary"
        onClick={handleClickOpen}
        sx={{
          width: 20,
          height: 20,
          padding: 0,
          color: 'grey',
          '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            '& svg': {
              animation: 'bounce 0.5s infinite alternate'
            }
          },
          '@keyframes bounce': {
            '0%': { transform: 'translateY(0)' },
            '100%': { transform: 'translateY(-3px)' }
          }
        }}
      >
        <Icon iconName="trash" style={{ fontSize: '20px' }} />
      </IconButton>
      {open && (
        <DeleteSummaryDialog
          open={open}
          onClose={handleClose}
          summaryId={summaryId}
          onSummaryUpdated={onSummaryUpdated}
        />
      )}
    </>
  );
}

