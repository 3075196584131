import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import publicTheme from '../styles/publicTheme';

/**
 * PublicThemeProvider - Wraps components with the public theme
 * 
 * This provider should be used for all public-facing pages outside of the
 * protected routes in your application.
 * 
 * @param {object} props.children - Child components to render with the theme
 */
const PublicThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={publicTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default PublicThemeProvider;

/* Usage example:

import PublicThemeProvider from '../providers/PublicThemeProvider';
import { Box, Typography } from '@mui/material';

function MyPublicPage() {
  return (
    <PublicThemeProvider>
      <Box>
        <Typography variant="h1">Welcome to our site</Typography>
        // Content goes here
      </Box>
    </PublicThemeProvider>
  );
}
*/ 