import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ENDPOINTS from '../../utils/apiConfig';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

export default function EditFileDialog({ fileId, open, onClose, onFileUpdated }) {
    const [fileName, setFileName] = useState('');
    const [error, setError] = useState(false);
    const MAX_CHARS = 250;

    const fetchFileDetails = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${ENDPOINTS.FILES}/${fileId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch file details');
            }
            const data = await response.json();
            setFileName(data.file_name);  // Set the fetched name as initial state
        } catch (error) {
            console.error('Error fetching file details:', error);
        }
    };

    useEffect(() => {
        if (open) {
            fetchFileDetails();
            setError(false); // Reset error state when dialog opens
        }
    }, [open, fileId]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= MAX_CHARS) {
            setFileName(newValue);
            setError(false);
        } else {
            setFileName(newValue.substring(0, MAX_CHARS));
            setError(true);
        }
    };

    const handleSave = async (event) => {
        event.preventDefault();
        if (fileName.length > MAX_CHARS) {
            setError(true);
            return;
        }
        
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${ENDPOINTS.FILES}/${fileId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ file_name: fileName })
            });
            if (!response.ok) {
                throw new Error('Failed to update file name');
            }
            onClose();  // Close dialog on successful update
            onFileUpdated();  // Trigger file list refresh
        } catch (error) {
            console.error('Error updating file name:', error);
        }
    };

    // Determine color for the character counter
    const getHelperTextColor = () => {
        if (fileName.length > MAX_CHARS) return 'error';
        if (fileName.length > MAX_CHARS * 0.8) return 'warning.main'; // 80% full
        return 'text.secondary';
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{
            sx: {
              borderRadius: '20px' 
            }
          }}>
            <DialogTitle>Dateinamen bearbeiten</DialogTitle>
            <form onSubmit={handleSave}>
                <DialogContent>
                    <FormControl fullWidth error={error}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Dateiname"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={fileName}
                            onChange={handleChange}
                            error={error}
                            inputProps={{
                                maxLength: MAX_CHARS
                            }}
                        />
                        <FormHelperText 
                            sx={{ 
                                textAlign: 'right',
                                color: getHelperTextColor()
                            }}
                        >
                            {`${fileName.length}/${MAX_CHARS}`}
                        </FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button 
                        type="submit" 
                        color="primary"
                        disabled={error || fileName.length === 0}
                    >
                        Speichern
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

