// src/components/Button/EditSummaryButton.jsx
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '../../components/Icon'; 
import { useTheme } from '@mui/material';

export default function EditSummaryButton({ onClick }) {
  const theme = useTheme();

  return (
    <IconButton
      aria-label="edit summary"
      onClick={onClick}
      sx={{
        width: 20,
        height: 20,
        padding: 0,
        color: 'grey',
        '&:hover': {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
          '& svg': {
            animation: 'wiggle 0.2s ease-in-out infinite'
          }
        },
        '@keyframes wiggle': {
          '0%, 100%': { transform: 'rotate(-3deg)' },
          '50%': { transform: 'rotate(3deg)' }
        }
      }}
    >
      <Icon iconName="pen" style={{ width: 30, height: 30 }} />
    </IconButton>
  );
}
