import React, { useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import usePublicTheme from '../../hooks/usePublicTheme';
import ThinkiLogo from '../../assets/ThinkiLogo.svg';
import './TosPage.css';

const TosPage = () => {
  const { gradients, neutral } = usePublicTheme();
  const navigate = useNavigate();
  
  // Get the updateThemeColor function from the PublicLayout context
  const { updateThemeColor } = useOutletContext() || { updateThemeColor: () => {} };

  // Set the theme color when component mounts
  useEffect(() => {
    updateThemeColor('purple'); // Use purple theme for TOS page
  }, [updateThemeColor]);

  const handleLogoClick = () => {
    navigate('/');
  };

  const isMobile = window.innerWidth <= 600; // Simple mobile detection

  return (
    <Box
      className="tos-content"
      sx={{
        width: '100%',
        minHeight: '100vh',
        background: gradients.secondaryLight,
        position: 'relative',
        overflow: 'auto', // Enable scrolling
        paddingTop: isMobile ? '60px' : '0', 
      }}
    >
      {/* Logo in top left corner */}
      <Box 
        sx={{ 
          position: 'absolute', 
          top: '20px', 
          left: '20px', 
          cursor: 'pointer',
          zIndex: 10,
        }}
        onClick={handleLogoClick}
      >
        <img 
          src={ThinkiLogo} 
          alt="Thinki Logo" 
          width={isMobile ? "80" : "120"} 
          style={{ maxWidth: '25vw' }}
        />
      </Box>

      <Container 
        className="tos-container"
        maxWidth="md" 
        sx={{ 
          py: isMobile ? 3 : 6,
          px: isMobile ? 2 : 3,
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 600, color: neutral[950], mb: 4 }}>
          Geschäfts- und Nutzungsbedingungen für Nutzer der Thinki-Plattform
        </Typography>
        
        {/* Add more content sections as needed */}
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        1. Anwendungsbereich und Vertragsabschluss
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        1.1 Diese Geschäfts- und Nutzungsbedingungen („AGB") regeln das Vertragsverhältnis zwischen der Plix AI GmbH, Kolonnenstraße 8, 10827 Berlin, Deutschland (nachfolgend „Plix AI" oder „Thinki") und dem registrierten Nutzer (nachfolgend „Nutzer" oder „Sie") der Thinki-Anwendung (nachfolgend „die Plattform" oder „der Service").
        <br /><br />
        1.2 Mit der Registrierung auf der Thinki-Plattform und deren Nutzung erklären Sie Ihr Einverständnis mit diesen AGB.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        2. Registrierung und Nutzerkonto
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        2.1 Die Registrierung auf der Thinki-Plattform ist ausschließlich volljährigen und voll geschäftsfähigen Personen vorbehalten.
        <br /><br />
        2.2 Für die Registrierung ist die Angabe einer gültigen E-Mail-Adresse erforderlich, über die ein Verifizierungslink versendet wird.
        <br /><br />
        2.3 Sie sind verpflichtet, bei der Registrierung wahrheitsgemäße und vollständige Angaben zu machen und diese aktuell zu halten.
        <br /><br />
        2.4 Nach Abschluss der Registrierung kommt ein verbindlicher Nutzungsvertrag auf Basis dieser AGB zwischen Ihnen und Plix AI zustande.
        <br /><br />
        2.5 Die Weitergabe von Zugangsdaten an Dritte ist strengstens untersagt. Bei Verdacht auf unbefugten Zugriff informieren Sie bitte umgehend Plix AI unter info@plix-ai.com.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        3. Leistungsbeschreibung und Nutzung der Plattform
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        3.1 Thinki ermöglicht es Nutzern, PDF-Dokumente sowie andere Vorlesungsunterlagen (z. B. Notizen, Bücher, Vorlesungsfolien) hochzuladen.
        <br /><br />
        3.2 Aus den hochgeladenen Dokumenten werden automatisch und halbautomatisch Lernmaterialien (z. B. Zusammenfassungen, Flashcards) generiert. Zukünftige Funktionen können die Erstellung von Hörbüchern, Unterstützung beim wissenschaftlichen Schreiben und weitere Lernunterstützungen umfassen.
        <br /><br />
        3.3 Die Nutzung der Plattform erfolgt im Rahmen eines Abonnementmodells, dessen konkrete Preise, Nutzungslimits und Zahlungsbedingungen noch festgelegt werden.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        4. Preise, Zahlung und Kündigung
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        4.1 Thinki wird als Abonnementmodell mit einer kostenlosen Probephase von zwei Wochen angeboten.
        <br /><br />
        4.2 Die Abonnementgebühren werden monatlich, sechsmonatlich oder jährlich im Voraus über Stripe abgerechnet.
        <br /><br />
        4.3 Im Falle einer Kündigung können Sie Ihr Abonnement jederzeit beenden. Bereits geleistete Zahlungen werden nicht zurückerstattet, es sei denn, gesetzlich ist etwas anderes vorgeschrieben.
        <br /><br />
        4.4 Plix AI behält sich das Recht vor, bei Zahlungsverzug das Nutzerkonto ohne Vorwarnung zu sperren und den Vertrag fristlos zu kündigen.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        5. Nutzerpflichten
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        5.1 Sie sind verantwortlich dafür, dass Sie nur Inhalte hochladen, für die Sie die entsprechenden Rechte besitzen oder zur Veröffentlichung autorisiert sind.
        <br /><br />
        5.2 Es ist untersagt, Inhalte hochzuladen, die gegen geltendes Recht, Urheberrechte oder die guten Sitten verstoßen.
        <br /><br />
        5.3 Plix AI behält sich das Recht vor, Inhalte, die gegen diese Bedingungen verstoßen, ohne Vorankündigung zu entfernen und bei schweren Verstößen Ihr Nutzerkonto zu sperren oder zu löschen.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        6. Datenverarbeitung, Datenschutz und Consent
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        6.1 Die Verarbeitung personenbezogener Daten erfolgt auf Basis der Erfüllung des Vertrags (Art. 6(1)(b) GDPR), berechtigter Interessen (Art. 6(1)(f) GDPR) sowie gegebenenfalls auf Grundlage Ihrer ausdrücklichen Einwilligung (Art. 6(1)(a) GDPR).
        <br /><br />
        6.2 Details zur Datenverarbeitung, internationalen Datenübermittlungen und den entsprechenden Rechtsgrundlagen finden Sie in unserer separaten Datenschutzerklärung, die Bestandteil dieses Vertrages ist.
        <br /><br />
        6.3 Mit der Nutzung der Plattform bestätigen Sie, dass Sie über die erforderlichen Rechte zur Übermittlung und Verarbeitung der hochgeladenen Inhalte verfügen.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        7. Nutzungsrechte und Urheberrecht
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        7.1 Mit dem Hochladen von Inhalten gewähren Sie Plix AI ein einfaches, zeitlich und räumlich unbeschränktes, nicht ausschließliches Recht zur Nutzung, Bearbeitung, Speicherung, Anzeige und Veröffentlichung der Inhalte, soweit dies zur Erbringung der Serviceleistungen erforderlich ist.
        <br /><br />
        7.2 Alle Rechte an den von Ihnen hochgeladenen Inhalten verbleiben bei Ihnen, soweit nicht ausdrücklich anders vereinbart.
        <br /><br />
        7.3 Für die automatisch oder halbautomatisch generierten Inhalte (z. B. Zusammenfassungen, Flashcards) räumt Plix AI ein Nutzungsrecht ein, das ausschließlich zur Bereitstellung des Services verwendet wird.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        8. Haftungsbeschränkung
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        8.1 Plix AI haftet nur für Schäden, die auf Vorsatz oder grober Fahrlässigkeit beruhen.
        <br /><br />
        8.2 Bei leicht fahrlässigen Pflichtverletzungen haftet Plix AI nur, sofern wesentliche Vertragspflichten (Kardinalpflichten) verletzt wurden. In diesem Fall ist die Haftung auf den vertragstypischen, vorhersehbaren Schaden begrenzt.
        <br /><br />
        8.3 Plix AI übernimmt keine Haftung für die Richtigkeit, Vollständigkeit oder Eignung der von der Plattform generierten Inhalte (z. B. Zusammenfassungen oder Flashcards).
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        9. Kündigung und Vertragsbeendigung
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        9.1 Sie können Ihr Nutzerkonto und/oder Abonnement jederzeit kündigen.
        <br /><br />
        9.2 Bereits geleistete Zahlungen werden nicht zurückerstattet, sofern nicht gesetzlich anders vorgeschrieben.
        <br /><br />
        9.3 Nach Beendigung des Vertrages werden Ihre Nutzerdaten gemäß den gesetzlichen Aufbewahrungsfristen gelöscht, soweit keine weitergehenden vertraglichen oder gesetzlichen Verpflichtungen bestehen.
        <br /><br />
        9.4 Das Recht zur Kündigung aus wichtigem Grund bleibt unberührt.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        10. Streitbeilegung und anwendbares Recht
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        10.1 Es gilt das Recht der Bundesrepublik Deutschland.
        <br /><br />
        10.2 Für alle Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag ist das zuständige Gericht in Berlin ausschließlich zuständig.
        <br /><br />
        10.3 Alle Streitigkeiten werden ausschließlich vor Gericht ausgetragen.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        11. Änderungen der Geschäftsbedingungen
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        11.1 Plix AI behält sich das Recht vor, diese AGB jederzeit anzupassen, sofern gesetzliche Vorgaben oder neue Serviceleistungen dies erfordern oder sofern die Änderungen ausschließlich zu Ihrem Vorteil sind.
        <br /><br />
        11.2 Änderungen werden Ihnen per E-Mail oder beim nächsten Login angezeigt.
        <br /><br />
        11.3 Wird innerhalb von 14 Tagen kein schriftlicher Einspruch per E-Mail an info@plix-ai.com erhoben, gelten die Änderungen als akzeptiert.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        12. Sonstige Bestimmungen
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        12.1 Sollten einzelne Bestimmungen dieser AGB unwirksam sein, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.
        <br /><br />
        12.2 Ergänzende oder abweichende Vereinbarungen bedürfen der Schriftform.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        13. Record of Processing Activities
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        Wir befinden uns derzeit im Prozess der Erstellung und laufenden Aktualisierung eines Verarbeitungsverzeichnisses gemäß den Vorgaben der DSGVO. Dieses Verzeichnis dokumentiert die Kategorien der verarbeiteten Daten, die Zwecke der Verarbeitung, die Rechtsgrundlagen sowie alle Empfänger und internationalen Datenübermittlungen. Bei Rückfragen hierzu kontaktieren Sie uns bitte unter info@plix-ai.com.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
        14. Kontakt und Beschwerden
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        Für Fragen zu diesen AGB oder zu datenschutzrechtlichen Belangen kontaktieren Sie uns bitte:
        <br /><br />
        Plix AI GmbH<br />
        Kolonnenstraße 8<br />
        10827 Berlin, Deutschland<br />
        E-Mail: info@plix-ai.com
        <br /><br />
        Sollten Sie der Ansicht sein, dass wir Ihre personenbezogenen Daten nicht rechtmäßig verarbeiten, haben Sie das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde (z. B. dem Berliner Beauftragten für Datenschutz und Informationsfreiheit) einzureichen.
        </Typography>
      </Container>
    </Box>
  );
};
  
export default TosPage;
  