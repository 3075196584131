import React, { useEffect, useState, useRef } from 'react';
import { styled, Box, List, Divider, Drawer as MuiDrawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ThinkiLogo from "../assets/ThinkiLogo.svg";
import Icon from '../components/Icon'; 
import { MainListItems, CourseListItems, SecondaryListItems, CreateCourseListItem } from './ListItemsNavbar';
import { useTheme } from '@mui/material';
import { useSidebar } from '../contexts/SidebarContext';
import './Drawer.css';

const drawerWidth = 240;

// Create a static drawer without animations
const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'fixed',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      border: 'none',
      ...(!open && {
        overflowX: 'hidden',
        width: 72,
      }),
    },
  }),
);

const SideDrawer = ({ open, toggleDrawer, onCourseCreated, onPanicClick, panicButtonDisabled }) => {
  const theme = useTheme();
  const { isFirstRender, animationsReady } = useSidebar();
  const previousOpen = useRef(open);
  
  // Update previous state when open changes
  useEffect(() => {
    if (animationsReady) {
      previousOpen.current = open;
    }
  }, [open, animationsReady]);
  
  // Determine the appropriate CSS class based on animation state
  const getAnimationClass = () => {
    // During initial render, no animations
    if (isFirstRender) return 'no-transition';
    
    // Once animations are ready, provide appropriate classes
    if (animationsReady) {
      return open 
        ? 'drawer-slide-enter-active' 
        : 'drawer-slide-exit-active';
    }
    
    // In between first render and animations being ready
    return 'no-transition';
  };
  
  return (
    <StyledDrawer 
      variant="permanent" 
      open={open}
      className={getAnimationClass()}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 0,
        mt: 3,
        ml: 2.2,
        gap: 0.4,
      }}>
        <IconButton
          style={{ color: theme.palette.icon.main }}
          aria-label="open drawer"
          onClick={toggleDrawer}
          className="drawer-toggle-button"
          sx={{ marginRight: 2 , height: 30, width: 30, padding: 0, ml:1 }}
        >
          <Icon iconName="sidebar" style={{ width: 30, height: 30 }}/>
        </IconButton>
        <img src={ThinkiLogo} alt="Thinki Logo" style={{ height: 40, width: 'auto' }} />
      </Box>
      <List component="nav">
        <MainListItems />
        <Divider sx={{ my: 0.5, borderColor: 'rgba(0, 0, 0, 0.10)', width: "90%", mx: 'auto'}} />
        <CourseListItems onCourseCreated={onCourseCreated} />
        <CreateCourseListItem onCourseCreated={onCourseCreated} />
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <List component="nav">
        <SecondaryListItems onPanicClick={onPanicClick} panicButtonDisabled={panicButtonDisabled} />
      </List>
    </StyledDrawer>
  );
};

export default SideDrawer;
