import ENDPOINTS from '../utils/apiConfig';
import { refreshToken } from '../utils/AuthenticationService';

export const fetchSummaryCreationRequestById = async (creationRequestId) => {
  let accessToken = localStorage.getItem('accessToken');
  const url = ENDPOINTS.SUMMARY_CREATION_REQUEST(creationRequestId);

  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Try refreshing token
        accessToken = await refreshToken();
        response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
      }
      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error fetching summary creation request:', errorData);
        throw new Error(errorData.detail || 'Unknown error in summary creation request');
      }
    }

    return await response.json();
  } catch (error) {
    console.error('Error in fetchSummaryCreationRequestById:', error);
    throw error;
  }
};
