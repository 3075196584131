import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  Button
} from '@mui/material';
import Icon from './Icon'; 
import { fetchSummaryById } from '../services/SummaryService';
import { useTheme } from '@mui/material';
import EditSummaryButton from '../components/Button/EditSummaryButton'; // newly created
import ENDPOINTS from '../utils/apiConfig'; 
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const SummaryDetail = ({ summary, onBack }) => {
  const [detailedSummary, setDetailedSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [newSummaryText, setNewSummaryText] = useState('');
  const [showMarkdownHelp, setShowMarkdownHelp] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (summary?.id) {
      setIsLoading(true);
      fetchSummaryById(summary.id)
        .then((data) => {
          setDetailedSummary(data);
          setNewSummaryText(data.summary_text); // load text into the editing state
        })
        .catch((err) => {
          console.error('Failed to fetch summary detail:', err);
          setError(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [summary]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Save new text to the server (Patch request)
  const handleSaveClick = async () => {
    if (!detailedSummary?.id) return;

    try {
      const accessToken = localStorage.getItem('accessToken');
      const res = await fetch(ENDPOINTS.SUMMARY_EDIT(detailedSummary.id), {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({ summary_text: newSummaryText })
      });

      if (!res.ok) {
        const errData = await res.json();
        console.error('Error patching summary:', errData);
        throw new Error(errData.detail || 'Fehler beim Aktualisieren der Zusammenfassung');
      }

      // If success:
      const updatedData = await res.json();
      setDetailedSummary(updatedData);           // update local detail
      setNewSummaryText(updatedData.summary_text);
      setIsEditing(false);
    } catch (err) {
      console.error('Error saving summary:', err);
      setError(err.message);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ mt: 4 }}>
        Error: {error}
      </Typography>
    );
  }

  if (!detailedSummary) {
    return null;
  }

  return (
    <Box sx={{ p: 2, minHeight: '100vh', position: 'relative' }}>
      {/* Header with summary name and top-right icons */}
      <Box sx={{ position: 'relative', mb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {detailedSummary.name}
        </Typography>

        {/* Close button */}
        <IconButton
          onClick={onBack}
          sx={{
            marginLeft: 'auto',
            width: 30,
            height: 30,
            padding: 0,
            position: 'absolute',
            top: 16,
            right: 16,
            '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: 'transparent'
            }
          }}
        >
          <Icon iconName="cross" style={{ width: 30, height: 30 }}/>
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Paper
          elevation={3}
          sx={{
            width: {
              xs: '90%',
              sm: '80%',
              md: '65%',
              lg: '63%',
              xl: '68%',
            },
            height: '78vh',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '20px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
          }}
        >
          {/* 
            1) NON-SCROLLABLE HEADER FOR EDIT/SAVE BUTTON
          */}
          <Box
            sx={{
              flexShrink: 0,
              p: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {isEditing ? (
              <IconButton
                onClick={handleSaveClick}
                sx={{
                  width: 30,
                  height: 30,
                  p: 0,
                  color: 'grey',
                  '&:hover': {
                    color: theme.palette.primary.main,
                    backgroundColor: 'transparent'
                  }
                }}
              >
                <Icon iconName="checkCircle" style={{ width: 30, height: 30 }} />
              </IconButton>
            ) : (
              <EditSummaryButton onClick={handleEditClick} />
            )}
          </Box>

          {/* 
            2) SCROLLABLE CONTENT 
              - extra right padding so scrollbar isn't flush with edge
              - minHeight: 0 to allow shrinking
          */}
          <Box
            sx={{
              flexGrow: 1,
              minHeight: 0,
              overflowY: 'auto',
              px: 3,        // left & right padding
              pb: 3,        // bottom padding
              '&::-webkit-scrollbar': { width: '0.5em' },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'darkgrey',
                borderRadius: '4px',
              },
            }}
          >
            {isEditing ? (
              <>
                <TextField
                  value={newSummaryText}
                  onChange={(e) => setNewSummaryText(e.target.value)}
                  multiline
                  fullWidth
                  minRows={10}
                  variant="outlined"
                  placeholder="Use Markdown for formatting (e.g., # for headers, ** for bold, * for italic)"
                  sx={{ 
                    textAlign: 'left',
                    fontFamily: 'monospace',
                    '& .MuiOutlinedInput-root': {
                      fontFamily: 'monospace',
                      lineHeight: 1.6,
                    }
                  }}
                  InputProps={{
                    style: {
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'keep-all',
                    }
                  }}
                />
              </>
            ) : (
              <Box sx={{
                textAlign: 'left',
                lineHeight: 1.6,
                '& h1, & h2, & h3, & h4, & h5, & h6': {
                  fontWeight: 'bold',
                  marginTop: 2,
                  marginBottom: 1,
                },
                '& h1': { fontSize: '1.8rem' },
                '& h2': { fontSize: '1.5rem' },
                '& h3': { fontSize: '1.3rem' },
                '& h4': { fontSize: '1.1rem' },
                '& p': { marginBottom: 1.5 },
                '& strong': { fontWeight: 'bold' },
                '& em': { fontStyle: 'italic' },
                '& ul, & ol': { paddingLeft: 2, marginBottom: 1.5 },
                '& li': { marginBottom: 0.5 },
                '& blockquote': {
                  borderLeft: '3px solid grey',
                  paddingLeft: 2,
                  fontStyle: 'italic',
                  marginLeft: 1,
                  marginRight: 1,
                },
                '& code': {
                  fontFamily: 'monospace',
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  padding: '2px 4px',
                  borderRadius: '4px',
                },
                '& pre': {
                  fontFamily: 'monospace',
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  padding: 1.5,
                  borderRadius: '4px',
                  overflow: 'auto',
                  marginBottom: 1.5,
                },
                '& a': {
                  color: (theme) => theme.palette.primary.main,
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                },
                '& img': {
                  maxWidth: '100%',
                  height: 'auto',
                },
                '& table': {
                  borderCollapse: 'collapse',
                  width: '100%',
                  marginBottom: 1.5,
                },
                '& th, & td': {
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  padding: '8px 12px',
                  textAlign: 'left',
                },
                '& th': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  fontWeight: 'bold',
                },
              }}>
                <ReactMarkdown 
                  remarkPlugins={[remarkGfm]}
                  components={{
                    h1: ({node, ...props}) => <Typography variant="h4" gutterBottom {...props} />,
                    h2: ({node, ...props}) => <Typography variant="h5" gutterBottom {...props} />,
                    h3: ({node, ...props}) => <Typography variant="h6" gutterBottom {...props} />,
                    h4: ({node, ...props}) => <Typography variant="subtitle1" gutterBottom {...props} />,
                    h5: ({node, ...props}) => <Typography variant="subtitle2" gutterBottom {...props} />,
                    h6: ({node, ...props}) => <Typography variant="subtitle2" gutterBottom {...props} />,
                    p: ({node, ...props}) => <Typography variant="body1" paragraph {...props} />,
                    a: ({node, ...props}) => <Typography variant="body1" component="a" color="primary" {...props} />,
                    code: ({node, inline, className, children, ...props}) => {
                      const match = /language-(\w+)/.exec(className || '');
                      return !inline && match ? (
                        <Box component="pre" sx={{ 
                          fontFamily: 'monospace',
                          backgroundColor: 'rgba(0, 0, 0, 0.05)',
                          p: 2,
                          borderRadius: '4px',
                          overflow: 'auto',
                          mb: 2,
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word'
                        }}>
                          <Typography component="code" variant="body2" className={className} sx={{ 
                            fontFamily: 'monospace',
                            display: 'block',
                          }} {...props}>
                            {children}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography component="code" variant="body2" sx={{ 
                          fontFamily: 'monospace', 
                          backgroundColor: 'rgba(0, 0, 0, 0.05)',
                          padding: '2px 4px',
                          borderRadius: '4px',
                        }} {...props}>
                          {children}
                        </Typography>
                      );
                    },
                    ul: ({node, ...props}) => <Box component="ul" sx={{ pl: 2, mb: 2 }} {...props} />,
                    ol: ({node, ...props}) => <Box component="ol" sx={{ pl: 2, mb: 2 }} {...props} />,
                    li: ({node, ...props}) => <Typography component="li" variant="body1" sx={{ mb: 0.5 }} {...props} />,
                    blockquote: ({node, ...props}) => <Box component="blockquote" sx={{ 
                      borderLeft: '3px solid grey',
                      pl: 2,
                      fontStyle: 'italic',
                      ml: 1,
                      mr: 1,
                      mb: 2,
                    }} {...props} />,
                  }}
                >
                  {detailedSummary.summary_text}
                </ReactMarkdown>
              </Box>
            )}
          </Box>

          {/* 
            3) NON-SCROLLABLE FOOTER (just white space)
              - optional border-top to visually separate
          */}
          <Box
            sx={{
              flexShrink: 0,
              minHeight: 20,    
            }}
          />

          {/* Slidable Markdown help menu - positioned at bottom left of the Paper */}
          {isEditing && (
            <Box 
              sx={{ 
                position: 'absolute',
                bottom: 0,
                left: 0,
                zIndex: 100,
                borderTopRightRadius: '10px',
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  width: '180px',
                  maxHeight: showMarkdownHelp ? '280px' : '40px',
                  height: 'auto',
                  borderRadius: '0 10px 0 0',
                  backgroundColor: '#FFF',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  transition: 'max-height 0.3s ease',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  transform: 'translateZ(0)',
                }}
              >
                {/* Header Bar (Always Visible) */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '40px',
                    minHeight: '40px',
                    width: '100%',
                    cursor: 'pointer',
                    borderBottom: showMarkdownHelp ? '1px solid #eee' : 'none',
                    backgroundColor: theme.palette.primary.main,
                  }}
                  onClick={() => setShowMarkdownHelp(!showMarkdownHelp)}
                >
                  <Icon iconName="info" style={{ color: theme.palette.common.white, width: 20, height: 15, marginRight: 8 }} />
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: 'white' }}>
                    Markdown-Hilfe
                  </Typography>
                </Box>

                {/* Content (Visible When Expanded) */}
                <Box
                  sx={{
                    padding: 2,
                    paddingBottom: 1,
                    flexShrink: 0,
                    overflowY: 'auto',
                    maxHeight: '240px',
                    '&::-webkit-scrollbar': { width: '4px' },
                    '&::-webkit-scrollbar-track': { background: '#f1f1f1', borderRadius: '4px' },
                    '&::-webkit-scrollbar-thumb': { background: '#c1c1c1', borderRadius: '4px' },
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 1,
                  }}>
                    <Typography variant="body2" component="div">
                      <code># Überschrift 1</code>
                    </Typography>
                    <Typography variant="body2" component="div">
                      <code>## Überschrift 2</code>
                    </Typography>
                    <Typography variant="body2" component="div">
                      <code>**fett**</code> - <strong>fett</strong>
                    </Typography>
                    <Typography variant="body2" component="div">
                      <code>*kursiv*</code> - <em>kursiv</em>
                    </Typography>
                    <Typography variant="body2" component="div">
                      <code>- Listenelement</code>
                    </Typography>
                    <Typography variant="body2" component="div">
                      <code>1. Nummeriert</code>
                    </Typography>
                    <Typography variant="body2" component="div">
                      <code>[Link](URL)</code>
                    </Typography>
                    <Typography variant="body2" component="div">
                      <code>`Code`</code>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default SummaryDetail;
