import React, { useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useOutletContext, useNavigate } from 'react-router-dom';
import usePublicTheme from '../../hooks/usePublicTheme';
import ThinkiLogo from '../../assets/ThinkiLogo.svg';

const ImprintPage = () => {
  const { gradients, neutral } = usePublicTheme();
  const navigate = useNavigate();
  
  // Get the updateThemeColor function from the PublicLayout context
  const { updateThemeColor } = useOutletContext() || { updateThemeColor: () => {} };

  // Set the theme color when component mounts
  useEffect(() => {
    updateThemeColor('purple');
  }, [updateThemeColor]);

  const handleLogoClick = () => {
    navigate('/');
  };

  // Check if device is mobile
  const isMobile = window.innerWidth <= 768;

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        background: gradients.secondaryLight,
        position: 'relative',
        overflow: 'auto',
        paddingTop: isMobile ? '60px' : '0',
      }}
    >
      {/* Logo in top left corner */}
      <Box 
        sx={{ 
          position: 'absolute', 
          top: '20px', 
          left: '20px', 
          cursor: 'pointer',
          zIndex: 10,
        }}
        onClick={handleLogoClick}
      >
        <img 
          src={ThinkiLogo} 
          alt="Thinki Logo" 
          width={isMobile ? "80" : "120"} 
          style={{ maxWidth: '25vw' }}
        />
      </Box>

      <Container 
        maxWidth="md" 
        sx={{ 
          py: isMobile ? 3 : 6,
          px: isMobile ? 2 : 3,
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 600, color: neutral[950], mb: 4 }}>
          Impressum
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          Angaben gemäß § 5 TMG / Information according to § 5 TMG
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Plix AI GmbH<br />
          Kolonnenstraße 8<br />
          10827 Berlin, Germany
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          Vertreten durch / Authorized Representatives
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Lucas Pohling<br />
          Fiarra Pohling
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          Kontakt / Contact
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          info@plix-ai.com
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          Registergericht / District Court
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Amtsgericht Berlin-Charlottenburg
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          Handelsregister / Commercial Register
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          HRB 267262 B
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          Umsatzsteuer-Identifikationsnummer / VAT–ID No.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
         DE450289227
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          Hinweis zum Angebot „Thinki“
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
        Thinki (thinki.de) ist ein Angebot der Plix AI GmbH.
        </Typography>
        
        <Typography variant="h5" gutteDatenschrBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV / Responsible for the Content according to § 55 RStV
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Plix AI GmbH<br />
          Kolonnenstraße 8<br />
          10827 Berlin, Germany
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          Haftungsausschluss / Disclaimer
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ color: neutral[950], mt: 3, mb: 1 }}>
          Haftung für Inhalte
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ color: neutral[950], mt: 3, mb: 1 }}>
          Haftung für Links
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ color: neutral[950], mt: 3, mb: 1 }}>
          Urheberrecht
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </Typography>
        
        <Typography variant="body1" sx={{ color: neutral[950], mt: 4, textAlign: 'left', fontStyle: 'italic' }}>
          Stand, 01.04.2025
        </Typography>
      
      </Container>
    </Box>
  );
};

export default ImprintPage; 