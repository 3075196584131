import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { refreshToken as fetchRefreshToken } from '../utils/AuthenticationService';

// --- 1) Helper functions to decode and check expiration ---
function decodeToken(token) {
  try {
    const payloadBase64 = token.split('.')[1];
    const decoded = atob(payloadBase64); // decode from Base64
    return JSON.parse(decoded);
  } catch (error) {
    console.error('Fehler beim Decodieren des Tokens:', error);
    return null;
  }
}

function isTokenExpired(token) {
  const payload = decodeToken(token);
  if (!payload || !payload.exp) {
    return true; 
  }
  const currentTime = Math.floor(Date.now() / 1000);
  return payload.exp < currentTime;
}

// --- 2) AuthContext-Setup ---
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('accessToken'));
  const [loading, setLoading] = useState(true);
  const [logoutMessage, setLogoutMessage] = useState('');
  const navigate = useNavigate();

  // --- 3) login und logout Logik ---
  const login = (tokens) => {
    localStorage.setItem('accessToken', tokens.access);
    localStorage.setItem('refreshToken', tokens.refresh);
    setIsAuthenticated(true);
    setLogoutMessage('');
  };

  const logout = (message = 'Du wurdest ausgeloggt. Bitte logge dich erneut ein.') => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setIsAuthenticated(false);
    setLogoutMessage(message);
    // Weiterleitung über ProtectedRoute oder hier erzwingen (z.B. navigate('/login'))
  };

  // --- 4) Token-Status in checkAuthStatus prüfen ---
  const checkAuthStatus = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    // Falls ein accessToken existiert
    if (accessToken) {
      // 4a) checken, ob abgelaufen
      if (isTokenExpired(accessToken)) {
        // 4b) Versuchen, per refreshToken neues Token zu holen
        if (refreshToken) {
          try {
            const newAccessToken = await fetchRefreshToken();
            if (!newAccessToken) {
              // refresh hat nicht geklappt -> logout
              logout('Deine Sitzung ist abgelaufen, bitte logge dich erneut ein.');
            } else {
              localStorage.setItem('accessToken', newAccessToken);
              setIsAuthenticated(true);
            }
          } catch (error) {
            logout('Deine Sitzung ist abgelaufen, bitte logge dich erneut ein.');
          }
        } else {
          // Kein refreshToken -> logout
          logout('Deine Sitzung ist abgelaufen, bitte logge dich erneut ein.');
        }
      } else {
        // Token ist gültig
        setIsAuthenticated(true);
      }
    }
    // Falls kein accessToken, aber es gibt einen refreshToken
    else if (refreshToken) {
      try {
        const newAccessToken = await fetchRefreshToken();
        if (!newAccessToken) {
          logout('Deine Sitzung ist abgelaufen, bitte logge dich erneut ein.');
        } else {
          localStorage.setItem('accessToken', newAccessToken);
          setIsAuthenticated(true);
        }
      } catch (error) {
        logout('Deine Sitzung ist abgelaufen, bitte logge dich erneut ein.');
      }
    }
    // Weder accessToken noch refreshToken
    else {
      logout('Du musst eingeloggt sein, um auf diese Seite zugreifen zu können. Bitte logge dich ein!');
    }

    setLoading(false);
  };

  // --- 5) useEffect: checkAuthStatus beim Laden ---
  useEffect(() => {
    checkAuthStatus();

    // 401 Listener
    const handleUnauthorized = () => {
      logout('Deine Sitzung ist abgelaufen, bitte logge dich erneut ein.');
    };

    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, []);

  // --- 6) Context-Werte ---
  const contextValue = {
    isAuthenticated,
    login,
    logout,
    loading,
    logoutMessage,
    setLogoutMessage,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
