export const BASE_URL = process.env.REACT_APP_API_BASE_URL;


const ENDPOINTS = {
  REGISTER: `${BASE_URL}/auth/register`,
  ACTIVATE: `${BASE_URL}/auth/activate`,
  LOGIN: `${BASE_URL}/auth/login`,
  RESET: `${BASE_URL}/auth/password/reset/request`,
  CONFIRM : `${BASE_URL}/auth/password/reset/confirm`,
  COURSES: `${BASE_URL}/courses`,
  FILES: `${BASE_URL}/files/documents`,
  GET_FILE: (fileId) => `${BASE_URL}/files/documents/${fileId}`,
  COURSE_FILES: (courseId) => `${BASE_URL}/courses/${courseId}/files`,
  USER: `${BASE_URL}/auth/user`,
  UPLOAD : `${BASE_URL}/files/documents/upload`,
  DOWNLOAD: (fileId) => `${BASE_URL}/files/documents/download/${fileId}`,
  CREATE_QUESTIONSET: `${BASE_URL}/qa/questions/generate`,
  GET_QUESTIONSETS: `${BASE_URL}/qa/question-sets`,
  GET_QUESTIONSETS_BY_COURSE: (courseId) => `${BASE_URL}/qa/question-sets?course_id=${courseId}`,
  GET_QUESTIONSET: (questionSetId) => `${BASE_URL}/qa/question-sets/${questionSetId}`,
  DELETE_QUESTIONSET: (questionSetId) => `${BASE_URL}/qa/question-sets/${questionSetId}`,
  GET_QUESTION_BY_QUESTIONSET: (questionSetId) => `${BASE_URL}/qa/questions?question_set_id=${questionSetId}`,
  DELETE_QUESTION: (questionId) => `${BASE_URL}/qa/questions/${questionId}`,
  GET_FILE_PAGE_SMALL_IMAGES: (fileId) => `${BASE_URL}/files/documents/${fileId}/images-list`,
  EDIT_QUESTIONSET: (questionSetId) => `${BASE_URL}/qa/question-sets/${questionSetId}`,
  GET_SINGLE_QUESTION: (questionId) => `${BASE_URL}/qa/questions/${questionId}`,
  EDIT_QUESTION: (questionId) => `${BASE_URL}/qa/questions/${questionId}`,
  CREATE_QUESTION: `${BASE_URL}/qa/questions`,
  CHECK_QUESTIONSET_NAME_EXISTS: `${BASE_URL}/qa/question-sets/check-name`,
  CREATE_SINGLE_RESPONSE_ASSESSMENT: `${BASE_URL}/assess/single_response`,
  GET_SINGLE_RESPONSE_ASSESMENT: (assessmentId) => `${BASE_URL}/assess/single_response/${assessmentId}`,
  FEEDBACK: `${BASE_URL}/feedback`,
  FEEDBACK_DELETE: (feedbackId) => `${BASE_URL}/feedback/${feedbackId}`,
  FEEDBACK_EDIT: (feedbackId) => `${BASE_URL}/feedback/${feedbackId}`,
  FEEDBACK_VOTE: (feedbackId) => `${BASE_URL}/feedback/${feedbackId}/vote`,
  INTERFACE: `${BASE_URL}/preferences/interface`,
  GET_INTERFACE: `${BASE_URL}/preferences/interface`,
  CREATE_FLASHCARD_RATING: `${BASE_URL}/study/flashcards/ratings`,
  GET_QUESTION_CREATION_REQUEST: (requestId) => `${BASE_URL}/qa/question-creation-requests/${requestId}`,
  RESET_SINGLE_RESPONSE_ASSESSMENT: (questionSetId) => `${BASE_URL}/assess/single_response/reset/${questionSetId}`,
  GET_QUESTION_CREATION_REQUEST: (questionSetId) => `${BASE_URL}/qa/question-creation-requests/${questionSetId}`,
  SUMMARY_AI_GENERATE: `${BASE_URL}/sum/summaries/generate`,
  SUMMARIES_GET: `${BASE_URL}/sum/summaries`,
  SUMMARIES_GET_BY_COURSE: (courseId) => `${BASE_URL}/sum/summaries?course_id=${courseId}`,
  SUMMARY_GET: (summaryId) => `${BASE_URL}/sum/summaries/${summaryId}`,
  SUMMARY_DELETE: (summaryId) => `${BASE_URL}/sum/summaries/${summaryId}`,
  SUMMARY_EDIT: (summaryId) => `${BASE_URL}/sum/summaries/${summaryId}`,
  SUMMARY_CREATE: `${BASE_URL}/sum/summaries`,
  SUMMARY_CREATION_REQUEST: (summaryId) => `${BASE_URL}/sum/summary-creation-requests/${summaryId}`,
  GET_IMAGE: (fileId, imageId) => `${BASE_URL}/files/documents/${fileId}/images/${imageId}`,




  // Add more endpoints as needed
};

export default ENDPOINTS;

console.log(`BASE_URL: ${BASE_URL}`);
console.log(`LOGIN ENDPOINT: ${ENDPOINTS.LOGIN}`);


export const getAuthHeaders = () => {
  const token = localStorage.getItem('accessToken');
  return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
  };
};
export const fetchAuth = async (endpoint, options) => {
  const response = await fetch(endpoint, {
      ...options,
      headers: {
          ...getAuthHeaders(),
          ...options.headers
      }
  });

  // Handle token refresh logic as needed
  if (response.status === 401) {
      // Token refresh logic
  }

  return response;
};