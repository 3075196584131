import { createTheme, lighten } from '@mui/material/styles';

// Helper function to adjust colors (lighten or darken)
function adjustColor(color, amount) {
  return '#' + color.replace(/^#/, '').replace(/../g, color => 
      ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

// Create a fixed authentication theme with purple gradient
export const createAuthTheme = () => {
  // Use fixed purple color
  const baseColor = '#65256a';
  
  return createTheme({
    typography: {
      fontFamily: 'Nunito, sans-serif',
      h1: {
        lineHeight: 1.5,
        letterSpacing: '0.009em',
      },
    },
    palette: {
      primary: {
        main: baseColor,
        contrastText: '#fff',
      },
      secondary: {
        main: baseColor,
        contrastText: '#fff',
      },
      gradient: {
        background: `linear-gradient(${lighten(baseColor, 0.95)} 0%, ${baseColor} 100%)`,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            borderRadius: '30px',
            textTransform: 'none',
            letterSpacing: '0.07em',
            lineHeight: 1.2,
            fontSize: '16px'
          },
          containedPrimary: {
            '&:hover': {
              backgroundColor: baseColor,
            },
          },
        },
      },
    },
  });
};

// Export a ready-to-use theme instance
export const authTheme = createAuthTheme(); 