import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ENDPOINTS from '../../utils/apiConfig';
import { Box } from '@mui/material';

export default function DeleteCourseDialog({ courseId, open, onClose, onCourseUpdated }) {
  const [courseName, setCourseName] = useState('');
  const cancelButtonRef = useRef(null);

  // Fetch course details
  const fetchCourseDetails = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${ENDPOINTS.COURSES}/${courseId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch course details');
      }
      const data = await response.json();
      setCourseName(data.name);
    } catch (error) {
      console.error('Error fetching course details:', error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchCourseDetails();
      // Focus on "Abbrechen" button
      if (cancelButtonRef.current) {
        cancelButtonRef.current.focus();
      }
    }
  }, [open, courseId]);

  const handleDelete = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${ENDPOINTS.COURSES}/${courseId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete course');
      }
      onClose();
      onCourseUpdated();
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius: '20px' },
      }}
    >
      <DialogTitle>Kurs löschen</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Achtung! Wenn du den Kurs "{courseName}" löschst, gehen er und alle seine Inhalte unwiderruflich verloren.
          Diese Aktion kann nicht rückgängig gemacht werden.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 16px 24px', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Button
            onClick={handleDelete}
            variant="outlined"
            sx={{
              borderWidth: 2,
              borderStyle: 'dashed',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              color: 'text.primary',
              '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
                backgroundColor: 'transparent',
              },
            }}
          >
            Löschen
          </Button>
        </Box>
        <Box>
          <Button
            onClick={onClose}
            variant="outlined"
            ref={cancelButtonRef}
            sx={{
              borderWidth: 2,
              borderStyle: 'solid',
            }}
          >
            Abbrechen
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
