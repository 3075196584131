import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Paper, Typography, Button, Switch } from '@mui/material';
import ThinkiLogo from "../../assets/ThinkiLogo.svg";
import usePublicTheme from '../../hooks/usePublicTheme';
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { primary, secondary, neutral, gradients } = usePublicTheme();

  // Get the updateThemeColor function from the PublicLayout context
  const { updateThemeColor } = useOutletContext() || { updateThemeColor: () => {} };

  // Toggler for background color
  const [useYellow, setUseYellow] = React.useState(false);
  const handleToggle = () => {
    setUseYellow(prev => !prev);
    // Update the theme in the PublicLayout context for the footer
    updateThemeColor(!useYellow ? 'yellow' : 'purple');
  };
  
  // MUI hook to check if the screen is "small" (mobile)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Handler for desktop version
  const handleNavigateToLogin = () => {
    navigate('/login');
  };

  // Set initial theme color for the footer when component mounts
  React.useEffect(() => {
    updateThemeColor(useYellow ? 'yellow' : 'purple');
  }, [updateThemeColor]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100vw',
        minHeight: '100vh',
        background: useYellow ? gradients.primaryToDark : gradients.secondaryToDark,
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
        pb: 10, // Add padding for footer
      }}
    >
      {/* Main content container */}
      <Paper
        sx={{
          zIndex: 1,
          boxShadow: `0px 8px 16px ${useYellow ? primary(400) + '50' : secondary(400) + '50'}`,
          maxWidth: 800,
          width: '95%',
          margin: '0 auto',
          borderRadius: 3,
          textAlign: 'center',
          p: { xs: 4, md: 6 }, 
        }}
      >
        {/* Logo */}
        <Box sx={{ mb: 2 }}>
          <img 
            src={ThinkiLogo} 
            alt="Thinki Logo" 
            style={{ height: 100, width: 'auto' }}
          />
        </Box>

        {/* 
          Conditionally render either:
          - Desktop: "Anmelden" button
          - Mobile: "Um dich anzumelden..." text
        */}
        <Box sx={{ mb: 3 }}>
          {isMobile ? (
            <Typography variant="body1" sx={{ color: neutral(950) }}>
              Um dich anzumelden, wechsle bitte zu einem größeren Gerät.
            </Typography>
          ) : (
            <>
            
              <Button
                variant="outlined"
                onClick={() => navigate('/register')}
                sx={{ 
                  margin: 1,
                  color: useYellow ? primary(700) : secondary(700),
                  borderColor: useYellow ? primary(400) : secondary(400),
                  '&:hover': {
                    borderColor: useYellow ? primary(700) : secondary(700),
                    backgroundColor: useYellow ? primary(50) : secondary(50),
                  }
                }}
              >
                Registrieren
              </Button>
              <Button
                variant="contained"
                onClick={handleNavigateToLogin}
                sx={{ 
                  margin: 1,
                  backgroundColor: useYellow ? primary(600) : secondary(600),
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: useYellow ? primary(700) : secondary(700),
                  }
                }}
              >
                Anmelden
              </Button>
              
              
            </>
          )}
        </Box>

        {/* Headline & texts */}
        <Typography variant="h5" paragraph sx={{ fontWeight: 600, color: neutral(950) }}>
          Willkommen! Thinki ist dein KI-Begleiter fürs Studium.
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: neutral(950) }}>
          Du möchtest Beta-Tester werden oder uns kontaktieren? Schreibe uns an{" "}
          <Box
            component="a"
            href="mailto:info@thinki.de"
            sx={{ 
              color: useYellow ? primary(800) : secondary(800),
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            info@thinki.de
          </Box>
          .
        </Typography>

        <Typography variant="body2" paragraph sx={{ color: neutral(800) }}>
          Mehr über uns findest du auf unserer{" "}
          <Box
            component="a"
            href="https://www.plix-ai.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ 
              color: useYellow ? primary(800) : secondary(800),
              fontWeight: 500,
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Webseite
          </Box>
          .
        </Typography>

        {/* Toggler */}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Switch
            checked={useYellow}
            onChange={handleToggle}
            sx={{
              '& .MuiSwitch-thumb': {
                backgroundColor: useYellow ? primary(500) : secondary(500),
              },
              '& .MuiSwitch-track': {
                backgroundColor: useYellow ? primary(200) : secondary(200),
              },
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default LandingPage;
