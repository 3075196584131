import React, { useState, useRef, useEffect } from 'react';
import { Box, CssBaseline, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material';
import SideDrawer from '../components/Drawer';
import { useSidebar } from '../contexts/SidebarContext';
import { useFeedbackButton } from '../contexts/FeedbackButtonContext';
import { fetchUserDetails } from '../services/UserService';
import '../components/Drawer.css'; // Import the drawer CSS
import FloatingFeedbackButton from '../components/Button/FloatingFeedbackButton';

// These need to be outside the component or they'll be re-created on each render
let hueInterval = null;
let twistInterval = null;
let currentHue = 0;

const drawerWidth = 240;

const AppLayout = () => {
  const { isOpen, toggleDrawer, isFirstRender, animationsReady, handleCourseCreated } = useSidebar();
  const { hideGlobalButton } = useFeedbackButton();
  const theme = useTheme();
  const [userName, setUserName] = useState('');
  const [previousDrawerState, setPreviousDrawerState] = useState(isOpen);
  const [bounceClass, setBounceClass] = useState('');
  
  // Panic-related states
  const [panicMode, setPanicMode] = useState(false);
  const [panicButtonDisabled, setPanicButtonDisabled] = useState(false);
  const [showPostPanicMessage, setShowPostPanicMessage] = useState(false);
  const [postPanicMessage, setPostPanicMessage] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);
  
  // Get the gradient from the theme
  const originalGradient = theme.palette.gradient?.background;
  
  // Ref for the background Box
  const backgroundRef = useRef(null);
  
  // Initialize previous drawer state after initial render
  useEffect(() => {
    if (animationsReady) {
      setPreviousDrawerState(isOpen);
    }
  }, [animationsReady, isOpen]);
  
  // Handle bounce effect when drawer state changes
  useEffect(() => {
    // Skip if animations aren't ready yet
    if (!animationsReady) return;
    
    // If drawer state changed, apply appropriate bounce effect
    if (previousDrawerState !== isOpen) {
      // Determine which bounce animation to apply
      setBounceClass(isOpen ? 'main-content-bounce-open' : 'main-content-bounce-close');
      
      // Clear bounce class after animation finishes
      const timer = setTimeout(() => {
        setBounceClass('');
      }, 500); // Match the animation duration
      
      // Update previous state
      setPreviousDrawerState(isOpen);
      
      return () => clearTimeout(timer);
    }
  }, [isOpen, previousDrawerState, animationsReady]);
  
  // Get the appropriate class for content animation
  const contentAnimationClass = (() => {
    // During initial render, no animations
    if (isFirstRender) return 'no-transition';
    
    // Once animations are ready, provide appropriate classes
    if (animationsReady) {
      return `main-content-slide ${bounceClass}`;
    }
    
    // In between first render and animations being ready
    return 'no-transition';
  })();
  
  // Messages for post-panic
  const postPanicMessages = [
    'Atme durch und leg wieder los!',
    'Puh, kurze Pause und weiter gehts.',
    'Alles gut, weiter geht\'s!',
    'Tief durchatmen, du schaffst das!',
    'Kurzer Reset - jetzt mit frischer Energie!',
  ];

  // Fetch user details on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = await fetchUserDetails();
        setUserName(userDetails.username);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchData();
  }, []);

  // Set the panic background variable on mount
  useEffect(() => {
    if (originalGradient) {
      document.documentElement.style.setProperty('--panic-bg', originalGradient);
    }
  }, [originalGradient]);

  // Panic mode logic
  const handlePanicClick = () => {
    // Disable button immediately to prevent multiple clicks
    setPanicButtonDisabled(true);
    setPanicMode(true);
    startPanicEffects();

    setTimeout(() => {
      stopPanicEffects();
      setPanicMode(false);
      
      // Re-enable the button after a short delay (total 5 seconds from initial click)
      setTimeout(() => {
        setPanicButtonDisabled(false);
      }, 1000);
    }, 4000);
  };

  const startPanicEffects = () => {
    // Only add the general shake to the body
    document.body.classList.add('panic-shake');
    
    // Start color cycling
    startColorCycle();
    
    // Start random twists for specific elements
    startRandomTwists();
  };

  const stopPanicEffects = () => {
    // Remove panic classes
    document.body.classList.remove('panic-shake');
    
    stopColorCycle();
    stopRandomTwists();

    // Restore the original gradient
    document.documentElement.style.setProperty('--panic-bg', originalGradient);

    // Show random post-panic message
    const randomMsg = postPanicMessages[Math.floor(Math.random() * postPanicMessages.length)];
    setPostPanicMessage(randomMsg);
    setShowPostPanicMessage(true);

    setTimeout(() => {
      setMessageVisible(true);
    }, 50);

    setTimeout(() => {
      setMessageVisible(false);
    }, 3000);

    setTimeout(() => {
      setShowPostPanicMessage(false);
    }, 4000);
  };

  // Color cycle effect
  const startColorCycle = () => {
    currentHue = 0;
    if (backgroundRef.current) {
      backgroundRef.current.style.filter = 'hue-rotate(0deg)';
    }
    hueInterval = setInterval(() => {
      currentHue = (currentHue + 20) % 360;
      if (backgroundRef.current) {
        backgroundRef.current.style.filter = `hue-rotate(${currentHue}deg)`;
      }
    }, 50);
  };

  const stopColorCycle = () => {
    if (hueInterval) {
      clearInterval(hueInterval);
      hueInterval = null;
    }
    if (backgroundRef.current) {
      backgroundRef.current.style.filter = '';
    }
  };

  // Random twist effects
  const startRandomTwists = () => {
    // Focus on specific elements that should wiggle, but include drawer items
    const selectors = [
      '.paper-content', 
      'button', 
      'p', 
      'h1', 
      'h2', 
      'h3', 
      '.MuiListItemButton-root', 
      '.MuiListItemText-root',
      '.MuiListItemIcon-root',
      'span',
      '.course-list-item',
      '.course-list-title'
    ];
    
    twistInterval = setInterval(() => {
      selectors.forEach((sel) => {
        const elements = document.querySelectorAll(sel);
        elements.forEach((elem) => {
          // Skip elements that are part of color picker dialogs to prevent disrupting UI functionality
          if (elem.closest('.MuiDialog-root')) return;
          
          if (Math.random() < 0.5) {
            // Use more subtle rotation values (maximum 15 degrees instead of 40)
            const rotate = Math.floor(Math.random() * 15) - 7;
            // Use more subtle scaling values
            const scale = 1 + (Math.random() * 0.2 - 0.1);
            elem.style.transition = 'transform 0.2s ease';
            elem.style.transform = `rotate(${rotate}deg) scale(${scale})`;
          } else {
            // Reset
            elem.style.transform = '';
          }
        });
      });
    }, 300); // Back to the original timing
  };

  const stopRandomTwists = () => {
    if (twistInterval) {
      clearInterval(twistInterval);
      twistInterval = null;
    }
    
    // Make sure we reset transformed elements
    const resetSelectors = '.paper-content, button, p, h1, h2, h3, .MuiListItemButton-root, .MuiListItemText-root, .MuiListItemIcon-root, span, .course-list-item, .course-list-title';
    const allElements = document.querySelectorAll(resetSelectors);
    
    allElements.forEach((elem) => {
      elem.style.transform = '';
      elem.style.transition = '';
    });
  };

  return (
    <>
      <Box
        ref={backgroundRef}
        sx={{
          background: panicMode
            ? 'var(--panic-bg)'
            : theme.palette.gradient?.background,
          minHeight: '100vh',
          width: '100%',
          overscrollBehavior: 'contain',
        }}
      >
        <CssBaseline />

        {/* SideDrawer - shared across pages */}
        <SideDrawer
          open={isOpen}
          toggleDrawer={toggleDrawer}
          onCourseCreated={handleCourseCreated}
          onPanicClick={handlePanicClick}
          panicButtonDisabled={panicButtonDisabled}
        />

        {/* Main content area - child routes will render inside Outlet */}
        <Box
          component="main"
          className={contentAnimationClass}
          sx={{
            flexGrow: 1,
            p: 1,
            width: isOpen
              ? `calc(100% - ${drawerWidth}px)`
              : `calc(100% - 72px)`,
            ml: isOpen ? `${drawerWidth}px` : `72px`,
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'space-between',
          }}
        >
          {/* Shared Paper component that stays mounted during navigation */}
          <Paper
            elevation={3}
            sx={{
              flexGrow: 1,
              borderRadius: '10px',
              backgroundColor: 'rgba(255, 255, 255, 0.97)',
              boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* This is where the child routes will be rendered */}
            <Outlet />
          </Paper>
        </Box>
      </Box>

      {/* Post-panic message overlay */}
      {showPostPanicMessage && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
            color: '#fff',
            fontSize: '4em',
            fontFamily: 'Nunito, sans-serif',
            opacity: messageVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            textAlign: 'center',
            p: 3,
          }}
        >
          {postPanicMessage}
        </Box>
      )}

      {!hideGlobalButton && <FloatingFeedbackButton />}
    </>
  );
};

export default AppLayout; 