// AuthenticationService.js

/**
 * NOTE:
 * 1) We store tokens as localStorage.setItem('accessToken', ...) and
 *    localStorage.setItem('refreshToken', ...), to match the AuthContext logic.
 * 2) If your backend responds with 'accessToken'/'refreshToken' instead of 'access'/'refresh',
 *    just rename data.access / data.refresh below to match.
 */

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Logs the user in with username and password.
 * Expects the server response to provide "access" and "refresh" tokens.
 */
export const login = async (username, password) => {
  const response = await fetch(`${BASE_URL}/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  });
  
  if (!response.ok) {
    // Handle error response in German if desired, e.g.:
    // throw new Error('Benutzername oder Passwort ist ungültig.');
    throw new Error(`Login failed with status ${response.status}`);
  }

  const data = await response.json();
  // Store the tokens with underscores
  localStorage.setItem('accessToken', data.access);
  localStorage.setItem('refreshToken', data.refresh);
};

/**
 * Attempts to refresh the access token using the refresh token.
 * Returns the new access token, or null if refresh failed or was invalid.
 */
export const refreshToken = async () => {
  const rToken = localStorage.getItem('refreshToken');
  if (!rToken) {
    // No refresh token available
    return null;
  }

  try {
    const response = await fetch(`${BASE_URL}/refresh`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ refresh: rToken }),
    });

    if (response.ok) {
      const data = await response.json();
      // Store the new access token
      localStorage.setItem('accessToken', data.access);
      return data.access; // Return the new access token
    } else {
      // If the refresh token is invalid or expired, remove it from localStorage
      localStorage.removeItem('refreshToken');
      return null;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
};

// This event is listened for in your AuthContext to force logout when unauthorized
const unauthorizedEvent = new Event('unauthorized');

/**
 * Example of how to use the tokens in a fetch call.
 * If the first request is 401, we attempt to refresh and retry once.
 */
export const fetchCourseData = async (courseId) => {
  let accessToken = localStorage.getItem('accessToken');
  const url = `${BASE_URL}/courses/${courseId}`;
  console.log('Fetching data from URL:', url);

  try {
    let response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    // If request is not OK, check if it's unauthorized (401)
    if (!response.ok) {
      if (response.status === 401) {
        // Try refreshing the access token
        accessToken = await refreshToken();
        if (accessToken) {
          // Retry the request with the new access token
          response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          if (!response.ok) {
            // If still unauthorized, dispatch the event and throw
            window.dispatchEvent(unauthorizedEvent);
            throw new Error('Unauthorized');
          }
        } else {
          // If refresh token is invalid or missing, dispatch the unauthorized event
          window.dispatchEvent(unauthorizedEvent);
          throw new Error('Unauthorized');
        }
      } else {
        // Handle other HTTP errors
        const errorData = await response.json();
        console.error('API Error:', errorData);
        throw new Error(errorData.detail || 'Unknown API error');
      }
    }

    // If the response is OK, return the JSON data
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching course data:', error);
    throw error;
  }
};
