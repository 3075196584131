import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ENDPOINTS from '../../utils/apiConfig';
import { Box } from '@mui/material';

export default function DeleteFileDialog({ fileId, open, onClose, onFileUpdated }) {
    const [fileName, setFileName] = useState('');  // State to store the file name

    useEffect(() => {
        if (open) {
            const fetchFileDetails = async () => {
                const accessToken = localStorage.getItem('accessToken');
                try {
                    const response = await fetch(`${ENDPOINTS.FILES}/${fileId}`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch file details');
                    }
                    const data = await response.json();
                    setFileName(data.file_name);  // Set the fetched file name to state
                } catch (error) {
                    console.error('Error fetching file details:', error);
                }
            };
            fetchFileDetails();
        }
    }, [open, fileId]);

    const handleDelete = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${ENDPOINTS.FILES}/${fileId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to delete the file');
            }
            onClose();  // Close dialog on successful delete
            onFileUpdated();  // Trigger file list refresh
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{
            sx: {
              borderRadius: '20px' 
            }
          }}>
            <DialogTitle>Datei löschen</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Achtung! Wenn du die Datei "{fileName}" löschst, kannst du sie nicht mehr für die Erstellung von Lernmaterialien nutzen. Bist du dir sicher?
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: '0 24px 16px 24px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={handleDelete} variant="outlined"
            sx={{
              borderWidth: 2,
              borderStyle: 'dashed',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              color: 'text.primary',
              '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
                backgroundColor: 'transparent',
              },
            }}>
                        Löschen
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              borderWidth: 2,
              borderStyle: 'solid',
            }}
          >
            Abbrechen
          </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}


