import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { getTheme } from '../styles/theme';
import ENDPOINTS from '../utils/apiConfig';
import { useAuth } from '../contexts/AuthContext';

const ThemeContext = createContext();

const LOCAL_STORAGE_COLOR_KEY = 'userThemeColor'; // Key for storing the theme color

export const ThemeProvider = ({ children }) => {
    const authContext = useAuth(); // Get the context
    if (!authContext) {
        throw new Error('ThemeProvider must be used within an AuthProvider');
    }

    const { isAuthenticated, loading } = authContext; // Ensure destructuring after the check
    
    // Add a theme ready state to prevent flashing
    const [themeReady, setThemeReady] = useState(false);
    
    // Initialize theme from localStorage, falling back to default if not available
    const initialColor = localStorage.getItem(LOCAL_STORAGE_COLOR_KEY) || '#5B327B';
    const [themeMode, setThemeMode] = useState('purple'); // Default theme mode
    const [customColor, setCustomColor] = useState(initialColor); // Use cached color

    // Apply theme to document immediately on mount, before any rendering
    useEffect(() => {
        // Apply cached theme immediately
        const root = document.documentElement;
        const savedColor = localStorage.getItem(LOCAL_STORAGE_COLOR_KEY) || '#5B327B';
        
        // Apply a gradient background right away
        const lightColor = adjustColor(savedColor, 60); // Create lighter variant
        root.style.setProperty('--primary-color', savedColor);
        root.style.setProperty('--secondary-color', savedColor);
        root.style.setProperty('--gradient-background', `linear-gradient(${lightColor} 0%, ${savedColor} 100%)`);
        
        // Apply these styles to the body to prevent flashing
        document.body.style.background = `linear-gradient(${lightColor} 0%, ${savedColor} 100%)`;
        document.body.style.backgroundAttachment = 'fixed';
        
        // If we have a saved color, immediately set to individual theme
        if (savedColor !== '#5B327B') {
            setThemeMode('individual');
        }
        
        // Mark theme as ready
        setThemeReady(true);
    }, []);

    // Helper function to adjust colors
    function adjustColor(color, amount) {
        return '#' + color.replace(/^#/, '').replace(/../g, color => 
            ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    }

    useEffect(() => {
        const fetchColorPreference = async () => {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                console.error('No access token available.');
                return; // Exit if no token
            }

            console.log("Fetching color preference from:", ENDPOINTS.INTERFACE);

            try {
                const response = await fetch(ENDPOINTS.INTERFACE, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });

                console.log("Response status:", response.status);
                if (!response.ok) {
                    const textResponse = await response.text();
                    console.error(`Failed to fetch color preference: HTTP status ${response.status}`);
                    console.error('Response text:', textResponse);
                    return;
                }

                const data = await response.json();
                console.log('Received color preference:', data);
                
                // Update state with the fetched color
                const newColor = data.primary_color || '#5B327B';
                setCustomColor(newColor);
                
                // Cache the color in localStorage for future page loads
                localStorage.setItem(LOCAL_STORAGE_COLOR_KEY, newColor);
                
                setThemeMode('individual');
            } catch (error) {
                console.error('Error fetching color preference:', error);
            }
        };

        // Fetch the color preference only if the user is authenticated and loading is complete
        if (isAuthenticated && !loading) {
            fetchColorPreference();
        }
    }, [isAuthenticated, loading]); // Depend on authentication state and loading

    const theme = useMemo(() => getTheme(themeMode, customColor), [themeMode, customColor]);

    const toggleTheme = (mode) => {
        setThemeMode(mode);
    };

    useEffect(() => {
        const root = document.documentElement;
        const primaryColor = theme.palette.primary.main;
        const secondaryColor = theme.palette.secondary.main;
        const gradientBackground = theme.palette.gradient.background;
        
        root.style.setProperty('--primary-color', primaryColor);
        root.style.setProperty('--secondary-color', secondaryColor);
        root.style.setProperty('--gradient-background', gradientBackground);

        // Also apply to body for consistent background
        document.body.style.background = gradientBackground;
        document.body.style.backgroundAttachment = 'fixed';
    }, [theme]);

    const updateCustomColor = (color) => {
        setCustomColor(color); // Update the custom color
        localStorage.setItem(LOCAL_STORAGE_COLOR_KEY, color); // Cache the updated color
    };

    // If theme is not ready yet, show nothing (or a very minimal loader if needed)
    if (!themeReady) {
        return null;
    }

    return (
        <ThemeContext.Provider value={{ toggleTheme, updateCustomColor, themeMode, setThemeMode, customColor, setCustomColor }}>
            <MUIThemeProvider theme={theme}>
                {children}
            </MUIThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => useContext(ThemeContext);
