import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ENDPOINTS  from '../../utils/apiConfig';
import { useNavigate } from 'react-router-dom';
import { fetchUserCourses } from '../../services/CourseService'; // Import fetchUserCourses
import { emit, EVENT_TYPES } from '../../utils/eventEmitter'; // Import event emitter

export default function CreateCourseDialog({ open, onClose, onCourseCreated }) {
    const [error, setError] = useState('');  // State to store error messages
    const navigate = useNavigate();  // Initialize the navigate function

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const courseData = {
            name: formData.get('courseName')
        };

        try {
            const response = await fetch(ENDPOINTS.COURSES, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(courseData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Failed to create course:', errorData);
                // Handle specific known errors
                if (errorData.name && errorData.name.name === "A course with this name already exists for this user.") {
                    setError('Ein Kurs mit diesem Namen existiert bereits für diesen Benutzer. Bitte wähle einen anderen Namen.');
                } else if (errorData.name && errorData.name[0] === "This field may not be blank.") {
                    setError('Das Feld "Kursname" darf nicht leer sein.');
                } else {
                    setError('Ein Fehler ist aufgetreten. Bitte versuche es erneut.');
                }
                return; // Stop further execution in case of error
            }

            const responseData = await response.json();
            console.log('Course Created Successfully:', responseData);
            
            // Debug the onCourseCreated callback
            console.log('Calling onCourseCreated callback, is function?', typeof onCourseCreated === 'function');
            if (typeof onCourseCreated === 'function') {
                onCourseCreated();
                console.log('✅ onCourseCreated callback executed');
            } else {
                console.error('❌ onCourseCreated is not a function:', onCourseCreated);
            }
            
            // Directly call fetchUserCourses to update course list in any components listening to the function
            try {
                console.log('Directly calling fetchUserCourses to refresh course list...');
                await fetchUserCourses();
                console.log('✅ fetchUserCourses completed successfully');
            } catch (err) {
                console.error('Error refreshing courses directly:', err);
            }

            // Emit a global event for course creation that any component can listen to
            emit(EVENT_TYPES.COURSE_CREATED, responseData);
            console.log('✅ COURSE_CREATED event emitted');
            
            onClose();  // Close dialog after successful API response
            setError('');  // Reset error state
            
            // Navigate to the new course page after successful creation
            console.log('Navigating to new course page:', `/course/${responseData.id}`);
            navigate(`/course/${responseData.id}`, { state: { tab: '1' } });
        } catch (error) {
            console.error('Error creating course:', error);
            setError('Netzwerkfehler. Bitte versuchen Sie es erneut.'); // Handle unexpected errors
        }
    };

    return (
        <Dialog open={open} onClose={() => { setError(''); onClose(); }} PaperProps={{
            sx: {
              borderRadius: '20px' 
            }
          }}>
            <DialogTitle>Neuen Kurs erstellen</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="courseName"
                        label="Kursname"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="courseName"
                        error={!!error}
                        helperText={error}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setError(''); onClose(); }}>Abbrechen</Button>
                    <Button type="submit">Kurs erstellen</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
