import { useTheme } from '@mui/material/styles';

/**
 * Custom hook for accessing the public theme's color palette and properties
 * Provides easy access to color scales and gradients 
 */
const usePublicTheme = () => {
  const theme = useTheme();
  
  // Check if we have access to the full colors palette
  const hasColorPalette = theme.palette.colors !== undefined;
  
  return {
    // Color getters with fallbacks if not using the public theme
    primary: (shade = 500) => hasColorPalette 
      ? theme.palette.colors.primary[shade] 
      : theme.palette.primary.main,
      
    secondary: (shade = 500) => hasColorPalette 
      ? theme.palette.colors.secondary[shade] 
      : theme.palette.secondary.main,
      
    neutral: (shade = 500) => hasColorPalette 
      ? theme.palette.colors.neutral[shade] 
      : '#9E9E9E',
    
    // Utility function to access a color by its path
    color: (path) => {
      if (!hasColorPalette) return null;
      
      const parts = path.split('.');
      let result = theme.palette.colors;
      
      for (const part of parts) {
        if (result === undefined) return null;
        result = result[part];
      }
      
      return result;
    },
    
    // Gradient accessors
    gradients: theme.gradients || {
      primaryToLight: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
      primaryToDark: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 100%)`,
      secondaryToLight: `linear-gradient(135deg, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.light} 100%)`,
      secondaryToDark: `linear-gradient(135deg, ${theme.palette.secondary.light} 0%, ${theme.palette.secondary.dark} 100%)`,
    },
    
    // Check if we're using the public theme
    isPublicTheme: hasColorPalette,
  };
};

export default usePublicTheme;

/* Usage Example:

import usePublicTheme from '../hooks/usePublicTheme';

function MyComponent() {
  const { primary, secondary, neutral, gradients } = usePublicTheme();
  
  return (
    <Box 
      sx={{ 
        backgroundColor: primary(100),
        color: secondary(800),
        borderColor: neutral(300),
        background: gradients.primaryToLight
      }}
    >
      Content
    </Box>
  );
}
*/ 