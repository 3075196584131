import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Paper, Box, Button } from '@mui/material';
import { fetchQuestionsByQuestionSet } from '../../services/GetQuestionsService';
import { fetchQuestionSetById } from '../../services/GetQuestionSetService';
import './LearningMode.css';
import { useTheme } from '@mui/material';
import QuizMenu from '../../components/Menus/QuizMenu';
import StatisticsMenu from '../../components/Menus/StatisticsMenu';
import { ReactComponent as SadSmiley } from '../../assets/SadSmiley.svg';
import { ReactComponent as NeutralSmiley } from '../../assets/NeutralSmiley.svg';
import { ReactComponent as HappySmiley } from '../../assets/HappySmiley.svg';
import FlashcardRatingButtons, {
  SadSmileyButton,
  NeutralSmileyButton,
  HappySmileyButton,
} from '../../components/Button/FlashcardRatingButtons';
import Icon from '../../components/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import FloatingFeedbackButton from '../../components/Button/FloatingFeedbackButton';
import { useFeedbackButton } from '../../contexts/FeedbackButtonContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LearningMode = ({ open, onClose, questionSetId }) => {
  const { hideButton, showButton } = useFeedbackButton();
  
  // Hide the global button when this component mounts and is open
  useEffect(() => {
    if (open) {
      hideButton();
    }
    
    // Show the global button again when this component unmounts or closes
    return () => {
      if (open) {
        showButton();
      }
    };
  }, [open, hideButton, showButton]);

  const checkboxStateKey = `learningModeCheckboxState-${questionSetId}`;
  const sliderValueKey = `learningModeSliderValue-${questionSetId}`;

  const theme = useTheme();

  // use state hooks 
  const [questions, setQuestions] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [unshuffledQuestions, setUnshuffledQuestions] = useState([]);
  const [questionSetName, setQuestionSetName] = useState('');
  const [currentIndex, setCurrentIndex] = useState(() => {
    const savedIndex = localStorage.getItem(`learningModeIndex-${questionSetId}`);
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });
  const [isFlipped, setIsFlipped] = useState(false);
  const [displayContent, setDisplayContent] = useState('');
  const [contentType, setContentType] = useState('');
  const [isPoemVisible, setIsPoemVisible] = useState(false);
  const [isShuffled, setIsShuffled] = useState(true); 
  const [isWiggling, setIsWiggling] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [totalAvailableQuestions, setTotalAvailableQuestions] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [cornerSmileyPop, setCornerSmileyPop] = useState(false);
  const [counterAnimation, setCounterAnimation] = useState(false);
  const [prevCardCount, setPrevCardCount] = useState(0);

  // Initialize filter settings from localStorage
  const [checkboxState, setCheckboxState] = useState(() => {
    const savedCheckboxState = localStorage.getItem(checkboxStateKey);
    return savedCheckboxState
      ? JSON.parse(savedCheckboxState)
      : {
          sad: true,
          okay: true,
          happy: true,
          none: true,
        };
  });
  
  const [sliderValue, setSliderValue] = useState(() => {
    const savedSliderValue = localStorage.getItem(sliderValueKey);
    if (savedSliderValue !== null) {
      const parsedValue = parseInt(savedSliderValue, 10);
      // Check if parsedValue is a valid number and greater than 0
      if (!isNaN(parsedValue) && parsedValue > 0) {
        return parsedValue;
      }
    }
    // If not valid, default to null for now
    return null;
  });
  


  useEffect(() => {
    // If we have totalAvailableQuestions and no valid sliderValue, default to totalAvailableQuestions
    if (totalAvailableQuestions !== undefined && (sliderValue === null || sliderValue <= 0)) {
      console.log('Setting sliderValue to totalAvailableQuestions:', totalAvailableQuestions);
      setSliderValue(totalAvailableQuestions);
    }
  }, [totalAvailableQuestions, sliderValue]);
  



  const currentQuestion = (currentQuestions.length > 0 && currentIndex < currentQuestions.length) ? currentQuestions[currentIndex] : null;

    // Save filter settings to localStorage
    useEffect(() => {
      localStorage.setItem(checkboxStateKey, JSON.stringify(checkboxState));
    }, [checkboxState]);

    


    // Fetch questions on component mount & when 'open' changes
useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true); // 1) Turn on spinner
    
    // Always reset to question side when opening
    setIsFlipped(false);
    setDisplayContent('');
    setContentType('');
    setIsPoemVisible(false);
    
    try {
      const questionData = await fetchQuestionsByQuestionSet(questionSetId);
      setQuestions(questionData);

      const questionSetData = await fetchQuestionSetById(questionSetId);
      setQuestionSetName(questionSetData.name);
      
    } catch (error) {
      console.error('Failed to fetch question set details:', error);
    } finally {
      setIsLoading(false); // 2) Turn off spinner (done or error)
    }
  };

  if (open) {
    fetchData();
  }
}, [open, questionSetId]);

// 2) Single effect to apply filters
useEffect(() => {
  if (!isLoading) {
    console.log("Applying filters after data loaded");
    applyFilters();
  }
}, [isLoading]);

useEffect(() => {
  // If you want immediate re-filter when the user toggles
  // some filter in the menu, do it here:
  if (!isLoading && questions.length > 0) {
    console.log("Applying filters due to slider or checkbox changes");
    applyFilters();
  }
}, [sliderValue, checkboxState]);
    

// Event handler to pass checkbox change 
const handleCheckboxChange = (updatedCheckboxState) => {
  // Compare with current state to see if there's a change in filtering
  const isFilterChanged = Object.keys(checkboxState).some(
    key => checkboxState[key] !== updatedCheckboxState[key]
  );

  setCheckboxState(updatedCheckboxState);

  // Recalculate totalAvailableQuestions based on the updated checkboxState
  let selectedStatuses = Object.keys(updatedCheckboxState).filter(
    (key) => updatedCheckboxState[key]
  );

  // If no statuses are selected, default to all
  if (selectedStatuses.length === 0) {
    selectedStatuses = ['sad', 'okay', 'happy', 'none'];
  }

  const filteredQuestions = filterQuestionsByStatus(questions, selectedStatuses);
  const totalAvailable = filteredQuestions.length;

  setTotalAvailableQuestions(totalAvailable); // Update state

  setSliderValue(totalAvailable > 0 ? totalAvailable : null);
  
  // If filter is changing, ensure we reset to question side
  if (isFilterChanged) {
    console.log("Filter checkbox changed - ensuring front side is shown");
    setIsFlipped(false);
    setDisplayContent('');
    setContentType('');
    setIsPoemVisible(false);
  }
};




  // Handle shuffling
  const shuffleQuestions = (questionsToShuffle) => {
    let shuffled = [...questionsToShuffle];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    if (totalAvailableQuestions !== undefined && sliderValue === null) {
      console.log('Setting sliderValue to totalAvailableQuestions:', totalAvailableQuestions);
      setSliderValue(totalAvailableQuestions);
    }
  }, [totalAvailableQuestions, sliderValue]);
  

  const toggleShuffle = () => {
    setIsWiggling(true);
    setTimeout(() => {
      setIsWiggling(false);
    }, 600);

    if (!isShuffled) {
      setUnshuffledQuestions(currentQuestions);
      const shuffled = shuffleQuestions(currentQuestions);
      setCurrentQuestions(shuffled);
      setCurrentIndex(0);
      setIsShuffled(true);
    } else {
      setCurrentQuestions(unshuffledQuestions);
      setCurrentIndex(0);
      setIsShuffled(false);
    }
  };

// Filter questions based on selected statuses
const filterQuestionsByStatus = (questionsToFilter, statuses) => {
  return questionsToFilter.filter((question) => {
    if (!question) return false;
    const status = question.flashcard_status || 'none';
    return statuses.includes(status);
  });
};

 // Ensure sliderValue does not exceed totalAvailableQuestions
  useEffect(() => {
    if (sliderValue != null && sliderValue > totalAvailableQuestions) {
      setSliderValue(totalAvailableQuestions);
    }
  }, [totalAvailableQuestions, sliderValue]);



  

// Apply filters
const applyFilters = () => {
  console.log("applyFilters called");
  // 1) Figure out which statuses are selected
  let selectedStatuses = Object.keys(checkboxState).filter(key => checkboxState[key]);
  if (selectedStatuses.length === 0) {
    selectedStatuses = ['sad', 'okay', 'happy', 'none'];
  }

  // 2) Filter
  const filtered = questions.filter(q => {
    const status = q.flashcard_status || 'none';
    return selectedStatuses.includes(status);
  });

  // 3) Update total
  const totalAvailable = filtered.length;
  setTotalAvailableQuestions(totalAvailable);

  // 4) Make sure sliderValue is valid
  const safeSlider = (sliderValue === null || sliderValue <= 0) ? totalAvailable : sliderValue;
  const finalCount = Math.min(safeSlider, totalAvailable);

  // 5) slice
  let limitedQuestions = filtered.slice(0, finalCount);

  // 6) shuffle or not
  if (isShuffled) {
    limitedQuestions = shuffleQuestions(limitedQuestions);
  }
  
  // Store the previous questions length to check if the set has changed
  const previousLength = currentQuestions.length;
  const isChangingSet = previousLength !== limitedQuestions.length;
  
  // Apply the new questions
  setCurrentQuestions(limitedQuestions);
  setUnshuffledQuestions(limitedQuestions);

  // 7) Reset index if needed
  if (limitedQuestions.length === 0) {
    setCurrentIndex(0);
  } else if (currentIndex >= limitedQuestions.length) {
    setCurrentIndex(0);
  }
  
  // 8) Always ensure we're showing the front side when filter is applied or set changes
  if (isFlipped || isChangingSet) {
    console.log("Filter applied - ensuring front side is shown");
    setIsFlipped(false);
    setDisplayContent('');
    setContentType('');
    setIsPoemVisible(false);
  }

  setIsMenuOpen(false);
};


useEffect(() => {
  if (questions.length === 0) return; 
  let selectedStatuses = Object.keys(checkboxState).filter(
    (key) => checkboxState[key]
  );

  // If no statuses are selected, default to all
  if (selectedStatuses.length === 0) {
    selectedStatuses = ['sad', 'okay', 'happy', 'none'];
  }

  const filteredQuestions = filterQuestionsByStatus(questions, selectedStatuses);
  const totalAvailable = filteredQuestions.length;

  setTotalAvailableQuestions(totalAvailable); // Update state

  if (sliderValue != null && sliderValue > totalAvailable) {
    setSliderValue(totalAvailable);
  }
}, [questions]);

  // Update flashcard status and ensure front side showing for next card
  const updateFlashcardStatus = (newStatus) => {
    if (!currentQuestion) return;
    
    // Track if this is a status change that might lead to card filtering
    const isStatusChange = currentQuestion.flashcard_status !== newStatus;
    
    // If the status is changing, trigger the pop animation
    if (isStatusChange) {
      setCornerSmileyPop(true);
      
      // Reset animation flag after animation completes
      setTimeout(() => {
        setCornerSmileyPop(false);
      }, 500); // Match the CSS animation duration
    }
    
    // Update all question collections
    const updatedQuestions = questions.map((q) =>
      q.id === currentQuestion.id ? { ...q, flashcard_status: newStatus } : q
    );
    setQuestions(updatedQuestions);
  
    const updatedCurrentQuestions = currentQuestions.map((q) =>
      q.id === currentQuestion.id ? { ...q, flashcard_status: newStatus } : q
    );
    setCurrentQuestions(updatedCurrentQuestions);
  
    if (isShuffled) {
      const updatedUnshuffledQuestions = unshuffledQuestions.map((q) =>
        q.id === currentQuestion.id ? { ...q, flashcard_status: newStatus } : q
      );
      setUnshuffledQuestions(updatedUnshuffledQuestions);
    }

    // If any checkboxes are unchecked, and we just changed a card to that status,
    // it will disappear on the next filter application
    const mightDisappear = isStatusChange && !checkboxState[newStatus];
    
    // If the card might be filtered out, make sure to flip back to front for next card
    if (mightDisappear) {
      console.log("Card might be filtered out, ensuring next card shows front side");
      // Immediately flip to front side
      setIsFlipped(false);
      
      // Clear any displayed content
      setDisplayContent('');
      setContentType('');
      setIsPoemVisible(false);
    }
  };
  

// Save checkboxState to localStorage whenever it changes
useEffect(() => {
  localStorage.setItem(checkboxStateKey, JSON.stringify(checkboxState));
}, [checkboxState]);

// Save sliderValue to localStorage whenever it changes
useEffect(() => {
  if (sliderValue !== null && sliderValue !== undefined && !isNaN(sliderValue) && sliderValue > 0) {
    localStorage.setItem(sliderValueKey, String(sliderValue));
  } else {
    localStorage.removeItem(sliderValueKey);
  }
}, [sliderValue]);




  // NORMAL STUFF HAPPENS AGAIN

  const handlePaperClick = () => {
    setIsFlipped(!isFlipped);
    setDisplayContent('');  // Clear display content when flipping the paper
};

useEffect(() => {
  console.log("Current Index =", currentIndex);
  localStorage.setItem(`learningModeIndex-${questionSetId}`, currentIndex);
}, [currentIndex, questionSetId]);


const handleNextClick = () => {
  console.log("Next Click: Current Index =", currentIndex);
  if (currentIndex < currentQuestions.length - 1) {
    setCurrentIndex(prevIndex => prevIndex + 1);
  } else {
    setCurrentIndex(0); // Go back to the first card
    console.log("After Next Click: Reached end, going to first card");
  }
  
  // Always reset to question side and clear any displayed content
  setIsFlipped(false);
  setDisplayContent('');
  setContentType('');
  setIsPoemVisible(false);
};

const handlePrevClick = () => {
  console.log("Prev Click: Current Index =", currentIndex);
  if (currentIndex > 0) {
    setCurrentIndex(prevIndex => prevIndex - 1);
  } else {
    setCurrentIndex(currentQuestions.length - 1); // Go back to the last card
    console.log("After Prev Click: Reached start, going to last card");
  }
  
  // Always reset to question side and clear any displayed content
  setIsFlipped(false);
  setDisplayContent('');
  setContentType('');
  setIsPoemVisible(false);
};

// Update the keyDown handler to also handle Enter key for flipping
useEffect(() => {
  const handleKeyDown = (event) => {
    // Skip if focused on an input field
    if (document.activeElement.tagName === 'TEXTAREA' || 
        document.activeElement.tagName === 'INPUT') {
      return;
    }
    
    if (event.key === 'ArrowLeft') {
      handlePrevClick();
    } else if (event.key === 'ArrowRight') {
      handleNextClick();
    } else if (event.key === 'Enter') {
      // Flip the card on Enter key
      setIsFlipped(!isFlipped);
      setDisplayContent('');  // Clear display content when flipping
    }
  };

  if (open) {
    window.addEventListener('keydown', handleKeyDown);
  }

  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}, [open, handlePrevClick, handleNextClick, isFlipped]);

// Add a new useEffect for the smiley rating keyboard shortcuts
useEffect(() => {
  const handleKeyboardRating = (event) => {
    // Skip if focused on an input field
    if (document.activeElement.tagName === 'TEXTAREA' || 
        document.activeElement.tagName === 'INPUT') {
      return;
    }
    
    // Only proceed if we have a current question
    if (!currentQuestion) return;
    
    // Use numeric keys 1, 2, 3 for ratings
    if (event.key === '1') {
      updateFlashcardStatus('sad');
    } else if (event.key === '2') {
      updateFlashcardStatus('okay');
    } else if (event.key === '3') {
      updateFlashcardStatus('happy');
    }
  };
  
  if (open) {
    window.addEventListener('keydown', handleKeyboardRating);
  }
  
  return () => {
    window.removeEventListener('keydown', handleKeyboardRating);
  };
}, [open, currentQuestion, updateFlashcardStatus]);

const handleQuoteClick = (e) => {
  e.stopPropagation(); // Prevent event bubbling


  // Safely retrieve quote content, file name, and page number
  const quoteContent = currentQuestion?.answer?.quote?.content || null;
  const fileName = currentQuestion?.source_text_detail?.file?.file_name || null;
  const pageNumber = currentQuestion?.source_text_detail?.page_number || null;

  // Check if all required details are available
  if (quoteContent && fileName && pageNumber !== undefined) {
    const newDisplayContent = `${quoteContent} (${fileName}, S. ${pageNumber})`;
    console.log({ quoteContent, fileName, pageNumber });

    // Toggle visibility or update content
    if (displayContent === newDisplayContent && contentType === 'quote') {
      setDisplayContent(''); // Clear content
      setContentType(''); // Reset type
    } else {
      setDisplayContent(newDisplayContent); // Update content
      setContentType('quote'); // Set type to 'quote'
    }
  } else {
    // Handle missing data gracefully
    setDisplayContent('Entschuldige, kein Zitat für diese Frage verfügbar.');
    setContentType('quote');
  }
};



const emojis = ["🌟", "📘", "🌈", "🍂", "🌼", "🌙", "✨", "🖋️", "📜"];

const handlePoemClick = (e) => {
  e.stopPropagation(); // Prevent event from bubbling up
  
  // Safely grab current question data from currentQuestion
  if (!currentQuestion) {
    setDisplayContent(['Für diese Frage ist kein Gedicht vorhanden.']);
    setIsPoemVisible(true);
    setContentType('poem');
    return;
  }

  const poemContent = currentQuestion?.answer?.memory_poem?.content || '';

  if (poemContent) {
    const formattedPoem = poemContent.split(/\\n|\n/);

    // Toggle logic
    if (isPoemVisible && contentType === 'poem') {
      setDisplayContent('');
      setIsPoemVisible(false);
      setContentType('');
    } else {
      setDisplayContent(formattedPoem);
      setIsPoemVisible(true);
      setContentType('poem');
    }
  } else {
    // Handle case where poem content is not available
    setDisplayContent(['Für diese Frage ist kein Gedicht vorhanden.']);
    setIsPoemVisible(true);
    setContentType('poem');
  }
};




const getSmileyIcon = (status) => {
  switch (status) {
    case 'happy':
      return HappySmiley;
    case 'okay':
      return NeutralSmiley;
    case 'sad':
      return SadSmiley;
    default:
      return null; // No icon for 'none' or undefined status
  }
};


const flashcardStatus = currentQuestion ? currentQuestion.flashcard_status : null;
const SmileyIcon = getSmileyIcon(flashcardStatus);

// Add this useEffect to track changes in currentQuestions.length
useEffect(() => {
  // Skip initial render
  if (prevCardCount > 0 && prevCardCount !== currentQuestions.length) {
    // Total number of cards has changed - trigger animation
    setCounterAnimation(true);
    
    // Reset animation flag after animation completes
    setTimeout(() => {
      setCounterAnimation(false);
    }, 700); // Match the CSS animation duration
  }
  
  // Update the previous count
  setPrevCardCount(currentQuestions.length);
}, [currentQuestions.length, prevCardCount]);

// Let's add a specific handleSliderChange function
const handleSliderChange = (newValue) => {
  console.log("Slider changed to:", newValue);
  
  // Always reset to question side when changing the number of cards
  setIsFlipped(false);
  setDisplayContent('');
  setContentType('');
  setIsPoemVisible(false);
  
  // Update the slider value which will trigger a re-filter
  setSliderValue(newValue);
};

return (
  <Dialog
    fullScreen
    open={open}
    onClose={onClose}
    TransitionComponent={Transition}
    PaperProps={{
      sx: {
        margin: 0,
        borderRadius: 0,
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        overflow: 'hidden',
      },
    }}
  >
    {/* Top-level container with gradient background */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
        background: theme.palette.gradient.background,
        position: 'relative',
      }}
    >
      {/* Large outer Paper that nearly covers the screen */}
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          flex: 1,
          m: 2,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgba(255, 255, 255, 0.97)',
          boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',
          overflow: 'hidden',
        }}
      >
        {/* Non-scrolling Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            backgroundColor: 'transparent',
          }}
        >
          <Typography sx={{ flex: 1, color: 'grey' }} variant="h6" component="div">
            {questionSetName || 'Loading...'}
          </Typography>
          <IconButton
            edge="start"
            color="gray"
            onClick={onClose}
            aria-label="close"
            sx={{
              marginLeft: 'auto',
              width: 30,
              height: 30,
              padding: 0,
              position: 'absolute',
              top: 25,
              right: 20,
              '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: 'transparent',
              },
            }}
          >
            <Icon iconName="cross" style={{ width: 30, height: 30 }} />
          </IconButton>
        </Box>

        {/* Scrollable container for the flipping Paper content + pinned menus */}
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            minHeight: 0,
            px: 2,
            pb: 8,
            mt: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 4,
              xl: 10,
            },
          }}
        >
          {isLoading ? (
    // -------- SHOW LOADING SPINNER --------
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '80%',
      }}
    >
      <CircularProgress
        size={80}
        sx={{ color: theme.palette.primary.main }} 
      />
    </Box>
  ) : currentQuestions.length === 0 ? (
    // -------- SHOW "OOPS" MESSAGE --------
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <Typography variant="h4" sx={{ fontSize: '1.8em' }}>
        Oops! Keine Frage verfügbar.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Bitte überprüfe deine Filtereinstellungen oder versuche es später erneut.
      </Typography>
    </Box>
  ) : (
          
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2, mt: 2, }}>
            <div className={`flip-container ${isWiggling ? 'wiggle' : ''}`} onClick={handlePaperClick}>
              <div className={`flipper ${isFlipped ? 'flipped' : ''}`}>
                {/* FRONT PAPER */}
                <Paper
                  className="front paper-content"
                  sx={{
                   
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column', // Stack items vertically
                      justifyContent: 'space-between', // Space between items
                      alignItems: 'center',
                      borderRadius: "20px", 
                    }}
                  
               
                >
                  {/* Optional smiley icon in the corner */}
                  {SmileyIcon && (
                    <SmileyIcon
                      style={{
                        position: 'absolute',
                        top: 20,
                        right: 20,
                        width: 50,
                        height: 50,
                        color: theme.palette.primary.main,
                      }}
                      className={cornerSmileyPop ? "corner-smiley-pop" : ""}
                    />
                  )}

                  {/* Content Container */}
                  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    {currentQuestion ? (
                      <Typography variant="h5" component="div" align="center" sx={{ fontSize: '1.8em' }}>
                        {currentQuestion.content.split(/\/n|\\n/).map((line, idx) => (
                          <React.Fragment key={idx}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    ) : (
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" component="div" align="center" sx={{ fontSize: '1.8em' }}>
                          Oops! Keine Frage verfügbar.
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ mt: 2 }}>
                          Bitte überprüfe deine Filtereinstellungen oder versuche es später erneut.
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  {/* Rating Buttons (sad/neutral/happy) */}
                  {currentQuestion && (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Box>
                        <SadSmileyButton
                          questionId={currentQuestion.id}
                          onStatusUpdate={updateFlashcardStatus}
                          defaultColor="#d1d1d1"
                        />
                      </Box>
                      <Box sx={{ ml: 30, mr: 30 }}>
                        <NeutralSmileyButton
                          questionId={currentQuestion.id}
                          onStatusUpdate={updateFlashcardStatus}
                          defaultColor="#d1d1d1"
                        />
                      </Box>
                      <Box>
                        <HappySmileyButton
                          questionId={currentQuestion.id}
                          onStatusUpdate={updateFlashcardStatus}
                          defaultColor="#d1d1d1"
                        />
                      </Box>
                    </Box>
                  )}
                </Paper>

                {/* BACK PAPER */}
                <Paper
                  className="back paper-content"
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    justifyContent: 'space-between', // Space between items
                    alignItems: 'center',
                    borderRadius: "20px", 
                  }}
                >
                  {SmileyIcon && (
                    <SmileyIcon
                      style={{
                        position: 'absolute',
                        top: 20,
                        right: 20,
                        width: 50,
                        height: 50,
                        color: theme.palette.primary.main,
                      }}
                      className={cornerSmileyPop ? "corner-smiley-pop" : ""}
                    />
                  )}

                  <Box
                    sx={{
                      flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    overflowY: 'auto',              // Enable vertical scrolling when needed
    maxHeight: 'calc(100% - 60px)',   // Reserve space for fixed items below (adjust 80px as needed)
    px: 1,   
                    }}
                  >
                    <Box sx={{ mt: 'auto', mb: 'auto', width: '100%' }}>
                    <Typography variant="h5" component="div" align="center" sx={{ fontSize: '1.1em', mb: 2 }}>
                      {currentQuestion && currentQuestion.answer && currentQuestion.answer.content
                        ? currentQuestion.answer.content.split(/\/n|\\n/).map((line, idx) => (
                            <React.Fragment key={idx}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))
                        : 'No answer available'}
                    </Typography>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        mt: 2,
                      }}
                    >
                      <Button
                        color="secondary"
                        variant="outlined"
                        sx={{ mx: 1 }}
                        onClick={(e) => handleQuoteClick(e)}
                      >
                        Quelle
                      </Button>
                      <Button
                        color="secondary"
                        variant="outlined"
                        sx={{ mx: 1 }}
                        onClick={(e) => handlePoemClick(e)}
                      >
                        Gedicht
                      </Button>
                    </Box>

                    {/* Additional text content (quotes, poems) */}
                    <Typography
                      variant="subtitle1"
                      component="div"
                      align="center"
                      sx={{
                        mt: 4,
                        fontFamily: "'Nunito', sans-serif",
                        fontStyle: 'italic',
                        fontSize: contentType === 'quote' ? '1em' : 'inherit',
                      }}
                    >
                      {contentType === 'quote' && displayContent}
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      component="div"
                      align="center"
                      sx={{
                        width: '100%',
                        mt: 4,
                        fontFamily: contentType === 'poem' ? "'Dancing Script', cursive" : 'inherit',
                        fontSize: contentType === 'poem' ? '1.2em' : 'inherit',
                      }}
                      className={contentType === 'poem' ? 'sparkly-text' : ''}
                    >
                      {Array.isArray(displayContent) &&
                        displayContent.map((line, idx) => (
                          <React.Fragment key={idx}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </Typography>
                  </Box>
                  </Box>

                  {/* Rating Buttons on the back side */}
                  {currentQuestion && (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Box>
                        <SadSmileyButton
                          questionId={currentQuestion.id}
                          onStatusUpdate={updateFlashcardStatus}
                          defaultColor="#d1d1d1"
                        />
                      </Box>
                      <Box sx={{ ml: 30, mr: 30 }}>
                        <NeutralSmileyButton
                          questionId={currentQuestion.id}
                          onStatusUpdate={updateFlashcardStatus}
                          defaultColor="#d1d1d1"
                        />
                      </Box>
                      <Box>
                        <HappySmileyButton
                          questionId={currentQuestion.id}
                          onStatusUpdate={updateFlashcardStatus}
                          defaultColor="#d1d1d1"
                        />
                      </Box>
                    </Box>
                  )}
                </Paper>
              </div>
            </div>

            {/* Button Container (below the flip-container) */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <IconButton
                  color="secondary"
                  onClick={handlePrevClick}
                  sx={{
                    mx: 8,
                    width: 56,
                    height: 56,
                  }}
                >
                  <Icon iconName="leftCircle" style={{ width: 40, height: 40 }} />
                </IconButton>

                {/* Fixed width container to prevent layout shift */}
                <Box sx={{ 
                  width: '60px', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  height: '40px' 
                }}>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      userSelect: 'none',
                      color: theme.palette.secondary.main
                    }}
                    className={counterAnimation ? "card-counter-animate" : ""}
                  >
                    {`${currentIndex + 1}/${currentQuestions.length}`}
                  </Typography>
                </Box>

                <IconButton
                  color="secondary"
                  onClick={handleNextClick}
                  sx={{
                    mx: 8,
                    width: 56,
                    height: 56,
                  }}
                >
                  <Icon iconName="rightCircle" style={{ width: 40, height: 40 }} />
                </IconButton>
              </Box>
            </Box>
          </Box>

          )}
          <Box
            sx={{
              position: 'absolute',
              bottom: 30,
              left: 30,
            }}
          >
            <QuizMenu
              totalQuestions={totalAvailableQuestions}
              onCheckboxChange={handleCheckboxChange}
              onSliderChange={handleSliderChange}
              sliderValue={sliderValue}
              checkboxState={checkboxState}
              pinnedStateKey={`quizMenuPinned-${questionSetId}`}
              isShuffled={isShuffled}
              toggleShuffle={toggleShuffle}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 30,
              right: 30,
            }}
          >
            <StatisticsMenu
              questions={questions}
              pinnedStateKey={`statisticsMenuPinned-${questionSetId}`}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
    <FloatingFeedbackButton position="center" zIndex={1500} />
  </Dialog>
);

};

export default LearningMode;
