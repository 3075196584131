import React, { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';
import { authTheme } from '../../styles/authTheme';
import usePublicTheme from '../../hooks/usePublicTheme';
import { primaryColor, secondaryColor } from '../../styles/publicTheme';


const AccountActivatedPage = () => {
  const navigate = useNavigate();
  const { gradients } = usePublicTheme();
  
  // Get the updateThemeColor function from the PublicLayout context
  const { updateThemeColor } = useOutletContext() || { updateThemeColor: () => {} };

  // Set the theme color when component mounts
  useEffect(() => {
    updateThemeColor('purple'); // Use purple theme
  }, [updateThemeColor]);

  const handleNavigateToLogin = () => {
    navigate('/login');
  };

  return (
    <ThemeProvider theme={authTheme}> 
      <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
          background: gradients.secondaryLight,
          backgroundAttachment: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          p: 3
        }}
      >
        <Box 
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '16px',
            p: 4,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            maxWidth: '600px',
            width: '90%'
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600, color: authTheme.palette.primary.main }}>
            JUHU! Dein Account konnte aktiviert werden
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Jetzt kannst du einfach loslegen.
          </Typography>
          <Button 
            variant="contained" 
            onClick={handleNavigateToLogin} 
            sx={{ 
              padding: '10px 30px',
              fontSize: '18px'
            }}
          >
            Ab zum Login
          </Button>
        </Box>
      </Box>
    </ThemeProvider> 
  );
};

export default AccountActivatedPage;
