import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Chip,
 Typography,
 Box,
 useTheme,
 FormControl,
 InputLabel,
 Select,
 MenuItem,
 TextField,
 Button,
 FormControlLabel,
 Switch,
 Stack,
 Divider,
 Card,
 CardContent,
 IconButton,
 Tooltip,
 Zoom,
 Collapse,
 Dialog,
 DialogActions,
 DialogContent,
 DialogContentText,
 DialogTitle
} from '@mui/material';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import Icon from '../components/Icon' ;
import ENDPOINTS from '../utils/apiConfig';
import EditFeedbackDialog from '../features/EditFeedbackDialog/EditFeedbackDialog';

// Import Material UI icons directly to avoid theme icon issues
import SendIcon from '@mui/icons-material/Send';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ListIcon from '@mui/icons-material/List';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CreateIcon from '@mui/icons-material/Create';

// Helper function to determine the chip color for priority
const getPriorityColor = (priority) => {
 switch (priority) {
   case 'high':
     return 'error';
   case 'medium':
     return 'warning';
   case 'low':
     return 'success';
   default:
     return 'default';
 }
};


// Helper function to determine the chip color for feedback type
const getFeedbackTypeColor = (type) => {
 switch (type) {
   case 'bug':
     return 'error';
   case 'improvement':
     return 'info';
   case 'idea':
     return 'success';
   case 'criticism':
     return 'warning';
   default:
     return 'default';
 }
};


// Helper function to format the feedback type for display
const formatFeedbackType = (type) => {
 switch (type) {
   case 'bug':
     return 'Bug/Fehler';
   case 'improvement':
     return 'Verbesserungsvorschlag';
   case 'idea':
     return 'Idee/Wunsch';
   case 'criticism':
     return 'Kritik';
   case 'other':
     return 'Anderes';
   default:
     return type;
 }
};


// Feedback types
const FEEDBACK_TYPES = [
 { value: 'bug', label: 'Bug/Fehler' },
 { value: 'idea', label: 'Idee/Wunsch' },
 { value: 'improvement', label: 'Verbesserungsvorschlag' },
 { value: 'criticism', label: 'Kritik' },
 { value: 'other', label: 'Lob' },
 { value: 'other', label: 'Anderes' }
];


// Priority levels
const PRIORITY_LEVELS = [
 { value: 'low', label: 'Niedrig' },
 { value: 'medium', label: 'Mittel' },
 { value: 'high', label: 'Hoch' }
];


// Emotion options
const EMOTION_OPTIONS = [
 { value: 'happy', label: 'Fröhlich ✨' },
 { value: 'amused', label: 'Amüsiert 🥳' },
 { value: 'woot', label: 'WOOT WOOT 🔥' },
 { value: 'confused', label: 'Verwirrt 🤔' },
 { value: 'annoyed', label: 'Genervt 🫠' },
 { value: 'disappointed', label: 'Enttäuscht 🥺' },
 { value: 'frustrated', label: 'Frustriert 😓' },
 { value: 'sad', label: 'Traurig 😥' },
 { value: 'dead', label: 'Dead inside 🤡' }
];


// Function options
const FUNCTION_OPTIONS = [
 { value: 'courses', label: 'Meine Kurse' },
 { value: 'files', label: 'Dateien' },
 { value: 'questionsets', label: 'Fragesets' },
 { value: 'summaries', label: 'Zusammenfassungen' },
 { value: 'auth', label: 'Login/Registrierung' },
 { value: 'eastereggs', label: 'EasterEggs' },
 { value: 'other', label: 'Anderes' }
];


// Helper function to determine if feedback belongs to current user
const isOwnFeedback = (feedbackItem) => {
 if (!feedbackItem) return false;
  
 console.log(`Checking ownership for feedback item #${feedbackItem.id}`);
 
 // The most reliable indicator is the can_edit flag from the backend
 // The backend sets this to true only for the feedback owner
 if (feedbackItem.can_edit === true) {
   console.log(`- Item #${feedbackItem.id}: User can edit this feedback, assuming ownership`);
   return true;
 }
 
 // Fallback checks - only used if can_edit is not available for some reason
 
 // Get current user ID from localStorage
 const currentUserIdStr = localStorage.getItem('userId');
 if (currentUserIdStr) {
   const currentUserId = parseInt(currentUserIdStr);
   console.log(`- Item #${feedbackItem.id}: Current user ID: ${currentUserId}`);
   
   if (!isNaN(currentUserId) && feedbackItem.user === currentUserId) {
     console.log(`- Item #${feedbackItem.id}: Match found on user field`);
     return true;
   }
   
   // Check the pre-computed isOwn flag as well
   if (feedbackItem.isOwn === true) {
     console.log(`- Item #${feedbackItem.id}: Using pre-computed isOwn flag`);
     return true;
   }
 } else {
   console.log(`- Item #${feedbackItem.id}: No user ID in localStorage`);
 }
 
 // Not the user's feedback
 console.log(`- Item #${feedbackItem.id}: Not user's feedback`);
 return false;
};


const FeedbackTable = ({ feedback, onVote, onCreateFeedback, onDeleteFeedback }) => {
 const theme = useTheme();
 const [orderBy, setOrderBy] = useState('timestamp');
 const [order, setOrder] = useState('desc');
 const [feedbackType, setFeedbackType] = useState('');
 const [description, setDescription] = useState('');
 const [priority, setPriority] = useState('medium');
 const [emotion, setEmotion] = useState('');
 const [functionValue, setFunctionValue] = useState('');
 const [isPrivate, setIsPrivate] = useState(false);
 const [isSubmitting, setIsSubmitting] = useState(false);
 const [expandedRows, setExpandedRows] = useState({});
 // Add state for delete confirmation dialog
 const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
 const [feedbackToDelete, setFeedbackToDelete] = useState(null);
 // Add state for edit dialog
 const [editDialogOpen, setEditDialogOpen] = useState(false);
 const [feedbackToEdit, setFeedbackToEdit] = useState(null);
  
 // Log the current user ID for debugging
 useEffect(() => {
   const currentUserId = localStorage.getItem('userId');
   console.log('FeedbackTable - Current user ID:', currentUserId);
   console.log('FeedbackTable - Initial feedback data:', feedback);
 }, [feedback]);

  // Toggle description expansion for a specific item
 const toggleExpandDescription = (itemId) => {
   setExpandedRows(prev => ({
     ...prev,
     [itemId]: !prev[itemId]
   }));
 };

  // Apply sorting and filter private feedback
 const sortedFeedback = React.useMemo(() => {
   console.log("===== DIRECT FILTERING - PRIVATE FEEDBACK =====");
   
   // Get current user ID 
   const currentUserIdStr = localStorage.getItem('userId');
   const currentUserId = currentUserIdStr ? parseInt(currentUserIdStr) : null;
   // Step 1: Filter private feedback (only show private feedback if it's the user's own)
   const filteredFeedback = feedback.filter(item => {
     // Normalize visibility value to handle different formats (string, boolean, undefined)
     let normalizedVisibility = 'public'; // Default to public
     if (item.visibility === 'private' || item.visibility === true) {
       normalizedVisibility = 'private';
     }
     
     // Only keep public feedback or private feedback that belongs to current user
     if (normalizedVisibility === 'private') {
       // Direct user ID comparison - much simpler than using isOwnFeedback
       const itemUserId = parseInt(item.user);
       const isOwner = currentUserId && !isNaN(itemUserId) && itemUserId === currentUserId;
       console.log(`Filtering: Item #${item.id} - visibility: ${normalizedVisibility}, direct owner check: ${isOwner}`);
       return isOwner;
     }
     
     // Keep all public feedback
     return true;
   });
   
   console.log(`Filtered feedback: ${filteredFeedback.length} items (from ${feedback.length} total)`);
   
   // Step 2: Sort the filtered feedback
   return [...filteredFeedback].sort((a, b) => {
     let valueA = a[orderBy];
     let valueB = b[orderBy];
    
     // Handle special cases
     if (orderBy === 'timestamp') {
       valueA = new Date(valueA);
       valueB = new Date(valueB);
     } else if (orderBy === 'vote_count') {
       valueA = Number(valueA || 0);
       valueB = Number(valueB || 0);
     }
    
     if (valueA < valueB) {
       return order === 'asc' ? -1 : 1;
     }
     if (valueA > valueB) {
       return order === 'asc' ? 1 : -1;
     }
     return 0;
   });
 }, [feedback, orderBy, order]);
  
  // Handle sorting change
 const handleRequestSort = (property) => {
   const isAsc = orderBy === property && order === 'asc';
   setOrder(isAsc ? 'desc' : 'asc');
   setOrderBy(property);
 };
  
  // Handle vote click
  const handleVoteClick = async (feedbackId) => {
    console.log(`===== VOTING ON FEEDBACK #${feedbackId} =====`);
    
   // Get the feedback item from the current data
   const feedbackItem = feedback.find(item => item.id === feedbackId);
  
    if (!feedbackItem) {
      console.error("Feedback item not found", feedbackId);
      return;
    }
    
    // Check if access token exists (must be logged in to vote)
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      console.error("Cannot vote: No access token found");
      alert("Du musst eingeloggt sein, um abstimmen zu können.");
      return;
    }
    
    // Simple ownership check - we can use the can_edit flag which is set by the server
    // This is much more reliable than trying to compare usernames or IDs
    const isMyFeedback = isOwnFeedback(feedbackItem);
    
    // Don't allow voting on own feedback
    if (isMyFeedback) {
     console.error("Cannot vote on own feedback");
      alert("Du kannst nicht für dein eigenes Feedback stimmen.");
     return;
   }
  
    // Check if the item is private, if so don't allow voting
    if (feedbackItem.visibility === 'private') {
      console.error("Cannot vote on private feedback");
      return;
    }
    
    try {
      // Check if the user has already voted
      const hasVoted = feedbackItem.voted_by_me;
      
      // Always use the same endpoint, but with different methods
      const voteUrl = ENDPOINTS.FEEDBACK_VOTE(feedbackId);
      
      // Add a cache-busting parameter to prevent caching issues with DELETE
      const requestUrl = hasVoted 
        ? `${voteUrl}?_t=${Date.now()}` 
        : voteUrl;
      
      console.log(`${hasVoted ? 'Removing vote' : 'Adding vote'} for feedback #${feedbackId}`);
      console.log(`Using endpoint: ${requestUrl} with method: ${hasVoted ? 'DELETE' : 'POST'}`);
      
      // Make the request with the appropriate method
      const response = await fetch(requestUrl, {
        method: hasVoted ? 'DELETE' : 'POST',
        headers: hasVoted 
          ? {
              'Authorization': `Bearer ${accessToken}`
            }
          : {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
      });
      
      console.log(`Vote response status: ${response.status}`);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`${hasVoted ? 'Vote removal' : 'Vote addition'} failed: ${errorText}`);
        
        // Log more details about the error
        console.error(`Request details: ${requestUrl}, Method: ${hasVoted ? 'DELETE' : 'POST'}`);
        
        // Check for the specific error message from the server
        const errorIsNotVoted = hasVoted && 
          (errorText.includes("You have not voted on this feedback") || 
           errorText.includes("not voted on this feedback"));
        
        // If we get a 404 when removing a vote, or a 400 with a specific error message
        // the vote might already be removed in the backend
        if ((response.status === 404 || errorIsNotVoted) && hasVoted) {
          console.log(`Received ${response.status} when removing vote - vote might already be removed in backend`);
          console.log('Updating local state to reflect vote removal despite API error');
          
          // Update the UI as if the vote was successfully removed
          if (typeof onVote === 'function') {
            onVote(feedbackId, hasVoted);
          } else if (typeof onCreateFeedback === 'function') {
            onCreateFeedback({ __action: 'refresh_only' });
          } else {
            // Update local state without alert, just reload
            window.location.reload();
          }
          return;
        }
        
        alert(`Fehler beim ${hasVoted ? 'Entfernen der Stimme' : 'Abstimmen'}: ${response.statusText}`);
        return;
      }
      
      const data = await response.json();
      console.log('Vote response:', data);
      
      // If we have a dedicated handler from the parent, use it to refresh the list
      if (typeof onVote === 'function') {
        console.log('Using onVote handler to refresh data');
   onVote(feedbackId, hasVoted);
      } else if (typeof onCreateFeedback === 'function') {
        console.log('Using onCreateFeedback to refresh data');
        // Signal a refresh request
        onCreateFeedback({ __action: 'refresh_only' });
      } else {
        // Handle the vote locally by updating our state
        // Toggle the voted_by_me flag and increment/decrement vote count
        console.log('Updating vote count locally');
        const newVotedByMe = !hasVoted;
        const newVoteCount = newVotedByMe 
          ? (feedbackItem.vote_count + 1) 
          : Math.max(0, feedbackItem.vote_count - 1);
        
        // Find the feedback item and update it
        const updatedFeedback = feedback.map(item => 
          item.id === feedbackId 
            ? { 
                ...item, 
                voted_by_me: newVotedByMe, 
                vote_count: newVoteCount 
              } 
            : item
        );
        
        // If we can't update the state through props, just reload the page
        alert(`${hasVoted ? 'Stimme wurde entfernt' : 'Deine Stimme wurde gespeichert'}. Die Seite wird aktualisiert.`);
        window.location.reload();
      }
      
      console.log(`===== VOTING ${hasVoted ? 'REMOVAL' : 'ADDITION'} COMPLETE =====`);
    } catch (error) {
      console.error('Error handling vote:', error);
      alert(`Fehler beim Verarbeiten der Stimme: ${error.message}`);
    }
  };
  
  // Handle feedback form submission
 const handleSubmitFeedback = async (e) => {
   e.preventDefault();
  
   if (!feedbackType || !description.trim()) {
     alert('Bitte fülle die Pflichtfelder aus (Typ und Beschreibung)');
     return;
   }
  
   setIsSubmitting(true);
  
   try {
     const feedbackData = {
       feedback_type: feedbackType,
       description,
       priority,
       emotion,
       function: functionValue,
       visibility: isPrivate ? 'private' : 'public'
     };
    
     // Log the feedback data being submitted
     console.log("Submitting feedback with data:", feedbackData);
     console.log("Private feedback setting:", isPrivate);
    
     await onCreateFeedback(feedbackData);
    
     // Reset form
     setFeedbackType('');
     setDescription('');
     setPriority('medium');
     setEmotion('');
     setFunctionValue('');
     setIsPrivate(false);
     
     console.log("Feedback submission successful, form reset");
   } catch (error) {
     console.error('Error submitting feedback:', error);
     alert('Fehler beim Senden des Feedbacks. Bitte versuche es später erneut.');
   } finally {
     setIsSubmitting(false);
   }
 };
  
  // Updated handle for initiating delete process
 const handleDeleteClick = (feedbackId) => {
   setFeedbackToDelete(feedbackId);
   setDeleteDialogOpen(true);
 };
 
 // Handle dialog close without deleting
 const handleDialogClose = () => {
   setDeleteDialogOpen(false);
   setFeedbackToDelete(null);
 };
  
 // Updated delete feedback function
 const handleDeleteFeedback = async () => {
   // We already know which feedback to delete from state
   const feedbackId = feedbackToDelete;
   
   // Close the dialog first
   setDeleteDialogOpen(false);
   
   // If we have a dedicated handler from the parent, use it
   if (typeof onDeleteFeedback === 'function') {
     onDeleteFeedback(feedbackId);
     setFeedbackToDelete(null);
     return;
   }
   
   // Fallback implementation if parent doesn't provide a handler
   try {
     const token = localStorage.getItem('accessToken');
     const url = ENDPOINTS.FEEDBACK_DELETE(feedbackId);
     
     console.log(`Deleting feedback with ID: ${feedbackId}`);
     
     const response = await fetch(url, {
       method: 'DELETE',
       headers: {
         'Authorization': `Bearer ${token}`,
         'Content-Type': 'application/json'
       }
     });
     
     if (!response.ok) {
       throw new Error(`Failed to delete feedback: ${response.status}`);
     }
     
     alert('Feedback wurde erfolgreich gelöscht. Die Seite wird aktualisiert.');
     window.location.reload();
     
   } catch (error) {
     console.error('Error deleting feedback:', error);
     alert('Fehler beim Löschen des Feedbacks. Bitte versuche es später erneut.');
   } finally {
     setFeedbackToDelete(null);
   }
 };

 // Handle edit button click
 const handleEditClick = (feedbackItem) => {
   setFeedbackToEdit(feedbackItem);
   setEditDialogOpen(true);
 };
 
 // Handle feedback update (refresh the list after editing)
 const handleFeedbackUpdated = () => {
   // If a dedicated callback exists, use it
   if (typeof onDeleteFeedback === 'function') {
     // Use the delete handler to refresh the list since it's already set up for refreshing
     onDeleteFeedback(null);
   } else if (typeof onCreateFeedback === 'function') {
     // Signal a refresh request without attempting to create any feedback
     // We're using an __action property to indicate this is a control action, not actual data
     onCreateFeedback({ __action: 'refresh_only' });
   } else {
     // Fallback: reload the page to see the updated feedback
     window.location.reload();
   }
 };
  
  return (
   <Box 
     sx={{
     width: '100%',
     display: 'flex',
     flexDirection: 'column',
     height: '100%',
     }}
   >
     {/* FEEDBACK SUBMISSION FORM - Fixed at the top */}
     <Card sx={{ 
       mb: 3, 
       borderRadius: '12px', 
       boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
       border: `1px solid ${theme.palette.divider}`,
       background: theme.palette.background.paper,
       flexShrink: 0
     }}>
       <CardContent sx={{ pb: 2 }}>
         <Typography variant="h6" gutterBottom sx={{ 
           fontSize: '1.1rem', 
           fontWeight: 'bold', 
           color: theme.palette.primary.main,
       display: 'flex',
           alignItems: 'center',
           gap: 1
         }}>
           
           Neues Feedback erstellen
         </Typography>
         
         <Box component="form" onSubmit={handleSubmitFeedback} sx={{ mt: 2 }}>
           <Stack spacing={2.5}>
             <TextField
               label="Beschreibung"
               multiline
               rows={3}
               value={description}
               onChange={(e) => setDescription(e.target.value)}
               required
               fullWidth
               placeholder="Beschreibe dein Feedback, deine Idee oder den gefundenen Fehler, teile uns mit, was immer du uns sagen möchtest."
             />
            
             <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
               <FormControl fullWidth required>
                 <Typography 
                   variant="body2" 
                   sx={{ 
                     mb: 0.75, 
                     fontWeight: 500,
                     display: 'flex',
                     alignItems: 'center',
                     '&::after': {
                       content: '"*"',
                       color: 'error.main',
                       ml: 0.5
                     }
                   }}
                 >
                   Wähle die Art deines Feedbacks
                 </Typography>
                 <Select
                   value={feedbackType}
                   displayEmpty
                   onChange={(e) => setFeedbackType(e.target.value)}
                   placeholder="Wähle einen Typ"
                 >
                   <MenuItem disabled value="">
                     <em>Bitte wählen</em>
                   </MenuItem>
                   {FEEDBACK_TYPES.map((type) => (
                     <MenuItem key={`${type.value}-${type.label}`} value={type.value}>
                       {type.label}
                     </MenuItem>
                   ))}
                 </Select>
               </FormControl>
              
               <FormControl fullWidth>
                 <Typography 
                   variant="body2" 
                   sx={{ 
                     mb: 0.75, 
                     fontWeight: 500,
                     color: theme.palette.text.secondary
                   }}
                 >
                   Wie wichtig ist dein Anliegen?
                 </Typography>
                 <Select
                   value={priority}
                   displayEmpty
                   onChange={(e) => setPriority(e.target.value)}
                 >
                 <MenuItem value="">
                   <em>Optional</em>
                 </MenuItem>
                   {PRIORITY_LEVELS.map((level) => (
                     <MenuItem key={level.value} value={level.value}>
                       {level.label}
                     </MenuItem>
                   ))}
                 </Select>
               </FormControl>
              
               <FormControl fullWidth>
                 <Typography 
                   variant="body2" 
                   sx={{ 
                     mb: 0.75, 
                     fontWeight: 500,
                     color: theme.palette.text.secondary
                   }}
                 >
                   Wie fühlst du dich bei diesem Thema?
                 </Typography>
                 <Select
                   value={emotion}
                   displayEmpty
                   onChange={(e) => setEmotion(e.target.value)}
                 >
                   <MenuItem value="">
                     <em>Optional</em>
                   </MenuItem>
                   {EMOTION_OPTIONS.map((option) => (
                     <MenuItem key={option.value} value={option.value}>
                       {option.label}
                     </MenuItem>
                   ))}
                 </Select>
               </FormControl>
              
               <FormControl fullWidth>
                 <Typography 
                   variant="body2" 
                   sx={{ 
                     mb: 0.75, 
                     fontWeight: 500, 
                     color: theme.palette.text.secondary
                   }}
                 >
                   Zu welchem Bereich gehört dein Feedback?
                 </Typography>
                 <Select
                   value={functionValue}
                   displayEmpty
                   onChange={(e) => setFunctionValue(e.target.value)}
                 >
                   <MenuItem value="">
                     <em>Optional</em>
                   </MenuItem>
                   {FUNCTION_OPTIONS.map((option) => (
                     <MenuItem key={option.value} value={option.value}>
                       {option.label}
                     </MenuItem>
                   ))}
                 </Select>
               </FormControl>
             </Stack>
             <Typography variant="body1" sx={{ mb: 3, ml: 3}}>
               Dein Feedback wird anonym in der Feedback-Tabelle angezeigt, damit auch andere Nutzer es lesen können. Wenn du das nicht möchtest, kannst du dein Feedback auch privat an uns senden.
             </Typography> 
             <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
               <FormControlLabel
                 control={
                   <Switch
                     checked={isPrivate}
                     onChange={(e) => setIsPrivate(e.target.checked)}
                   />
                 }
                 label="Privat senden(nur für Admins sichtbar)"
               />
              
               <Button
                 variant="contained"
                 color="primary"
                 type="submit"
                 disabled={isSubmitting || !feedbackType || !description.trim()}
                 sx={{ px: 3, py: 1 }}
               >
                 Feedback senden
               </Button>
             </Box>
           </Stack>
         </Box>
       </CardContent>
     </Card>

     {/* Divider with text label for Visual Separation */}
     <Box 
       sx={{ 
         display: 'flex', 
         alignItems: 'center', 
         mb: 2, 
         mt: 1,
         flexShrink: 0
       }}
     >
       <Divider sx={{ flexGrow: 1 }} />
       <Typography 
         variant="body2" 
         sx={{ 
           mx: 2, 
           color: theme.palette.text.secondary,
           fontWeight: 'medium',
           display: 'flex',
           alignItems: 'center',
           gap: 0.5
         }}
       >
         BISHERIGES FEEDBACK
       </Typography>
       <Divider sx={{ flexGrow: 1 }} />
     </Box>

     <Typography 
       variant="body1" 
       sx={{ 
         mb: 2,
         flexShrink: 0 
       }}
     >
       Hier siehst du alle Feedback-Einträge. Du kannst für Vorschläge von anderen Usern stimmen und deine eigenen Vorschläge bearbeiten. 
     </Typography> 

     {/* Feedback Table with improved scrolling */}
     <Box 
       sx={{
         flex: 1,
       minHeight: 0,
       display: 'flex',
         flexDirection: 'column',
         border: `1px solid ${theme.palette.divider}`,
         borderRadius: '8px',
         overflow: 'hidden',
         mb: 2
       }}
     >
       <TableContainer
         sx={{
           flex: 1,
           overflow: 'auto',
           "&::-webkit-scrollbar": {
             width: '8px',
             height: '8px',
           },
           "&::-webkit-scrollbar-thumb": {
             backgroundColor: 'rgba(0,0,0,0.1)',
             borderRadius: '4px',
           },
           "&::-webkit-scrollbar-track": {
             backgroundColor: 'rgba(0,0,0,0.05)',
           },
           // Add significant bottom padding to ensure visibility of all content
           pb: 6
         }}
       >
         <Table
           stickyHeader
           sx={{ minWidth: 650 }}
           aria-label="feedback table"
           size="small"
         >
           <TableHead>
             <TableRow>
               <TableCell
                 sx={{
                   fontWeight: 'bold',
                   width: '12%',
                   fontSize: '1rem',
                   whiteSpace: 'nowrap',
                   backgroundColor: theme.palette.background.paper,
                   borderBottom: `2px solid ${theme.palette.divider}`
                 }}
               >
                 Besitzer
               </TableCell>
               <TableCell
                 sx={{
                   fontWeight: 'bold',
                   width: '53%',
                   fontSize: '1rem',
                   backgroundColor: theme.palette.background.paper,
                   borderBottom: `2px solid ${theme.palette.divider}`
                 }}
               >
                 Beschreibung
               </TableCell>
              
               <TableCell
                 sx={{
                   fontWeight: 'bold',
                   cursor: 'pointer',
                   width: '10%',
                   fontSize: '1rem',
                   whiteSpace: 'nowrap',
                   backgroundColor: theme.palette.background.paper,
                   borderBottom: `2px solid ${theme.palette.divider}`
                 }}
                 onClick={() => handleRequestSort('feedback_type')}
               >
                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
                   Typ
                   {orderBy === 'feedback_type' && (
                     order === 'asc' ? <ArrowUpwardIcon fontSize="small" sx={{ fontSize: '1rem' }} /> : <ArrowDownwardIcon fontSize="small" sx={{ fontSize: '1rem' }} />
                   )}
                 </Box>
               </TableCell>
              
               <TableCell
                 sx={{
                   fontWeight: 'bold',
                   cursor: 'pointer',
                   width: '10%',
                   fontSize: '1rem',
                   whiteSpace: 'nowrap',
                   backgroundColor: theme.palette.background.paper,
                   borderBottom: `2px solid ${theme.palette.divider}`
                 }}
                 onClick={() => handleRequestSort('priority')}
               >
                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
                   Priorität
                   {orderBy === 'priority' && (
                     order === 'asc' ? <ArrowUpwardIcon fontSize="small" sx={{ fontSize: '1rem' }} /> : <ArrowDownwardIcon fontSize="small" sx={{ fontSize: '1rem' }} />
                   )}
                 </Box>
               </TableCell>
              
               <TableCell
                 sx={{
                   fontWeight: 'bold',
                   cursor: 'pointer',
                   width: '10%',
                   fontSize: '1rem',
                   whiteSpace: 'nowrap',
                   backgroundColor: theme.palette.background.paper,
                   borderBottom: `2px solid ${theme.palette.divider}`
                 }}
                 onClick={() => handleRequestSort('timestamp')}
               >
                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
                   Datum
                   {orderBy === 'timestamp' && (
                     order === 'asc' ? <ArrowUpwardIcon fontSize="small" sx={{ fontSize: '1rem' }} /> : <ArrowDownwardIcon fontSize="small" sx={{ fontSize: '1rem' }} />
                   )}
                 </Box>
               </TableCell>
              
               <TableCell
                 align="center"
                 sx={{
                   fontWeight: 'bold',
                   cursor: 'pointer',
                   width: '5%',
                   fontSize: '1rem',
                   whiteSpace: 'nowrap',
                   backgroundColor: theme.palette.background.paper,
                   borderBottom: `2px solid ${theme.palette.divider}`
                 }}
                 onClick={() => handleRequestSort('vote_count')}
               >
                 <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                   Votes
                   {orderBy === 'vote_count' && (
                     order === 'asc' ? <ArrowUpwardIcon fontSize="small" sx={{ fontSize: '1rem' }} /> : <ArrowDownwardIcon fontSize="small" sx={{ fontSize: '1rem' }} />
                   )}
                 </Box>
               </TableCell>
              
               <TableCell
                 align="center"
                 sx={{
                   fontWeight: 'bold',
                   width: '5%',
                   fontSize: '1rem',
                   whiteSpace: 'nowrap',
                   backgroundColor: theme.palette.background.paper,
                   borderBottom: `2px solid ${theme.palette.divider}`
                 }}
               >
                 <ThumbUpIcon style={{ fontSize: 16 }} />
               </TableCell>
             </TableRow>
           </TableHead>
          
           <TableBody>
             {/* Map through feedback items */}
             {sortedFeedback.map((item) => {
               // Use the dedicated helper function to determine ownership
               const isMyFeedback = isOwnFeedback(item);
               const isExpanded = expandedRows[item.id] || false;
               const hasLongDescription = item.description && item.description.length > 200;
              
               return (
                 <React.Fragment key={item.id}>
                 <TableRow
                   hover
                   sx={{
                       '& td': { 
                         py: 1,
                         px: 1.5,
                         borderBottom: hasLongDescription && isExpanded ? 'none' : '1px solid rgba(224, 224, 224, 0.3)' 
                       },
                       backgroundColor: isMyFeedback ? 'rgba(0, 0, 0, 0.02)' : 'transparent',
                       // Add styles for action buttons on hover
                       '& .action-buttons': {
                         opacity: 0,
                         transition: 'opacity 0.2s ease-in-out',
                       },
                       '&:hover .action-buttons': {
                         opacity: 1,
                       }
                     }}
                   >
                   <TableCell>
                       {isMyFeedback ? (
                     <Chip
                           label="Mein Feedback"
                           size="small"
                           color="secondary"
                           variant="filled"
                           sx={{ 
                             height: 22, 
                             fontSize: '0.7rem', 
                             fontWeight: 'bold',
                             mr: 1.5,
                             my: 0.3,
                             flexShrink: 0,
                             backgroundColor: theme.palette.secondary.main,
                             color: '#fff',
                             border: `1px solid ${theme.palette.secondary.main}`,
                             boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                           }}
                         />
                       ) : (
                         <Chip
                           label="Anderes Feedback"
                       size="small"
                       variant="outlined"
                           sx={{ 
                             height: 22, 
                             fontSize: '0.7rem', 
                             fontWeight: 'medium',
                             mr: 1.5,
                             my: 0.3,
                             flexShrink: 0,
                             color: theme.palette.text.secondary,
                             borderColor: theme.palette.divider,
                           }}
                         />
                       )}
                   </TableCell>
                   <TableCell>
                       <Box 
                         sx={{ 
                           display: 'flex', 
                           alignItems: 'flex-start',
                           gap: 0.5,
                           width: '100%'
                         }}
                       >
                     <Typography
                       variant="body2"
                       sx={{
                         wordBreak: 'break-word',
                             lineHeight: 1.4,
                             cursor: hasLongDescription ? 'pointer' : 'default',
                             color: hasLongDescription ? theme.palette.text.primary : 'inherit',
                             width: '100%',
                             pr: 1
                           }}
                           onClick={() => hasLongDescription && toggleExpandDescription(item.id)}
                         >
                           {hasLongDescription && !isExpanded 
                             ? `${item.description.substring(0, 200)}...` 
                             : !isExpanded ? item.description : null}
                           {hasLongDescription && !isExpanded && (
                             <Typography 
                               component="span" 
                               variant="caption" 
                               sx={{ 
                                 color: theme.palette.primary.main, 
                                 fontWeight: 'medium',
                                 ml: 0.5,
                                 fontSize: '0.7rem'
                               }}
                             >
                               [Mehr anzeigen]
                     </Typography>
                           )}
                         </Typography>
                         {/* Action buttons for own feedback */}
                         {isMyFeedback && (
                           <Box
                             className="action-buttons"
                             sx={{
                               display: 'flex',
                               alignItems: 'center',
                               gap: 0.5,
                               ml: 2
                             }}
                             onClick={(e) => e.stopPropagation()}
                           >
                             <IconButton
                               aria-label="edit feedback"
                               size="small"
                               sx={{
                                 width: 20,
                                 height: 20,
                                 padding: 0,
                                 color: 'grey',
                                 '&:hover': {
                                   color: theme.palette.primary.main,
                                   backgroundColor: 'transparent'
                                 }
                               }}
                               onClick={() => handleEditClick(item)}
                             >
                               <Icon iconName="pen" style={{ fontSize: 14 }} />
                             </IconButton>
                             <IconButton
                               aria-label="delete feedback"
                               size="small"
                               sx={{
                                 width: 20,
                                 height: 20,
                                 padding: 0,
                                 color: 'grey',
                                 '&:hover': {
                                   color: theme.palette.error.main,
                                   backgroundColor: 'transparent'
                                 }
                               }}
                               onClick={() => handleDeleteClick(item.id)}
                             >
                               <Icon iconName="trash" style={{ fontSize: 14 }} />
                             </IconButton>
                           </Box>
                         )}
                         {hasLongDescription && (
                           <IconButton 
                             size="small" 
                             onClick={() => toggleExpandDescription(item.id)}
                             sx={{ 
                               p: 0, 
                               ml: 'auto',
                               flexShrink: 0,
                               color: theme.palette.text.secondary,
                               transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                               transition: 'transform 0.2s'
                             }}
                           >
                             {isExpanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                           </IconButton>
                         )}
                       </Box>
                     </TableCell>
                  
                   <TableCell>
                     <Chip
                       label={formatFeedbackType(item.feedback_type)}
                       color={getFeedbackTypeColor(item.feedback_type)}
                       size="small"
                       variant="outlined"
                     />
                   </TableCell>
                  
                   <TableCell>
                     <Chip
                       label={item.priority}
                       color={getPriorityColor(item.priority)}
                       size="small"
                       variant="outlined"
                     />
                   </TableCell>
                  
                   <TableCell>
                     {format(new Date(item.timestamp), 'dd.MM.yyyy', { locale: de })}
                   </TableCell>
                  
                   <TableCell align="center">
                     <Typography variant="body2">
                       {item.vote_count}
                     </Typography>
                   </TableCell>
                  
                   <TableCell align="center" sx={{ width: '40px', minWidth: '40px', maxWidth: '40px' }}>
                     {/* Using isMyFeedback for consistent logic */}
                     {isMyFeedback ? (
                       // My own feedback - no vote option
                       <Typography variant="body2" color="text.secondary">-</Typography>
                     ) : (
                       // Vote button with ThumbUpIcon - changes color if voted
                       <Box 
                         onClick={() => handleVoteClick(item.id)}
                         sx={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           cursor: 'pointer',
                           '&:hover': {
                             opacity: 0.8
                           }
                         }}
                       >
                         <ThumbUpIcon 
                           fontSize="small"
                           sx={{ 
                             color: item.voted_by_me 
                               ? theme.palette.primary.main
                               : theme.palette.text.secondary
                           }} 
                         />
                       </Box>
                     )}
                   </TableCell>
                 </TableRow>
                   
                   {/* Expanded description row */}
                   {hasLongDescription && (
                     <TableRow
                       sx={{
                         display: isExpanded ? 'table-row' : 'none',
                         backgroundColor: isMyFeedback ? 'rgba(0, 0, 0, 0.02)' : 'transparent',
                         // Add hover styles for expanded row buttons too
                         '& .action-buttons': {
                           opacity: 0,
                           transition: 'opacity 0.2s ease-in-out',
                         },
                         '&:hover .action-buttons': {
                           opacity: 1,
                         }
                       }}
                     >
                       <TableCell 
                         colSpan={7} 
                         sx={{ 
                           py: isExpanded ? 1 : 0,
                           px: 3,
                           pb: 2,
                           borderTop: 'none',
                           transition: 'all 0.2s ease'
                         }}
                       >
                         <Collapse in={isExpanded}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                       <Typography
                         variant="body2"
                         sx={{
                                 wordBreak: 'break-word',
                                 lineHeight: 1.6,
                                 mt: 0.5,
                                 mb: 1,
                                 color: theme.palette.text.primary,
                                 whiteSpace: 'pre-line',
                                 flexGrow: 1
                               }}
                             >
                               {item.description}
                             </Typography>
                             
                             {/* Action buttons for expanded view */}
                             {isMyFeedback && (
                               <Box
                                 className="action-buttons"
                                 sx={{
                                   display: 'flex',
                                   alignItems: 'center',
                                   gap: 0.5,
                                   ml: 2
                                 }}
                                 onClick={(e) => e.stopPropagation()}
                               >
                                 <IconButton
                                   aria-label="edit feedback"
                                   size="small"
                                   sx={{
                                     width: 20,
                                     height: 20,
                                     padding: 0,
                                     color: 'grey',
                           '&:hover': {
                                       color: theme.palette.primary.main,
                                       backgroundColor: 'transparent'
                                     }
                                   }}
                                   onClick={() => handleEditClick(item)}
                                 >
                                   <Icon iconName="pen" style={{ fontSize: 14 }} />
                                 </IconButton>
                                 <IconButton
                                   aria-label="delete feedback"
                                   size="small"
                                   sx={{
                                     width: 20,
                                     height: 20,
                                     padding: 0,
                                     color: 'grey',
                                     '&:hover': {
                                       color: theme.palette.error.main,
                                       backgroundColor: 'transparent'
                                     }
                                   }}
                                   onClick={() => handleDeleteClick(item.id)}
                                 >
                                   <Icon iconName="trash" style={{ fontSize: 14 }} />
                                 </IconButton>
                               </Box>
                             )}
                           </Box>
                           <Button
                             size="small"
                             onClick={() => toggleExpandDescription(item.id)}
                             sx={{
                               mt: 0.5,
                               color: theme.palette.text.secondary,
                               textTransform: 'none',
                               minWidth: 'auto',
                               padding: '2px 6px',
                               fontSize: '0.7rem',
                               borderRadius: '4px',
                               '&:hover': {
                                 backgroundColor: 'rgba(0,0,0,0.04)',
                                 color: theme.palette.primary.main,
                               }
                             }}
                             startIcon={
                               <ExpandLessIcon style={{ fontSize: '0.9rem', marginRight: '2px' }} />
                             }
                           >
                             Weniger anzeigen
                           </Button>
                         </Collapse>
                   </TableCell>
                 </TableRow>
                   )}
                 </React.Fragment>
               );
             })}
              
             {/* Empty state message */}
             {sortedFeedback.length === 0 ? (
               <TableRow>
                 <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                   <Typography variant="body1" color="text.secondary">
                     Keine Feedback-Einträge gefunden
                   </Typography>
                 </TableCell>
               </TableRow>
             ) : (
               /* Add a taller empty row at the end to ensure scrolling includes the last real item */
               <TableRow sx={{ height: '80px', border: 'none', '& td': { border: 'none', padding: 0 } }}>
                 <TableCell colSpan={6}></TableCell>
               </TableRow>
             )}
           </TableBody>
         </Table>
       </TableContainer>
     </Box>
    
     {/* Confirmation Dialog */}
     <Dialog
       open={deleteDialogOpen}
       onClose={handleDialogClose}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description"
       PaperProps={{
         sx: {
           borderRadius: '20px' 
         }
       }}
     >
       <DialogTitle id="alert-dialog-title">Feedback löschen</DialogTitle>
       <DialogContent>
         <DialogContentText id="alert-dialog-description">
           Möchtest du dieses Feedback wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.
         </DialogContentText>
       </DialogContent>
       <DialogActions sx={{ justifyContent: 'space-between', padding: '0 24px 16px 24px' }}>
         <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
           <Button 
             onClick={handleDeleteFeedback} 
             variant="outlined"
             sx={{
               borderWidth: 2,
               borderStyle: 'dashed',
               borderColor: 'rgba(0, 0, 0, 0.23)',
               color: 'text.primary',
               '&:hover': {
                 borderColor: 'rgba(0, 0, 0, 0.87)',
                 backgroundColor: 'transparent',
               },
             }}
           >
             Löschen
           </Button>
         </Box>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
             <Button
             onClick={handleDialogClose}
             variant="outlined"
             sx={{
               borderWidth: 2,
               borderStyle: 'solid',
             }}
           >
             Abbrechen
             </Button>
           </Box>
       </DialogActions>
     </Dialog>

     {/* Edit Feedback Dialog */}
     <EditFeedbackDialog
       open={editDialogOpen}
       onClose={() => setEditDialogOpen(false)}
       feedback={feedbackToEdit}
       onFeedbackUpdated={handleFeedbackUpdated}
     />
   </Box>
 );
};


FeedbackTable.propTypes = {
 feedback: PropTypes.array.isRequired,
 onVote: PropTypes.func.isRequired,
 onCreateFeedback: PropTypes.func.isRequired,
 onDeleteFeedback: PropTypes.func
};


export default FeedbackTable;

