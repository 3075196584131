import React, { createContext, useContext, useState } from 'react';

// Create a context for the FloatingFeedbackButton
const FeedbackButtonContext = createContext();

// Custom hook to use the feedback button context
export const useFeedbackButton = () => {
  return useContext(FeedbackButtonContext);
};

// Provider component to wrap around components that need access to the feedback button state
export const FeedbackButtonProvider = ({ children }) => {
  const [hideGlobalButton, setHideGlobalButton] = useState(false);

  // Function to hide the global feedback button (when dialogs are open)
  const hideButton = () => {
    setHideGlobalButton(true);
  };

  // Function to show the global feedback button (when dialogs are closed)
  const showButton = () => {
    setHideGlobalButton(false);
  };

  const value = {
    hideGlobalButton,
    hideButton,
    showButton
  };

  return (
    <FeedbackButtonContext.Provider value={value}>
      {children}
    </FeedbackButtonContext.Provider>
  );
}; 