/**
 * A simple event emitter for global events in the application
 */

const eventCallbacks = {};

export const EVENT_TYPES = {
  COURSE_CREATED: 'COURSE_CREATED',
  COURSE_UPDATED: 'COURSE_UPDATED',
  COURSE_DELETED: 'COURSE_DELETED',
};

/**
 * Subscribe to an event
 * @param {string} eventType - The event type to subscribe to
 * @param {Function} callback - The callback to execute when the event is triggered
 * @returns {Function} - Unsubscribe function
 */
export const subscribe = (eventType, callback) => {
  if (!eventCallbacks[eventType]) {
    eventCallbacks[eventType] = [];
  }
  
  eventCallbacks[eventType].push(callback);
  
  // Return unsubscribe function
  return () => {
    eventCallbacks[eventType] = eventCallbacks[eventType].filter(cb => cb !== callback);
  };
};

/**
 * Emit an event to all subscribers
 * @param {string} eventType - The event type to emit
 * @param {any} data - Data to pass to the event handlers
 */
export const emit = (eventType, data) => {
  console.log(`EVENT EMITTED: ${eventType}`, data);
  
  if (!eventCallbacks[eventType]) {
    return;
  }
  
  eventCallbacks[eventType].forEach(callback => {
    try {
      callback(data);
    } catch (err) {
      console.error(`Error in ${eventType} event handler:`, err);
    }
  });
}; 