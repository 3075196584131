import React from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import usePublicTheme from '../../hooks/usePublicTheme';

const Footer = ({ themeColor }) => {
  const navigate = useNavigate();
  const { primary, secondary, neutral } = usePublicTheme();
  
  // Get colors based on theme (yellow or purple)
  const isYellowTheme = themeColor === 'yellow';
  
  // Check if device is mobile
  const isMobile = window.innerWidth <= 768;
  
  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        padding: isMobile ? '10px 0' : '12px 0',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white
        backdropFilter: 'blur(8px)', // Adds a subtle blur effect (for modern browsers
        boxShadow: `0px -2px 10px ${isYellowTheme ? primary(100) : secondary(100)}30`,
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1000 // Ensure footer is above content
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: '8px', sm: '16px', md: '32px' },
          flexWrap: 'wrap',
          width: '100%',
          maxWidth: isMobile ? '100%' : 'auto',
          px: isMobile ? 1 : 0
        }}
      >
        <Button 
          onClick={() => navigate('/tos')}
          sx={{ 
            color: isYellowTheme ? primary(800) : secondary(800),
            textTransform: 'none',
            fontWeight: 500,
            fontSize: isMobile ? '0.75rem' : '0.875rem',
            minWidth: 'auto',
            padding: isMobile ? '4px 6px' : '4px 8px',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline'
            }
          }}
        >
          AGB
        </Button>
        
        <Button 
          onClick={() => navigate('/privacy-policy')}
          sx={{ 
            color: isYellowTheme ? primary(800) : secondary(800),
            textTransform: 'none',
            fontWeight: 500,
            fontSize: isMobile ? '0.75rem' : '0.875rem',
            minWidth: 'auto',
            padding: isMobile ? '4px 6px' : '4px 8px',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline'
            }
          }}
        >
          {isMobile ? 'Datenschutz' : 'Datenschutzerklärung'}
        </Button>
        
        <Button 
          onClick={() => navigate('/imprint')}
          sx={{ 
            color: isYellowTheme ? primary(800) : secondary(800),
            textTransform: 'none',
            fontWeight: 500,
            fontSize: isMobile ? '0.75rem' : '0.875rem',
            minWidth: 'auto',
            padding: isMobile ? '4px 6px' : '4px 8px',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline'
            }
          }}
        >
          Impressum
        </Button>
      </Box>
    </Box>
  );
};

export default Footer; 