// DeleteSummaryDialog.jsx
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box
} from '@mui/material';
import ENDPOINTS from '../../utils/apiConfig';

export default function DeleteSummaryDialog({ summaryId, open, onClose, onSummaryUpdated }) {
  const [summaryName, setSummaryName] = useState(''); // Store the summary name for display

  useEffect(() => {
    if (open) {
      // Optionally fetch summary details to show the name
      const fetchSummaryDetails = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
          const response = await fetch(ENDPOINTS.SUMMARY_GET(summaryId), {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error('Failed to fetch summary details');
          }
          const data = await response.json();
          setSummaryName(data.name);
        } catch (error) {
          console.error('Error fetching summary details:', error);
        }
      };
      fetchSummaryDetails();
    }
  }, [open, summaryId]);

  const handleDelete = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(ENDPOINTS.SUMMARY_DELETE(summaryId), {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to delete the summary');
      }
      onClose();         // Close the dialog on success
      onSummaryUpdated(); // Trigger parent refresh or update logic
    } catch (error) {
      console.error('Error deleting summary:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '20px' } }}>
      <DialogTitle>Zusammenfassung löschen</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Achtung! Wenn du die Zusammenfassung "{summaryName}" löschst, kannst du sie nicht mehr für die Erstellung von Lernmaterialien nutzen. Bist du dir sicher?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', padding: '0 24px 16px 24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            onClick={handleDelete}
            variant="outlined"
            sx={{
              borderWidth: 2,
              borderStyle: 'dashed',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              color: 'text.primary',
              '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
                backgroundColor: 'transparent',
              },
            }}
          >
            Löschen
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              borderWidth: 2,
              borderStyle: 'solid',
            }}
          >
            Abbrechen
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
