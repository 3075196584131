import React, { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Button from '@mui/material/Button';
import ConfirmResetPasswordForm from '../../features/ConfirmResetPasswordForm/ConfirmResetPasswordForm';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material';
import { authTheme } from '../../styles/authTheme';
import usePublicTheme from '../../hooks/usePublicTheme';

const ConfirmResetPasswordPage = () => {
  const navigate = useNavigate();
  const { gradients } = usePublicTheme();
  
  // Get the updateThemeColor function from the PublicLayout context
  const { updateThemeColor } = useOutletContext() || { updateThemeColor: () => {} };

  // Set the theme color when component mounts
  useEffect(() => {
    updateThemeColor('purple'); // Use purple theme
  }, [updateThemeColor]);

  const handleBack = () => {
    navigate('/');
  };

  return (
    <ThemeProvider theme={authTheme}> 
      <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
          background: gradients.secondaryLight,
          backgroundAttachment: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container component="main" maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white', fontWeight: 500 }}>
            Bitte lege ein neues Passwort fest
          </Typography>
          <Box sx={{
              width: 550, // Adjust as necessary
              background: '#FFF',
              boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.3)',
              borderRadius: '30px',
              p: 2, // padding inside the box
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <ConfirmResetPasswordForm />
          </Box>
          <Button variant="contained" sx={{ mt: 6, backgroundColor: 'white', color: authTheme.palette.primary.main }} onClick={handleBack}>
            Zurück zur Startseite
          </Button>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ConfirmResetPasswordPage;
