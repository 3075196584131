import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Paper, Menu, MenuItem, Button } from '@mui/material';
import Icon from '../Icon';
import EditCourseButton from '../Button/EditCourseButton';
import DeleteCourseButton from '../Button/DeleteCourseButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Sorting options constants
const SORT_BY_DATE_NEWEST = 'newest';
const SORT_BY_DATE_OLDEST = 'oldest';
const SORT_BY_ALPHABET = 'alphabet';

export default function CourseList({
  courses = [],
  isLoading = false,
  onCourseSelect,
  onCourseUpdated,
  title = 'Meine Kurse',
}) {
  const [sortedCourses, setSortedCourses] = useState([]);
  const [sortOption, setSortOption] = useState(SORT_BY_DATE_NEWEST);
  
  // Sorting menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleSortMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };
  
  const handleSortOptionSelect = (option) => {
    setSortOption(option);
    handleSortMenuClose();
  };

  // Sort courses whenever courses or sortOption changes
  useEffect(() => {
    if (!courses || courses.length === 0) {
      setSortedCourses([]);
      return;
    }
    
    const coursesCopy = [...courses];
    
    if (sortOption === SORT_BY_DATE_NEWEST) {
      // Sort by creation date, newest first
      coursesCopy.sort((a, b) => {
        return new Date(b.date_created) - new Date(a.date_created);
      });
    } else if (sortOption === SORT_BY_DATE_OLDEST) {
      // Sort by creation date, oldest first
      coursesCopy.sort((a, b) => {
        return new Date(a.date_created) - new Date(b.date_created);
      });
    } else if (sortOption === SORT_BY_ALPHABET) {
      // Sort alphabetically by course name
      coursesCopy.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }
    
    setSortedCourses(coursesCopy);
  }, [courses, sortOption]);

  return (
    <Paper
      elevation={4}
      className="course-list-container"
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        // If you want a responsive height, you can use { md: '400px', lg: '500px', xl: '600px' } 
        // or keep a fixed height if you prefer:
        height: '600px',
        width: '100%',
        borderRadius: '20px',
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        backgroundColor: 'background.paper',
        width: '100%',
        mb: 2
      }}>
        <Typography
          variant="h6"
          className="course-list-title"
          sx={{
            textAlign: 'left',
            pl: 1,
            backgroundColor: 'background.paper',
          }}
        >
          {title}
        </Typography>
        
        {/* Sort menu */}
        <Box>
          <Button
            id="sort-button"
            aria-controls={open ? 'sort-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleSortMenuClick}
            endIcon={<ArrowDropDownIcon sx={{ fontSize: '1rem', ml: -0.5 }} />}
            sx={{ 
              textTransform: 'none',
              fontSize: '0.85rem',
              fontWeight: 'normal',
              color: 'text.secondary',
              padding: '2px 5px 2px 8px',
              minWidth: 'auto', 
              width: 'fit-content',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              },
              '& .MuiButton-endIcon': {
                marginLeft: '3px'
              }
            }}
          >
            Sortieren
          </Button>
          <Menu
            id="sort-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleSortMenuClose}
            MenuListProps={{
              'aria-labelledby': 'sort-button',
              dense: true
            }}
            PaperProps={{
              sx: {
                borderRadius: '20px',
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                mt: 1
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem 
              onClick={() => handleSortOptionSelect(SORT_BY_ALPHABET)}
              selected={sortOption === SORT_BY_ALPHABET}
              sx={{ 
                fontSize: '0.85rem',
                py: 0.8,
                minHeight: 0
              }}
            >
              Alphabetisch
            </MenuItem>
            <MenuItem 
              onClick={() => handleSortOptionSelect(SORT_BY_DATE_NEWEST)}
              selected={sortOption === SORT_BY_DATE_NEWEST}
              sx={{ 
                fontSize: '0.85rem',
                py: 0.8,
                minHeight: 0
              }}
            >
              Neueste zuerst
            </MenuItem>
            <MenuItem 
              onClick={() => handleSortOptionSelect(SORT_BY_DATE_OLDEST)}
              selected={sortOption === SORT_BY_DATE_OLDEST}
              sx={{ 
                fontSize: '0.85rem',
                py: 0.8,
                minHeight: 0
              }}
            >
              Älteste zuerst
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Scrollable container for the courses */}
      <Box
        className="course-list-content"
        sx={{
          overflowY: 'auto',
          flexGrow: 1, // Allows the box to fill remaining space
          // Same custom scrollbar styles used in FileList:
          '&::-webkit-scrollbar': { width: '0.5em' },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            borderRadius: '4px',
          },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100%',
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : sortedCourses.length > 0 ? (
          sortedCourses.map((course) => (
            <Box
              key={course.id}
              className="course-list-item"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                p: 2,
                width: '100%',
                '&:hover': {
                  backgroundColor: 'grey.200',
                  cursor: 'pointer',
                },
              }}
              onClick={() => onCourseSelect?.(course.id)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  overflow: 'hidden',
                }}
              >
                <Icon
                  iconName="folder"
                  style={{ width: '35px', height: '35px' }}
                />
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {course.name}
                </span>
              </Box>
              {/* Edit/Delete buttons */}
              <Box
                className="course-action-buttons"
                sx={{ display: 'flex', gap: 1 }}
                onClick={(e) => e.stopPropagation()} // so we don't trigger onCourseSelect
              >
                <EditCourseButton
                  courseId={course.id}
                  onCourseUpdated={onCourseUpdated}
                />
                <DeleteCourseButton
                  courseId={course.id}
                  onCourseUpdated={onCourseUpdated}
                />
              </Box>
            </Box>
          ))
        ) : (
          <Typography
            className="course-list-empty"
            sx={{
              mt: 2,
              textAlign: 'left',
              width: '100%',
              color: 'text.secondary',
              pl: 1,
              pb: 2,
            }}
          >
            Du hast noch keine Kurse erstellt. Beginne damit,
            einen neuen Kurs zu erstellen.
          </Typography>
        )}
      </Box>
    </Paper>
  );
}
