import React, { useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useOutletContext, useNavigate } from 'react-router-dom';
import usePublicTheme from '../../hooks/usePublicTheme';
import ThinkiLogo from '../../assets/ThinkiLogo.svg';

const PrivacyPolicyPage = () => {
  const { gradients, neutral } = usePublicTheme();
  const navigate = useNavigate();
  
  // Get the updateThemeColor function from the PublicLayout context
  const { updateThemeColor } = useOutletContext() || { updateThemeColor: () => {} };

  // Set the theme color when component mounts
  useEffect(() => {
    updateThemeColor('purple');
  }, [updateThemeColor]);

  const handleLogoClick = () => {
    navigate('/');
  };

  // Check if device is mobile
  const isMobile = window.innerWidth <= 768;

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        background: gradients.secondaryLight,
        position: 'relative',
        overflow: 'auto', // Enable scrolling
        paddingTop: isMobile ? '60px' : '0', // Add padding for mobile
      }}
    >
      {/* Logo in top left corner */}
      <Box 
        sx={{ 
          position: 'absolute', 
          top: '20px', 
          left: '20px', 
          cursor: 'pointer',
          zIndex: 10,
        }}
        onClick={handleLogoClick}
      >
        <img 
          src={ThinkiLogo} 
          alt="Thinki Logo" 
          width={isMobile ? "80" : "120"} 
          style={{ maxWidth: '25vw' }}
        />
      </Box>

      <Container 
        maxWidth="md" 
        sx={{ 
          py: isMobile ? 3 : 6,
          px: isMobile ? 2 : 3,
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 600, color: neutral[950], mb: 4 }}>
          Datenschutzerklärung
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          1. Einleitung
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Diese Datenschutzerklärung erläutert, wie die Plix AI GmbH („Thinki", „wir", „uns", „unser") Ihre personenbezogenen Daten („personenbezogene Daten") in Verbindung mit der Anwendung „Thinki" („der Dienst") erhebt, nutzt, speichert und schützt. Wir halten uns dabei an die EU-Datenschutz-Grundverordnung (DSGVO), die deutschen Datenschutzgesetze (einschließlich Bundesdatenschutzgesetz und Telemediengesetz/TTDSG, soweit anwendbar) sowie andere relevante rechtliche Vorgaben.
          <br /><br />
          Wenn Sie Fragen zu dieser Erklärung haben, kontaktieren Sie uns bitte unter info@thinki.de.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          2. Verantwortlicher und Kontaktinformationen
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          <strong>Verantwortlicher</strong>
          <br />
          Plix AI GmbH
          <br />
          Kolonnenstraße 8
          <br />
          10827 Berlin, Deutschland
          <br />
          Handelsregister: HRB 267262 B, Amtsgericht Berlin-Charlottenburg
          <br /><br />
          <strong>Vertretungsberechtigte:</strong>
          <br />
          Lucas Pohling
          <br />
          Fiarra Pohling
          <br /><br />
          <strong>Kontakt</strong>
          <br />
          Haupt-E-Mail: info@thinki.de
          <br />
          Alternative E-Mail: info@plix-ai.com
          <br />
          Datenschutz-Ansprechpartner: l.pohling@thinki.de
          <br /><br />
          Für Anfragen zum Datenschutz wenden Sie sich bitte an Lucas Pohling unter info@thinki.de, der/die für interne Datenschutzbelange zuständig ist. Wir haben derzeit keinen formellen externen Datenschutzbeauftragten bestellt.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          3. Rechtsgrundlagen für die Verarbeitung
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Wir verarbeiten Ihre personenbezogenen Daten auf der Grundlage der folgenden Rechtsgrundlagen gemäß Art. 6 DSGVO:
          <br /><br />
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO): Wenn Sie in bestimmte Verarbeitungsvorgänge freiwillig einwilligen (z. B. Erhalt von Marketing-E-Mails, bestimmte Cookies).
          <br /><br />
          Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO): Wenn die Datenverarbeitung erforderlich ist, um unsere Dienstleistung zu erbringen (z. B. Erstellung von Zusammenfassungen/Lernkarten oder anderen Lerninhalten aus Ihren hochgeladenen Dateien).
          <br /><br />
          Rechtliche Verpflichtung (Art. 6 Abs. 1 lit. c DSGVO): Wenn wir Daten zum Beispiel für die Erfüllung gesetzlicher Aufbewahrungspflichten nach EU-Recht oder nationalem Recht speichern oder offenlegen müssen.
          <br /><br />
          Berechtigte Interessen (Art. 6 Abs. 1 lit. f DSGVO): Etwa zur Betrugsprävention, Sicherheit oder Verbesserung der Nutzererfahrung—soweit diese Interessen nicht Ihre Grundrechte und -freiheiten überwiegen.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          4. Personenbezogene Daten, die wir erheben
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          <strong>Kontodaten</strong>
          <br />
          E-Mail-Adresse: Erforderlich für die Kontoerstellung, den Login und wichtige Mitteilungen (z. B. Passwortzurücksetzung).
          <br /><br />
          Benutzername/Spitzname: Von Ihnen frei wählbar (wir verlangen keinen Klarnamen).
          <br /><br />
          IP-Adresse: Wird aus Sicherheitsgründen, zur Betrugsprävention und Systemwartung protokolliert.
          <br /><br />
          <strong>Vom Nutzer hochgeladene Inhalte</strong>
          <br />
          Dokumente: PDFs, Folien, Notizen oder andere Dateien, die Sie zu Studien- oder Inhaltserstellungszwecken hochladen.
          <br /><br />
          Abgeleitete Materialien: Zusammenfassungen, Lernkarten oder andere KI-generierte Ausgaben.
          <br /><br />
          <strong>Nutzungs- und technische Informationen</strong>
          <br />
          Logdaten: Datum, Uhrzeit, besuchte Seiten und genutzte Funktionen.
          <br /><br />
          Fehlerprotokolle: Technische Details (z. B. Betriebssystem, Teile des Stack-Trace) bei Abstürzen oder schwerwiegenden Fehlern.
          <br /><br />
          Wir erfassen keine besonderen Kategorien personenbezogener Daten (Art. 9 DSGVO) mit Absicht. Wenn Sie sensible Daten hochladen, sind Sie dafür verantwortlich, dass Sie dazu berechtigt sind.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          5. Wie wir Ihre Daten verwenden
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          <strong>Bereitstellung des Dienstes</strong>
          <br />
          Wir nutzen Ihre Kontodaten und hochgeladene Dokumente, um unseren Vertrag mit Ihnen zu erfüllen (z. B. zur Erstellung von Zusammenfassungen, Lernkarten und anderen Inhalten).
          <br /><br />
          <strong>Konto-Verifizierung & Kommunikation</strong>
          <br />
          Wir senden Verifizierungslinks, Passwortzurücksetzungen und wichtige Mitteilungen zum Dienst an Ihre E-Mail-Adresse.
          <br /><br />
          <strong>Marketing & Werbemitteilungen</strong>
          <br />
          Wir versenden Werbe-E-Mails nur mit Ihrer ausdrücklichen Einwilligung. Sie können Ihre Einwilligung jederzeit widerrufen (Art. 7 Abs. 3 DSGVO).
          <br /><br />
          <strong>Sicherheit & Betrugsprävention</strong>
          <br />
          Wir erheben IP-Adressen und Nutzungsprotokolle, um verdächtige Aktivitäten zu erkennen, Angriffe abzuwehren oder Missbrauch vorzubeugen.
          <br /><br />
          <strong>Analysen & Service-Verbesserungen</strong>
          <br />
          Auf Grundlage berechtigter Interessen werten wir Nutzungs- und Fehlerprotokolle aus, um unsere Funktionen zu optimieren und das Nutzererlebnis zu verbessern.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          6. Einsatz von KI-Tools
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Wir nutzen OpenAI zur Erstellung von Zusammenfassungen, Lernkarten und anderen Textausgaben aus Ihren hochgeladenen Inhalten. Dies beinhaltet:
          <br /><br />
          <strong>Datenübermittlung:</strong> Teile Ihres hochgeladenen Textes können an die Server von OpenAI übertragen werden, die sich außerhalb der EU befinden können.
          <br /><br />
          <strong>Rechtsgrundlage:</strong> In der Regel Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO). Für eine weitergehende Nutzung zur Verbesserung oder zum Training über diesen Zweck hinaus berufen wir uns auf berechtigte Interessen (Art. 6 Abs. 1 lit. f DSGVO) oder holen, falls rechtlich erforderlich, eine gesonderte Einwilligung ein.
          <br /><br />
          <strong>Sicherheitsmaßnahmen:</strong> Wir stützen uns auf Standardvertragsklauseln (Art. 46 DSGVO) oder gleichwertige Mechanismen, um eine rechtmäßige Übermittlung personenbezogener Daten in Drittländer sicherzustellen.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          7. Datenweitergabe und internationale Übermittlungen
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          <strong>DigitalOcean (Frankfurt, EU)</strong>
          <br />
          Wir hosten Server, Datenbanken und Speicher in Frankfurt (Deutschland). Mit DigitalOcean haben wir einen Auftragsverarbeitungsvertrag gemäß Art. 28 DSGVO.
          <br /><br />
          <strong>OpenAI (außerhalb der EU)</strong>
          <br />
          Daten, die von OpenAI verarbeitet werden, können auf Servern außerhalb der EU gespeichert sein. Wir nutzen Standardvertragsklauseln oder gleichwertige Maßnahmen zum Schutz Ihrer personenbezogenen Daten.
          <br /><br />
          <strong>Stripe (kostenpflichtige Funktionen)</strong>
          <br />
          Wenn wir kostenpflichtige Abonnements einführen, werden Zahlungen über Stripe abgewickelt. Stripe agiert als eigenständiger Verantwortlicher für Zahlungsinformationen und hat eigene DSGVO-konforme Bedingungen. Falls erforderlich, schließen wir zusätzlich einen Auftragsverarbeitungsvertrag nach Art. 28 DSGVO ab.
          <br /><br />
          Wir verkaufen Ihre personenbezogenen Daten nicht. Eine Offenlegung erfolgt nur, wenn dies gesetzlich vorgeschrieben ist (z. B. gegenüber Strafverfolgungsbehörden) oder zur Verteidigung unserer rechtlichen Interessen erforderlich ist.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          8. Speicherdauer und Löschung
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          <strong>Aktive Nutzung</strong>
          <br />
          Wir speichern Ihre personenbezogenen Daten, solange Ihr Konto aktiv ist, damit wir den Dienst ordnungsgemäß bereitstellen können.
          <br /><br />
          <strong>Nutzerinitiierte Löschung</strong>
          <br />
          Sie können Ihr Konto jederzeit löschen. Dies löst eine Löschung Ihrer personenbezogenen Daten, hochgeladenen Inhalte und abgeleiteten Materialien aus unseren aktiven Systemen aus.
          <br /><br />
          <strong>Backups</strong>
          <br />
          Unsere Backups werden in der Regel für ca. 30 Tage aufbewahrt, um eine Notfallwiederherstellung zu ermöglichen. Sobald Daten aus unseren aktiven Systemen entfernt wurden, werden sie auch aus den Backups nach Ablauf dieser Frist gelöscht.
          <br /><br />
          Wir bewahren Ihre Daten nur so lange auf, wie es für die Erfüllung unserer vertraglichen Pflichten oder zur Einhaltung gesetzlicher Aufbewahrungsfristen erforderlich ist.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          9. Cookies und Tracking-Technologien
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Wir setzen Cookies oder ähnliche Technologien (z. B. Local Storage, Analysetools) ein, um:
          <br /><br />
          Ihre Sitzung und Einstellungen zu speichern
          <br /><br />
          Die Leistung der Anwendung zu verbessern
          <br /><br />
          Die Nutzung zu analysieren (z. B. welche Funktionen beliebt sind)
          <br /><br />
          Technisch notwendige Cookies (Art. 25 TTDSG in Deutschland, Art. 5 Abs. 3 ePrivacy-Richtlinie in der EU) erfordern keine Einwilligung. Für alle anderen Cookies oder Tracking-Verfahren (z. B. Marketing, Personalisierung) holen wir Ihre vorherige Einwilligung über ein Cookie-Banner oder eine ähnliche Lösung ein.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          10. Ihre Rechte nach der DSGVO
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Sie haben das Recht auf:
          <br /><br />
          <strong>Auskunft (Art. 15 DSGVO):</strong> Informationen über die von uns verarbeiteten personenbezogenen Daten anzufordern.
          <br /><br />
          <strong>Berichtigung (Art. 16 DSGVO):</strong> Unrichtige oder unvollständige Daten zu korrigieren.
          <br /><br />
          <strong>Löschung (Art. 17 DSGVO):</strong> Die Löschung Ihrer Daten unter bestimmten Voraussetzungen zu verlangen.
          <br /><br />
          <strong>Einschränkung der Verarbeitung (Art. 18 DSGVO):</strong> Unter bestimmten Umständen die Einschränkung der Verarbeitung zu verlangen.
          <br /><br />
          <strong>Datenübertragbarkeit (Art. 20 DSGVO):</strong> Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
          <br /><br />
          <strong>Widerspruch (Art. 21 DSGVO):</strong> Der Verarbeitung zu widersprechen, wenn diese auf berechtigten Interessen oder Direktwerbung basiert.
          <br /><br />
          <strong>Widerruf der Einwilligung (Art. 7 Abs. 3 DSGVO):</strong> Jede Einwilligung mit Wirkung für die Zukunft zu widerrufen, ohne dass dies die Rechtmäßigkeit der vorherigen Verarbeitung berührt.
          <br /><br />
          Zur Ausübung dieser Rechte senden Sie bitte eine E-Mail an info@thinki.de. Wir antworten in der Regel innerhalb eines Monats gemäß den Vorgaben der DSGVO.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          11. Verfahren bei Datenpannen
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Sollte es zu einer Verletzung des Schutzes personenbezogener Daten kommen, die ein Risiko für Ihre Rechte oder Freiheiten darstellt, werden wir:
          <br /><br />
          <strong>Die zuständige Behörde informieren:</strong> Die Berliner Beauftragte für Datenschutz und Informationsfreiheit (oder eine andere zuständige Aufsichtsbehörde) innerhalb von 72 Stunden, nachdem wir Kenntnis erlangt haben.
          <br /><br />
          <strong>Sie benachrichtigen:</strong> Wenn die Verletzung voraussichtlich ein hohes Risiko für Ihre Rechte und Freiheiten bedeutet, werden wir Sie unverzüglich informieren.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          12. Änderungen dieser Datenschutzerklärung
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Wir können diese Datenschutzerklärung von Zeit zu Zeit anpassen, um Änderungen unserer Geschäftspraktiken oder rechtlichen Verpflichtungen widerzuspiegeln. Die jeweils aktuelle Version ist jederzeit unter thinki.de/privacy (oder in der App) abrufbar. Wir empfehlen, die Erklärung regelmäßig zu überprüfen.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          13. Verzeichnis von Verarbeitungstätigkeiten
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Wir erstellen derzeit ein Verzeichnis von Verarbeitungstätigkeiten (Verarbeitungsverzeichnis) gemäß den Anforderungen der DSGVO, in dem die Kategorien von Daten, Zwecke, Rechtsgrundlagen und etwaige Empfänger/Auftragsverarbeiter aufgeführt sind. Dieses Verzeichnis wird fortlaufend aktualisiert, sobald sich unser Dienst weiterentwickelt. Bei Fragen dazu wenden Sie sich bitte an info@thinki.de.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ color: neutral[950], mt: 4, mb: 2 }}>
          14. Kontakt und Beschwerden
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: neutral[950], lineHeight: 1.7 }}>
          Wenn Sie Fragen oder Bedenken bezüglich dieser Datenschutzerklärung oder unserer Verarbeitung Ihrer personenbezogenen Daten haben, wenden Sie sich bitte an:
          <br /><br />
          Plix AI GmbH
          <br />
          Kolonnenstraße 8
          <br />
          10827 Berlin, Deutschland
          <br />
          E-Mail: info@thinki.de
          <br /><br />
          Für Datenschutzbelange erreichen Sie unseren internen Kontakt unter info@thinki.de.
          <br /><br />
          Sollten Sie der Ansicht sein, dass wir Ihre personenbezogenen Daten nicht in Übereinstimmung mit den geltenden Datenschutzgesetzen verarbeiten, steht Ihnen das Recht zu, eine Beschwerde bei der Berliner Beauftragten für Datenschutz und Informationsfreiheit oder einer anderen zuständigen Aufsichtsbehörde in Deutschland einzureichen.
          <br /><br />
          <em>Stand: 01.04.2025</em>
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicyPage; 