import { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCourseData } from '../../utils/AuthenticationService';
import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import UploadFilesField from '../../components/UploadFilesField';
import { fetchFilesByCourse } from '../../services/FileService';
import FilePreviewDrawer from '../../components/FilePreviewDrawer';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import CreateQuizButton from '../../components/Button/CreateQuizButton';
import { fetchQuestionSetsByCourse } from '../../services/QuestionService';
import QuestionSetDetail from '../../components/QuestionSetDetail';
import { useLocation } from 'react-router-dom';
import FileList from '../../components/Lists/FileList';
import QuizList from '../../components/Lists/QuizList';
import usePollFiles from '../../hooks/usePollFiles';
import usePollQuizzes from '../../hooks/usePollQuizzes';
import { fetchSummariesByCourse } from '../../services/SummaryService';
import SummaryList from '../../components/Lists/SummaryList';
import CreateSummaryButton from '../../components/Button/CreateSummaryButton';
import SummaryDetail from '../../components/SummaryDetail';
import usePollSummaries from '../../hooks/usePollSummaries';

const CoursePage = () => {
  const [courses, setCourses] = useState([]);
  const [files, setFiles] = useState([]);
  const [questionSets, setQuestionSets] = useState([]);
  const [courseDetails, setCourseDetails] = useState(null);
  const [courseError, setCourseError] = useState(null);
  const [filesError, setFilesError] = useState(null);
  const [questionsError, setQuestionsError] = useState(null);
  const navigate = useNavigate();
  const { courseId } = useParams();
  const location = useLocation();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState(null);
  const [isViewingQuestionSet, setIsViewingQuestionSet] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [currentFileIds, setCurrentFileIds] = useState([]);
  const [currentCreationRequestId, setCurrentCreationRequestId] = useState(null);
  const [isQuizReady, setIsQuizReady] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true);
  const [lastFileUpload, setLastFileUpload] = useState(Date.now());
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const theme = useTheme();
  const [quizProgress, setQuizProgress] = useState(null);
  const [summaries, setSummaries] = useState([]);
  const [selectedSummary, setSelectedSummary] = useState(null);
  const [isViewingSummary, setIsViewingSummary] = useState(false);
  const [currentSummaryCreationRequestId, setCurrentSummaryCreationRequestId] = useState(null);
  const [summaryProgress, setSummaryProgress] = useState(0);
  const [summariesError, setSummariesError] = useState(null);

  const handleSummaryUpdated = useCallback(async (summaryId) => {
    console.log("Updating summary with ID:", summaryId);
    try {
      // Refresh the summaries list to get the latest data
      const updatedSummaries = await fetchSummariesByCourse(courseId);
      setSummaries(updatedSummaries);
    } catch (error) {
      console.error('Error updating summary:', error);
      setSummariesError('Failed to update summary');
    }
  }, [courseId]);

  useEffect(() => {
    if (!courseDetails && !courseError) {
      const timer = setTimeout(() => {
        setShowLoadingMessage(true);
      }, 500); // Adjust delay time as needed
  
      return () => clearTimeout(timer); // Clean up the timer
    } else {
      setShowLoadingMessage(false); // Immediately hide the message if the content is loaded or there is an error
    }
  }, [courseDetails, courseError]);
  

  useEffect(() => {
    // Check for tab parameter in URL and set it as active tab
    const params = new URLSearchParams(location.search);
    const urlTabValue = params.get('tab');

    // Default to tab 1 if no tab is specified
    const activeTab = urlTabValue || '1';
    setTabValue(activeTab);

    // Only restore question set view if we're on the questions tab (2)
    // AND we have a valid saved question set
    // AND we're actually in viewing mode
    const savedQuestionSet = localStorage.getItem('selectedQuestionSet');
    const savedIsViewingQuestionSet = localStorage.getItem('isViewingQuestionSet');

    if (activeTab === '2' && savedQuestionSet && savedIsViewingQuestionSet === 'true') {
      try {
        setSelectedQuestionSet(JSON.parse(savedQuestionSet));
        setIsViewingQuestionSet(true);
      } catch (e) {
        // If parsing fails, clear the localStorage
        localStorage.removeItem('selectedQuestionSet');
        localStorage.removeItem('isViewingQuestionSet');
      }
    } else {
      // For any other tab, ensure we're not viewing question set detail
      setIsViewingQuestionSet(false);
      setSelectedQuestionSet(null);
    }

    const fetchInitialData = async () => {
      setIsLoadingFiles(true);
      try {
        // Clear previous course data first
        setCourseDetails(null);
        
        const [courseData, filesData, questionSetsData, summariesData] = await Promise.all([
          fetchCourseData(courseId),
          fetchFilesByCourse(courseId),
          fetchQuestionSetsByCourse(courseId),
          fetchSummariesByCourse(courseId)
        ]);

        setCourseDetails(courseData);
        setFiles(filesData);
        setQuestionSets(questionSetsData);
        setSummaries(summariesData);
      } catch (error) {
        console.error('Failed to load initial data:', error);
        setCourseError('Failed to load course data');
      } finally {
        setIsLoadingFiles(false);
      }
    };

    fetchInitialData();
  }, [courseId, location.search]); // Both courseId and search params

  const handleSummaryClick = (summary) => {
    console.log('Summary clicked:', summary);
    setSelectedSummary(summary);
    setIsViewingSummary(true);
  };

  const handleBackToSummaries = () => {
    setIsViewingSummary(false);
    setSelectedSummary(null);
  };

   // 1) Callback to handle creation request updates
   const handleSummaryCreationRequestUpdate = useCallback((data) => {
    console.log('Polled summary creation request =>', data);
  
    setSummaries((prevSummaries) =>
      prevSummaries.map((summary) => {
        if (summary.id === data.summary_id) {
          return {
            ...summary,
            status: data.status, 
            bulletpoints_progress_percentage: data.bulletpoints_progress_percentage || 0,
          };
        }
        return summary;
      })
    );
  
    if (data.status === 'completed' || data.status === 'failed') {
      setCurrentSummaryCreationRequestId(null);
      // if completed => optional final re-fetch
      // if failed => setSummariesError('Summary creation failed');
    }
  }, []);

  // 3) Hook to poll
  usePollSummaries(
    currentSummaryCreationRequestId,
    handleSummaryCreationRequestUpdate,
    setSummariesError,
    setCurrentSummaryCreationRequestId
  );

  // 4) Callback to handle "onSummaryCreated" from the dialog
  const handleSummaryCreated = useCallback((creationResponse) => {
    // Immediately re-fetch so new summary is visible
    fetchSummariesByCourse(courseId).then(setSummaries).catch(console.error);
  
    // Then set the creationRequestId for polling
    if (creationResponse?.creation_request?.id) {
      setCurrentSummaryCreationRequestId(creationResponse.creation_request.id);
    }
  }, [courseId]);


  const handleSummariesRefresh = useCallback(async () => {
  console.log("Refreshing summaries for courseId:", courseId);
  try {
    const updatedSummaries = await fetchSummariesByCourse(courseId);
    console.log("Updated summaries:", updatedSummaries);
    setSummaries(updatedSummaries);
  } catch (error) {
    console.error('Error refreshing summaries:', error);
    // Optionally set a local error state for summariesError
  }
}, [courseId]);

  const handleQuestionSetsRefresh = useCallback(async () => {
    console.log("Refreshing question sets for courseId:", courseId);
    try {
      const updatedQuestionSets = await fetchQuestionSetsByCourse(courseId);
      console.log("Updated question sets:", updatedQuestionSets);
      setQuestionSets(updatedQuestionSets);
    } catch (error) {
      console.error('Error refreshing question sets:', error);
      setQuestionsError('Failed to refresh question sets');
    }
  }, [courseId]);
  

  const handleQuizCreated = useCallback((creationRequestId) => {
    console.log("handleQuizCreated called with creationRequestId:", creationRequestId);
    setCurrentCreationRequestId(creationRequestId);  // Start polling the creation request status
    setIsQuizReady(false);  // Assume it's not ready until confirmed
    handleQuestionSetsRefresh();  // Refresh quiz list immediately
  }, [handleQuestionSetsRefresh]);

  const updateFileInState = (updatedFile) => {
    setFiles(prevFiles => prevFiles.map(file => {
        return file.id === updatedFile.id ? updatedFile : file;
    }));
  };

  const handleCreationRequestUpdate = (creationRequestData) => {
    console.log("Creation request updated:", creationRequestData);

    const updatedQuestionSetId = creationRequestData.question_set;
    const updatedStatus = creationRequestData.status;

    // 1) Retrieve the progress from the JSON
    const progress = creationRequestData.progress_percentage;
    // 2) Store it in state for the UI
    setQuizProgress(progress);

    // Update the questionSets array so we can keep track of status
    setQuestionSets(prevQuestionSets =>
      prevQuestionSets.map(qs => {
        if (qs.id === updatedQuestionSetId) {
          return { ...qs, questions_status: updatedStatus };
        }
        return qs;
      })
    );

    // If status is completed or progress is 100, set isQuizReady
    if (updatedStatus === 'completed' || progress === 100) {
      setIsQuizReady(true);
      setCurrentCreationRequestId(null); // Stop polling
    } else if (updatedStatus === 'failed') {
      console.error('Quiz creation failed:', creationRequestData.error_details);
      setQuestionsError('Quiz creation failed');
      setIsQuizReady(false);
      setCurrentCreationRequestId(null);
    } else {
      setIsQuizReady(false);
    }
  };

  usePollFiles(currentFileIds, updateFileInState, setFilesError, setCurrentFileIds);

  usePollQuizzes(currentCreationRequestId, handleCreationRequestUpdate, setQuestionsError, setCurrentCreationRequestId);

  // This effect specifically triggers when courseId changes (when navigating between courses)
  useEffect(() => {
    // Always reset QuestionSetDetail view when switching courses
    setIsViewingQuestionSet(false);
    setSelectedQuestionSet(null);
    
    // Clear localStorage to prevent QuestionSetDetail from coming back
    localStorage.removeItem('selectedQuestionSet');
    localStorage.removeItem('isViewingQuestionSet');
    
    // Also ensure we're looking at a fresh state for the new course
    setFiles([]);
    setQuestionSets([]);
    setSummaries([]);
    setCurrentFileIds([]);
    setCurrentCreationRequestId(null);
    setCurrentSummaryCreationRequestId(null);
    setIsQuizReady(false);
    
    // Force-reset loading state
    setIsLoadingFiles(true);
  }, [courseId]); // Only re-run when courseId changes

  if (courseError) {
    return <div>Error: {courseError}</div>;
  } else if (!courseDetails) {
    // Here, you can also check showLoadingMessage before showing the loading indicator
    return showLoadingMessage ? <div>Details werden geladen...</div> : null;
  }

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setPreviewOpen(true);
  };

  const handleQuestionSetClick = (questionSet) => {
    setSelectedQuestionSet(questionSet);
    setIsViewingQuestionSet(true);
    localStorage.setItem('selectedQuestionSet', JSON.stringify(questionSet));
    localStorage.setItem('isViewingQuestionSet', 'true');
  };

  const handleBackToQuestionSets = () => {
    setIsViewingQuestionSet(false);
    localStorage.removeItem('selectedQuestionSet');
    localStorage.removeItem('isViewingQuestionSet');
  };

  const handleCourseCreated = async () => {
    const fetchedCourses = await fetchCourseData(courseId);
    setCourses(fetchedCourses);
  };

  const handleFileUploadSuccess = async (fileId) => {
    setCurrentFileIds((prevIds) => [...prevIds, fileId]);
    const updatedFiles = await fetchFilesByCourse(courseId);
    setFiles(updatedFiles);
    setLastFileUpload(Date.now()); // Update the timestamp when files are updated
  };
 
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    navigate(`/course/${courseId}?tab=${newValue}`, { replace: true }); // Use replace to avoid building up history
    
    // Clear question set view when switching away from tab 2
    if (newValue !== '2') {
      setIsViewingQuestionSet(false);
      setSelectedQuestionSet(null);
      localStorage.removeItem('selectedQuestionSet');
      localStorage.removeItem('isViewingQuestionSet');
    }
  };

  return (
    <>
      <FilePreviewDrawer open={previewOpen} onClose={() => setPreviewOpen(false)} file={selectedFile} />
      <Container maxWidth="false" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 0, borderColor: 'divider', mt: 1, ml: 2 }}>
            <TabList
              onChange={handleTabChange}
              aria-label="course tabs"
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab
                label="Dateien"
                disableRipple
                value="1"
                sx={{
                  minWidth: 150,
                  width: 'auto',
                  fontWeight: (theme) => (tabValue === '1' ? 700 : 400),
                }}
              />
              <Tab
                label="Fragesets"
                disableRipple
                value="2"
                sx={{
                  minWidth: 150,
                  width: 'auto',
                  fontWeight: (theme) => (tabValue === '2' ? 700 : 400),
                }}
              />
              <Tab
                label="Zusammenfassungen"
                disableRipple
                value="3"
                sx={{
                  minWidth: 150,
                  width: 'auto',
                  fontWeight: (theme) => (tabValue === '3' ? 700 : 400),
                }}
              />
            </TabList>
          </Box>

          {/* ---------- FIRST TAB: Dateien ---------- */}
          <TabPanel value="1">
            <Box display="flex" alignItems="center" sx={{
              mb: { xs: 0, sm: 0, md: 7, lg: 8, xl: 10 }, // Responsive margins
            }}>
              <UploadFilesField
                courseId={courseId}
                onFileUploadSuccess={handleFileUploadSuccess}
              />
              <CreateQuizButton
                variant="outlined"
                color="secondary"
                size="small"
                style={{ marginLeft: 'auto' }}
                onQuizCreated={handleQuizCreated}
                lastFileUpload={lastFileUpload}
                courseName={courseDetails.name}
                files={files}   
              />
              <CreateSummaryButton
                variant="outlined"
                color="secondary"
                size="small"
                style={{ marginLeft: 'auto' }} 
                courseId={courseId}
                courseName={courseDetails?.name || 'Kurs'}
                files={files}
                filesError={filesError}
                onSummaryCreated={handleSummaryCreated}
              />
            </Box>

            <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
              <Grid item xs={12}>
                <FileList
                  files={files}
                  filesError={filesError}
                  inProgressFileIds={currentFileIds}
                  onFileClick={handleFileClick}
                  onFileUpdated={handleFileUploadSuccess}
                  courseName={courseDetails.name}
                  isLoading={isLoadingFiles}
                />
              </Grid>
            </Grid>
          </TabPanel>

          {/* ---------- SECOND TAB: Fragesets ---------- */}
          <TabPanel value="2">
            {isViewingQuestionSet ? (
              <QuestionSetDetail
                questionSet={selectedQuestionSet}
                onBack={handleBackToQuestionSets}
              />
            ) : (
              <>
                <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                  <CreateQuizButton
                    variant="contained"
                    color="secondary"
                    size="large"
                    onQuizCreated={handleQuizCreated}
                    lastFileUpload={lastFileUpload}
                    courseName={courseDetails.name}
                    files={files}
                    filesError={filesError}
                  />
                </Box>

                <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={12}>
                    <QuizList
                      questionSets={questionSets}
                      questionsError={questionsError}
                      onQuestionSetClick={handleQuestionSetClick}
                      onQuestionSetUpdated={handleQuestionSetsRefresh}
                      isQuizReady={isQuizReady}
                      courseName={courseDetails.name}
                      quizProgress={quizProgress}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </TabPanel>

          {/* ---------- THIRD TAB: Zusammenfassungen ---------- */}
          <TabPanel value="3">
            {isViewingSummary ? (
              <SummaryDetail summary={selectedSummary} onBack={handleBackToSummaries} />
            ) : (
              <>
                <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                  <CreateSummaryButton
                    courseId={courseId}
                    courseName={courseDetails?.name || 'Kurs'}
                    files={files}
                    filesError={filesError}
                    onSummaryCreated={handleSummaryCreated}
                  />
                </Box>

                <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={12}>
                    <SummaryList
                      summaries={summaries}
                      onSummaryClick={handleSummaryClick}
                      courseName={courseDetails ? courseDetails.name : 'Kurs'}
                      onSummariesRefresh={handleSummariesRefresh}
                      onSummaryUpdated={handleSummaryUpdated}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
}

export default CoursePage;
