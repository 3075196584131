import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

const SidebarContext = createContext();
const SIDEBAR_STATE_KEY = 'sidebarIsOpen'; // Key for localStorage

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
    // Track if this is the first render (used to prevent initial animation)
    const isFirstRender = useRef(true);
    
    // Track if animations should be enabled (for subsequent interactions)
    const [animationsReady, setAnimationsReady] = useState(false);
    
    // Initialize from localStorage or default to true if not found
    const [isOpen, setIsOpen] = useState(() => {
        // Use a function to initialize state to avoid unnecessary localStorage checks
        const savedState = localStorage.getItem(SIDEBAR_STATE_KEY);
        // Convert the string 'false' to a boolean false, otherwise default to true
        return savedState === 'false' ? false : true;
    });
    
    // Add course update trigger state
    const [courseUpdateTrigger, setCourseUpdateTrigger] = useState(0);
    
    // After initial mount, set first render to false and enable animations
    useEffect(() => {
        // Short delay to ensure the initial render is complete
        const initialTimer = setTimeout(() => {
            isFirstRender.current = false;
        }, 100);
        
        // Longer delay to enable animations for subsequent interactions
        const animationTimer = setTimeout(() => {
            setAnimationsReady(true);
        }, 500);
        
        return () => {
            clearTimeout(initialTimer);
            clearTimeout(animationTimer);
        };
    }, []);
    
    // Update localStorage whenever the state changes
    useEffect(() => {
        localStorage.setItem(SIDEBAR_STATE_KEY, isOpen.toString());
    }, [isOpen]);

    const toggleDrawer = () => {
        setIsOpen(prevState => !prevState);
    };
    
    // Function to trigger course list updates
    const handleCourseCreated = () => {
        console.log('SidebarContext: handleCourseCreated called');
        setCourseUpdateTrigger(prev => {
            const newValue = prev + 1;
            console.log('SidebarContext: courseUpdateTrigger changing from', prev, 'to', newValue);
            return newValue;
        });
    };

    return (
        <SidebarContext.Provider value={{ 
            isOpen, 
            toggleDrawer, 
            isFirstRender: isFirstRender.current,
            animationsReady,
            courseUpdateTrigger,
            handleCourseCreated
        }}>
            {children}
        </SidebarContext.Provider>
    );
};
