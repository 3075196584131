import { ReactComponent as AddFolderIcon } from './Add_Folder_Icon.svg';
import { ReactComponent as CheckCircleIcon } from './Check_Circle_Icon.svg';
import { ReactComponent as CoursesIcon } from './Courses_Icon.svg';
import { ReactComponent as CrossCircleIcon } from './Cross_Circle_Icon.svg';
import { ReactComponent as CrossIcon } from './Cross_Icon.svg';
import { ReactComponent as DownloadIcon } from './Download_Icon.svg';
import { ReactComponent as FeedbackIcon } from './Feedback_Icon.svg';
import { ReactComponent as FileIcon } from './File_Icon.svg';
import { ReactComponent as FolderIcon } from './Folder_Icon.svg';
import { ReactComponent as LeftCircleIcon } from './Left_Circle_Icon.svg';
import { ReactComponent as LogoutIcon } from './Logout_Icon.svg';
import { ReactComponent as PaletteIcon } from './Palette_Icon.svg';
import { ReactComponent as PenIcon } from './Pen_Icon.svg';
import { ReactComponent as PinIcon } from './Pin_Icon.svg';
import { ReactComponent as PlusCircleIcon } from './Plus_Circle_Icon.svg';
import { ReactComponent as ProfileIcon } from './Profile_Icon.svg';
import { ReactComponent as QuizIcon } from './Quiz_Icon.svg';
import { ReactComponent as RightCircleIcon } from './Right_Circle_Icon.svg';
import { ReactComponent as ShuffleIcon } from './Shuffle_Icon.svg';
import { ReactComponent as SidebarIcon } from './Sidebar_Icon.svg';
import { ReactComponent as StatisticIcon } from './Statistic_Icon.svg';
import { ReactComponent as TrashIcon } from './Trash_Icon.svg';
import { ReactComponent as UploadIcon } from './Upload_Icon.svg';
import { ReactComponent as UserIcon } from './User_Icon.svg';
import { ReactComponent as PanicButtonIcon } from './Panic_Button.svg';
import { ReactComponent as SeeIcon } from './See_Icon.svg';
import { ReactComponent as UnseeIcon } from './Unsee_Icon.svg';
import { ReactComponent as CalenderIcon } from './Calender_Icon.svg';
import { ReactComponent as Zero_Circle_Icon } from './Zero_Circle_Icon.svg';
import { ReactComponent as Zero_Icon} from './Zero_Icon.svg';
import { ReactComponent as One_Circle_Icon } from './One_Circle_Icon.svg';
import { ReactComponent as One_Icon } from './One_Icon.svg';
import { ReactComponent as Two_Circle_Icon } from './Two_Circle_Icon.svg';
import { ReactComponent as Two_Icon } from './Two_Icon.svg';
import { ReactComponent as Three_Circle_Icon } from './Three_Circle_Icon.svg';
import { ReactComponent as Three_Icon } from './Three_Icon.svg';
import { ReactComponent as Four_Circle_Icon } from './Four_Circle_Icon.svg';
import { ReactComponent as Four_Icon } from './Four_Icon.svg';
import { ReactComponent as Five_Circle_Icon } from './Five_Circle_Icon.svg';
import { ReactComponent as Five_Icon } from './Five_Icon.svg';
import { ReactComponent as Six_Icon } from './Six_Icon.svg';
import { ReactComponent as Seven_Icon } from './Seven_Icon.svg';
import { ReactComponent as Eight_Icon } from './Eight_Icon.svg';
import { ReactComponent as Nine_Icon } from './Nine_Icon.svg';
import { ReactComponent as Question_Circle_Icon } from './Question_Circle_Icon.svg';
import { ReactComponent as Dash_Icon } from './Dash_Icon.svg';
import { ReactComponent as Summary_Icon } from './Summary_Icon.svg';



const Icons = {
  addFolder: AddFolderIcon,
  calender: CalenderIcon,
  checkCircle: CheckCircleIcon,
  courses: CoursesIcon,
  crossCircle: CrossCircleIcon,
  cross: CrossIcon,
  download: DownloadIcon,
  feedback: FeedbackIcon,
  file: FileIcon,
  folder: FolderIcon,
  leftCircle: LeftCircleIcon,
  logout: LogoutIcon,
  palette: PaletteIcon,
  panic: PanicButtonIcon,
  pen: PenIcon,
  pin: PinIcon,
  plusCircle: PlusCircleIcon,
  profile: ProfileIcon,
  quiz: QuizIcon,
  rightCircle: RightCircleIcon,
  see: SeeIcon,
  shuffle: ShuffleIcon,
  sidebar: SidebarIcon,
  statistic: StatisticIcon,
  trash: TrashIcon,
  unsee: UnseeIcon,
  upload: UploadIcon,
  user: UserIcon,
  zeroCircle: Zero_Circle_Icon,
  zero: Zero_Icon,
  oneCircle: One_Circle_Icon,
  one: One_Icon,
  twoCircle: Two_Circle_Icon,
  two: Two_Icon,
  threeCircle: Three_Circle_Icon,
  three: Three_Icon,
  fourCircle: Four_Circle_Icon,
  four: Four_Icon,
  five: Five_Icon,
  fiveCircle: Five_Circle_Icon,
  six: Six_Icon,
  seven: Seven_Icon,
  eight: Eight_Icon,
  nine: Nine_Icon,
  questionCircle: Question_Circle_Icon,
  dash: Dash_Icon,
  summary: Summary_Icon,

};

export default Icons;
