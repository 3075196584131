import React, { useState } from 'react';
import { Fab, Zoom, useTheme, Tooltip, useMediaQuery } from '@mui/material';
import Icon from '../Icon';
import FeedbackDialog from '../../features/FeedbackDialog/FeedbackDialog';
import Snackbar from '@mui/material/Snackbar';

/**
 * FloatingFeedbackButton - A floating action button that can be positioned at the bottom right corner
 * or bottom center of the screen, and opens a feedback dialog when clicked.
 * 
 * @param {Object} props - Component props
 * @param {string} props.position - Position of the button ('right' or 'center')
 * @param {number} props.zIndex - Z-index value for the button
 */
const FloatingFeedbackButton = ({ position = 'right', zIndex = 1050 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openFeedback, setOpenFeedback] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleFeedbackClick = () => {
    setOpenFeedback(true);
  };

  const handleFeedbackClose = () => {
    setOpenFeedback(false);
  };

  const handleFeedbackSubmitted = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Get position styles based on the position prop
  const getPositionStyles = () => {
    if (position === 'center') {
      return {
        left: '48.5%',
        transform: 'translateX(-50%)',
        bottom: isMobile ? 16 : 32,
        right: 'auto'
      };
    }
    
    // Default 'right' position
    return {
      bottom: isMobile ? 16 : 32,
      right: isMobile ? 16 : 32,
      left: 'auto'
    };
  };

  return (
    <>
      <Zoom in={true} timeout={500} style={{ transitionDelay: '500ms' }}>
        <Tooltip
          title="Feedback geben"
          placement={position === 'center' ? 'top' : 'left'}
          arrow
        >
          <Fab
            color="secondary"
            aria-label="feedback"
            onClick={handleFeedbackClick}
            size={isMobile ? "medium" : "large"}
            sx={{
              position: 'fixed',
              ...getPositionStyles(),
              boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
              zIndex: zIndex,
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
                transform: position === 'center' 
                  ? 'translateX(-50%) scale(1.05) rotate(8deg)'
                  : 'scale(1.05) rotate(8deg)',
                boxShadow: '0 6px 14px rgba(0,0,0,0.25)',
              },
            }}
          >
            <Icon iconName="feedback" style={{ fontSize: isMobile ? '1.2rem' : '1.5rem', color: 'white' }} />
          </Fab>
        </Tooltip>
      </Zoom>

      {/* Feedback Dialog */}
      <FeedbackDialog
        open={openFeedback}
        onClose={handleFeedbackClose}
        onFeedbackSubmitted={handleFeedbackSubmitted}
      />

      {/* Snackbar for feedback submission confirmation */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default FloatingFeedbackButton; 