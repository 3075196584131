import React, { useState } from 'react';
import { Box } from '@mui/material';
import Footer from '../components/Footer/Footer';
import { Outlet } from 'react-router-dom';
import PublicThemeProvider from '../providers/PublicThemeProvider';
import './PublicLayout.css';

// PublicLayout component serves as a wrapper for all public pages
// It provides a consistent layout with a footer
const PublicLayout = () => {
  // Track the theme color to pass to Footer
  // Default to 'purple' to match default in LandingPage
  const [themeColor, setThemeColor] = useState('purple');

  // Method for child components to update the theme color
  const updateThemeColor = (color) => {
    setThemeColor(color);
  };

  return (
    <PublicThemeProvider>
      <Box
        className="public-layout-container"
        sx={{
          width: '100%',
          minHeight: '100vh',
          position: 'relative',
          overflow: 'auto',
          bgcolor: '#FBFBFB', // Set a light neutral background color as fallback
        }}
      >
        {/* Main content area */}
        <Box className="public-layout-content">
          <Outlet context={{ updateThemeColor }} />
        </Box>
        
        {/* Footer appears naturally after the content */}
        <Footer themeColor={themeColor} />
      </Box>
    </PublicThemeProvider>
  );
};

export default PublicLayout; 