// SummaryService.js

import ENDPOINTS from '../utils/apiConfig';
import { refreshToken } from '../utils/AuthenticationService';

export const fetchAllSummaries = async () => {
  let accessToken = localStorage.getItem('accessToken');
  const url = ENDPOINTS.SUMMARIES_GET;

  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    // If unauthorized, try refreshing the token and retry
    if (!response.ok) {
      if (response.status === 401) {
        accessToken = await refreshToken();
        response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
      }
      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error fetching summaries:', errorData);
        throw new Error(errorData.detail || 'Unknown API error while fetching summaries');
      }
    }

    return await response.json();
  } catch (error) {
    console.error('Error in fetchAllSummaries:', error);
    throw error;
  }
};


export const fetchSummaryById = async (summaryId) => {
  let accessToken = localStorage.getItem('accessToken');
  const url = ENDPOINTS.SUMMARY_GET(summaryId);

  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    // If unauthorized, attempt to refresh the token and retry
    if (!response.ok) {
      if (response.status === 401) {
        accessToken = await refreshToken();
        response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
      }
      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error fetching summary:', errorData);
        throw new Error(errorData.detail || 'Unknown API error while fetching the summary');
      }
    }

    return await response.json();
  } catch (error) {
    console.error('Error in fetchSummaryById:', error);
    throw error;
  }
};


export const fetchSummariesByCourse = async (courseId) => {
  let accessToken = localStorage.getItem('accessToken');
  const url = ENDPOINTS.SUMMARIES_GET_BY_COURSE(courseId);

  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    // If unauthorized, try refreshing the token and retry
    if (!response.ok) {
      if (response.status === 401) {
        accessToken = await refreshToken();
        response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
      }
      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error fetching summaries by course:', errorData);
        throw new Error(errorData.detail || 'Unknown API error while fetching summaries by course');
      }
    }

    return await response.json();
  } catch (error) {
    console.error('Error in fetchSummariesByCourse:', error);
    throw error;
  }
};
