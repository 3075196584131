import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Icon from '../../components/Icon';
import CreateSummaryDialog from '../../features/CreateSummaryDialog/CreateSummaryDialog'; // adjust path as needed

export default function CreateSummaryButton({
  variant,
  color,
  size,
  sx,
  onSummaryCreated,
  courseId,
  courseName,
  files,
  filesError,
  ...props 
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* 
        The button style can be driven by the props: variant="outlined" or "contained", 
        size="small" or "large", etc. 
      */}
      <Button
        variant={variant}
        color={color}
        size={size}
        startIcon={<Icon iconName="summary" style={{ height: 24, width: 24 }} />}
        onClick={handleClickOpen}
        sx={{
            fontSize: '0.875rem', // Smaller font (14px)
            whiteSpace: 'nowrap', // Prevents text from wrapping
            minWidth: 'auto', // Prevents button from being unnecessarily wide
            textTransform: 'none', // Prevents all uppercase (optional)
            paddingX: 1.5, // Reduces horizontal padding
            ...sx, // Preserve any additional styles passed via props
          }}
        {...props} 
      >
        {/* Label can be the same in both styles */}
        Neue Zusammenfassung
      </Button>

      {/* The dialog itself */}
      <CreateSummaryDialog
        open={open}
        onClose={handleClose}
        courseId={courseId}
        courseName={courseName}
        files={files}
        filesError={filesError}
        onSummaryCreated={onSummaryCreated}
      />
    </>
  );
}

// Provide default props if user doesn't pass them
CreateSummaryButton.defaultProps = {
  variant: 'contained',
  color: 'secondary',
  size: 'medium',
};
