import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
 Dialog,
 DialogTitle,
 DialogContent,
 DialogActions,
 Button,
 TextField,
 Box,
 Typography,
 CircularProgress,
 IconButton,
 AppBar,
 Toolbar,
 useTheme
} from '@mui/material';
import ENDPOINTS from '../../utils/apiConfig';
import Icon from '../../components/Icon';
import FeedbackTable from '../../components/FeedbackTable';


const FeedbackDialog = ({ open, onClose, onFeedbackSubmitted }) => {
 const theme = useTheme();
 const location = useLocation();
 const [feedback, setFeedback] = useState([]);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);


 // Fetch all feedback entries
 const fetchFeedback = useCallback(async () => {
   console.log("===== FETCHING FEEDBACK START =====");
   setLoading(true);
   try {
     const token = localStorage.getItem('accessToken');
     const url = ENDPOINTS.FEEDBACK;
    
     // Check if user ID is missing from localStorage
     let userId = localStorage.getItem('userId');
     console.log(`Current user ID from localStorage: ${userId}`);
     
     if (!userId) {
       console.warn('User ID missing from localStorage, trying to fetch from profile');
       try {
         // Try to fetch profile data to get the user ID
         const profileResponse = await fetch(`${ENDPOINTS.USER}/profile`, {
           headers: {
             'Authorization': `Bearer ${token}`
           }
         });
         
         if (profileResponse.ok) {
           const profileData = await profileResponse.json();
           if (profileData && profileData.id) {
             console.log('Retrieved user ID from profile:', profileData.id);
             localStorage.setItem('userId', profileData.id);
             userId = profileData.id;
           }
         }
       } catch (profileError) {
         console.error('Error fetching user profile:', profileError);
       }
     }
    
     console.log("Fetching feedback from API...");
     const response = await fetch(url, {
       headers: {
         'Authorization': `Bearer ${token}`
       }
     });
    
     if (!response.ok) {
       throw new Error('Failed to fetch feedback');
     }
    
     const data = await response.json();
    
     // Log the raw data from server
     console.log(`Received ${data.length} feedback items from server`);
     
     // List all visibility values
     const visibilityValues = data.map(item => item.visibility);
     console.log("All visibility values in response:", visibilityValues);
     
     // Count private vs public
     const privateCount = data.filter(item => item.visibility === 'private').length;
     const publicCount = data.filter(item => item.visibility === 'public').length;
     const undefinedCount = data.filter(item => !item.visibility).length;
     
     console.log(`Visibility stats: ${privateCount} private, ${publicCount} public, ${undefinedCount} undefined`);
    
     // Process the data to add a voted_by_me flag and other necessary fields
     const myUserId = parseInt(userId);
     
     const processedData = data.map(item => {
       // Log detailed ownership information for each item
       console.log(`Processing item #${item.id}:`);
       
       // Log vote-related information
       console.log(`  - Item vote_count: ${item.vote_count}`);
       console.log(`  - Item voted_by_me: ${item.voted_by_me}`);
       console.log(`  - Item votes array length: ${item.votes?.length}`);
     
       // Ensure visibility is always defined
       const visibility = item.visibility || 'public';
     
       // Make sure vote_count is a number from the right source
       // API might provide vote_count directly or we might need to count votes array
       const voteCount = typeof item.vote_count === 'number' 
         ? item.vote_count 
         : (item.votes?.length || 0);
       
       return {
         ...item,
         // Keep the can_edit flag from the API response
         isOwn: item.can_edit === true,
         // Use voted_by_me directly from the API response 
         voted_by_me: !!item.voted_by_me,
         // Ensure vote_count is properly set
         vote_count: voteCount,
         // Ensure visibility is explicitly set
         visibility: visibility
       };
     });
    
     console.log("Processed data:", processedData);
     
     // Check what happens post-processing
     const processedPrivateCount = processedData.filter(item => item.visibility === 'private').length;
     const processedPublicCount = processedData.filter(item => item.visibility === 'public').length;
     
     console.log(`Post-processing: ${processedPrivateCount} private, ${processedPublicCount} public`);
     console.log("===== FETCHING FEEDBACK END =====");
     
     setFeedback(processedData);
     setError(null);
   } catch (err) {
     console.error('Error fetching feedback:', err);
     setError('Failed to load feedback. Please try again later.');
   } finally {
     setLoading(false);
   }
 }, []);


 useEffect(() => {
   if (open) {
     fetchFeedback();
   }
 }, [open, fetchFeedback]);


 // Handle vote
 const handleVote = async (feedbackId, hasVoted) => {
   console.log(`===== ${hasVoted ? 'REMOVING VOTE FROM' : 'VOTING ON'} FEEDBACK #${feedbackId} =====`);
   
   // Get the access token from local storage
   const accessToken = localStorage.getItem('accessToken');
   
   if (!accessToken) {
     console.error("Cannot vote: No access token found");
     alert("Du musst eingeloggt sein, um abstimmen zu können.");
     return;
   }
   
   try {
     // Always use the FEEDBACK_VOTE endpoint with appropriate method
     const voteUrl = ENDPOINTS.FEEDBACK_VOTE(feedbackId);
     
     console.log(`${hasVoted ? 'Removing vote from' : 'Adding vote to'} feedback #${feedbackId}`);
     console.log(`Using endpoint: ${voteUrl} with method: ${hasVoted ? 'DELETE' : 'POST'}`);
     
     // Make the request to vote
     const response = await fetch(voteUrl, {
       method: hasVoted ? 'DELETE' : 'POST',
       headers: hasVoted 
         ? {
             'Authorization': `Bearer ${accessToken}`
           }
         : {
             'Authorization': `Bearer ${accessToken}`,
             'Content-Type': 'application/json'
           }
     });
     
     if (!response.ok) {
       const errorText = await response.text();
       console.error(`${hasVoted ? 'Vote removal' : 'Vote addition'} failed:`, errorText);
       console.error(`Request details: ${voteUrl}, Method: ${hasVoted ? 'DELETE' : 'POST'}`);
       
       // If we get a 404 when removing a vote, the vote might already be removed in the backend
       // So we should update the UI to reflect this, even though the API call failed
       if (response.status === 404 && hasVoted) {
         console.log('Received 404 when removing vote - vote might already be removed in backend');
         console.log('Updating local state to reflect vote removal despite API error');
         
         // Silently refresh the feedback list to update the UI
         fetchFeedback();
         return;
       }
       
       alert(`Fehler beim ${hasVoted ? 'Entfernen der Stimme' : 'Abstimmen'}: ${response.status} ${response.statusText}`);
       return;
     }
     
     // Get the response data
     const data = await response.json();
     console.log('Vote response:', data);
     
     // Refresh feedback list
     fetchFeedback();
     
     console.log(`===== ${hasVoted ? 'VOTE REMOVAL' : 'VOTING'} COMPLETE =====`);
   } catch (error) {
     console.error('Error handling vote:', error);
     alert(`Fehler beim Verarbeiten der Stimme: ${error.message}`);
   }
 };


 // Handle creating new feedback
 const handleCreateFeedback = async (feedbackData) => {
   try {
     // Check if this is just a refresh request
     if (feedbackData.__action === 'refresh_only') {
       console.log('Refreshing feedback list only');
       fetchFeedback();
       return true;
     }
     
     const token = localStorage.getItem('accessToken');
     const url = ENDPOINTS.FEEDBACK;
    
     // Get context from URL (page and tab)
     const page = location.pathname;
     const queryParams = new URLSearchParams(location.search);
     const tab = queryParams.get('tab');
    
     // Add context to feedback data
     const enrichedFeedbackData = {
       ...feedbackData,
       page,
       ...(tab && { tab }),
     };
    
     const response = await fetch(url, {
       method: 'POST',
       headers: {
         'Authorization': `Bearer ${token}`,
         'Content-Type': 'application/json'
       },
       body: JSON.stringify(enrichedFeedbackData)
     });
    
     if (!response.ok) {
       throw new Error('Failed to submit feedback');
     }
    
     // Refresh feedback data
     fetchFeedback();
    
     if (onFeedbackSubmitted) {
       onFeedbackSubmitted('Dein Feedback wurde erfolgreich gesendet!');
     }
    
     return true;
   } catch (error) {
     console.error('Error submitting feedback:', error);
     throw error;
   }
 };

 // Handle deleting feedback
 const handleDeleteFeedback = async (feedbackId) => {
   try {
     // If feedbackId is null, just refresh the list
     if (feedbackId === null) {
       console.log('Refreshing feedback list only');
       fetchFeedback();
       return;
     }
     
     const token = localStorage.getItem('accessToken');
     const url = ENDPOINTS.FEEDBACK_DELETE(feedbackId);
     
     console.log(`Deleting feedback with ID: ${feedbackId}`);
     
     const response = await fetch(url, {
       method: 'DELETE',
       headers: {
         'Authorization': `Bearer ${token}`,
         'Content-Type': 'application/json'
       }
     });
     
     if (!response.ok) {
       throw new Error(`Failed to delete feedback: ${response.status}`);
     }
     
     // On success, refresh the feedback list
     console.log('Feedback successfully deleted');
     fetchFeedback();
     
     // Show success message
     if (onFeedbackSubmitted) {
       onFeedbackSubmitted('Dein Feedback wurde erfolgreich gelöscht!');
     }
     
   } catch (error) {
     console.error('Error deleting feedback:', error);
     alert('Fehler beim Löschen des Feedbacks. Bitte versuche es später erneut.');
   }
 };


 return (
   <Dialog
     open={open}
     onClose={onClose}
     maxWidth="xl"
     fullWidth
     PaperProps={{
       sx: {
         backgroundColor: theme.palette.background.default,
         borderRadius: '12px',
         height: 'calc(100vh - 80px)',
         width: 'calc(100% - 80px)',
         margin: 'auto',
         overflowY: 'hidden',
         display: 'flex',
         flexDirection: 'column'
       }
     }}
   >
     <Box sx={{ 
       display: 'flex', 
       justifyContent: 'space-between', 
       alignItems: 'center',
       py: 1.5, 
       px: 2.5,
       borderBottom: `1px solid ${theme.palette.divider}`,
       flexShrink: 0
     }}>
       <Box sx={{ 
         display: 'flex', 
         alignItems: 'center',
         gap: 3,
         maxWidth: '90%'
       }}>
         <Typography 
           variant="h6" 
           component="div" 
           sx={{ 
             fontWeight: 'bold',
             display: 'flex',
             alignItems: 'center',
             gap: 1,
             whiteSpace: 'nowrap'
           }}
         >
           <Icon iconName="feedback" style={{ fontSize: '1.2rem' }} />
           Feedback-Management
         </Typography>
         <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
           Wir freuen uns auf dein ehrliches Feedback! Bitte sei dabei so konkret wie möglich, damit wir deine Vorschläge möglichst gut umsetzen können.
         </Typography>
       </Box>
       <IconButton
         onClick={onClose}
         aria-label="close"
         sx={{ 
           width: '30px', 
           height: '30px',
           p: 0.5
         }}
       >
         <Icon iconName="cross" style={{ fontSize: '1.2rem' }} />
       </IconButton>
     </Box>
     
     <Box sx={{
       px: 2.5,
       pt: 2,
       pb: 4, // Add more bottom padding
       display: 'flex',
       flexDirection: 'column',
       flex: 1,
       minHeight: 0,
       overflow: 'hidden'
     }}>
       <Box sx={{ 
         flex: 1, 
         display: 'flex', 
         flexDirection: 'column', 
         minHeight: 0, 
         overflow: 'hidden'
       }}>
         {loading ? (
           <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
             <CircularProgress />
           </Box>
         ) : error ? (
           <Box sx={{ p: 3, textAlign: 'center' }}>
             <Typography color="error">{error}</Typography>
           </Box>
         ) : (
           <FeedbackTable
             feedback={feedback}
             onVote={handleVote}
             onCreateFeedback={handleCreateFeedback}
             onDeleteFeedback={handleDeleteFeedback}
           />
         )}
       </Box>
     </Box>
   </Dialog>
 );
};


export default FeedbackDialog;