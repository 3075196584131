import { useEffect, useRef } from 'react';
import { fetchSummaryCreationRequestById } from '../services/SummaryCreationRequestService';

const usePollSummaries = (
  creationRequestId,
  onUpdate,                      // stable callback => handleCreationRequestUpdate
  setSummaryError,               // stable callback => for errors
  setCurrentSummaryCreationRequestId  // stable callback => to stop polling
) => {
  const forcedCompleteRef = useRef(false);
  const lastProgressRef = useRef(null);
  const lastProgressChangedTimeRef = useRef(0);
  const prevRequestIdRef = useRef(null);

  useEffect(() => {
    // 1) If no ID, do nothing
    if (!creationRequestId) return;

    // 2) If this is a *new* ID (diff from previous), reset
    if (creationRequestId !== prevRequestIdRef.current) {
      console.log('[usePollSummaries] New creationRequestId => reset polling refs');
      forcedCompleteRef.current = false;
      lastProgressRef.current = null;
      lastProgressChangedTimeRef.current = 0;
      prevRequestIdRef.current = creationRequestId;
    } else {
      console.log('[usePollSummaries] Same creationRequestId => keep refs');
    }

    let intervalId;
    const STUCK_THRESHOLD_MS = 5000;
    const POLL_INTERVAL_MS = 3000;

    const pollSummary = async () => {
      if (forcedCompleteRef.current) return; // skip if forced complete

      try {
        const data = await fetchSummaryCreationRequestById(creationRequestId);
        console.log('[usePollSummaries] Polled data =>', data);

        // Check if status = failed
        if (data.status === 'failed' || data.error_details) {
          data.status = 'failed';
          onUpdate(data);
          setSummaryError?.('Summary creation failed.');
          setCurrentSummaryCreationRequestId(null);
          clearInterval(intervalId);
          return;
        }

        // Evaluate progress -> bulletpoints_progress_percentage
        const currentProgress = data.bulletpoints_progress_percentage ?? 0;

        // If we are >=90% for > 5s => force completion
        if (currentProgress >= 90) {
          const now = Date.now();
          const timeSinceLastChange = now - lastProgressChangedTimeRef.current;

          if (lastProgressRef.current !== null && timeSinceLastChange > STUCK_THRESHOLD_MS) {
            console.warn('[usePollSummaries] Forcing completion at ' + currentProgress + '% after ' + timeSinceLastChange / 1000 + 's');
            data.status = 'completed';
            data.bulletpoints_progress_percentage = 100;
            forcedCompleteRef.current = true;
            onUpdate(data);
            setCurrentSummaryCreationRequestId(null);
            clearInterval(intervalId);
            return;
          }
        }

        // If progress changed significantly => update time
        if (
          lastProgressRef.current === null ||
          Math.abs(currentProgress - lastProgressRef.current) >= 0.01
        ) {
          lastProgressRef.current = currentProgress;
          lastProgressChangedTimeRef.current = Date.now();
        }

        // Normal update
        onUpdate(data);

        // If status is not in_progress or pending => done
        if (data.status !== 'in_progress' && data.status !== 'pending') {
          setCurrentSummaryCreationRequestId(null);
          clearInterval(intervalId);
        }
      } catch (err) {
        console.error('[usePollSummaries] Error:', err);
        setSummaryError?.('Error fetching summary creation request details');
        clearInterval(intervalId);
      }
    };

    // Kick off
    pollSummary();
    intervalId = setInterval(pollSummary, POLL_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [creationRequestId, onUpdate, setSummaryError, setCurrentSummaryCreationRequestId]);
};

export default usePollSummaries;
