import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  Stack,
  FormControlLabel,
  Switch,
  useTheme
} from '@mui/material';
import ENDPOINTS from '../../utils/apiConfig';

// Import the option arrays from constants or define them here
const FEEDBACK_TYPES = [
  { value: 'bug', label: 'Bug/Fehler' },
  { value: 'idea', label: 'Idee/Wunsch' },
  { value: 'improvement', label: 'Verbesserungsvorschlag' },
  { value: 'criticism', label: 'Kritik' },
  { value: 'other', label: 'Lob' },
  { value: 'other', label: 'Anderes' }
];

const PRIORITY_LEVELS = [
  { value: 'low', label: 'Niedrig' },
  { value: 'medium', label: 'Mittel' },
  { value: 'high', label: 'Hoch' }
];

const EMOTION_OPTIONS = [
  { value: 'happy', label: 'Fröhlich ✨' },
  { value: 'amused', label: 'Amüsiert 🥳' },
  { value: 'woot', label: 'WOOT WOOT 🔥' },
  { value: 'confused', label: 'Verwirrt 🤔' },
  { value: 'annoyed', label: 'Genervt 🫠' },
  { value: 'disappointed', label: 'Enttäuscht 🥺' },
  { value: 'frustrated', label: 'Frustriert 😓' },
  { value: 'sad', label: 'Traurig 😥' },
  { value: 'dead', label: 'Dead inside 🤡' }
];

const FUNCTION_OPTIONS = [
  { value: 'courses', label: 'Meine Kurse' },
  { value: 'files', label: 'Dateien' },
  { value: 'questionsets', label: 'Fragesets' },
  { value: 'summaries', label: 'Zusammenfassungen' },
  { value: 'auth', label: 'Login/Registrierung' },
  { value: 'eastereggs', label: 'EasterEggs' },
  { value: 'other', label: 'Anderes' }
];

const EditFeedbackDialog = ({ open, onClose, feedback, onFeedbackUpdated }) => {
  const theme = useTheme();
  
  // Form state
  const [feedbackType, setFeedbackType] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('medium');
  const [emotion, setEmotion] = useState('');
  const [functionValue, setFunctionValue] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  
  // UI state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  // Populate form with feedback data when opened
  useEffect(() => {
    if (open && feedback) {
      setFeedbackType(feedback.feedback_type || '');
      setDescription(feedback.description || '');
      setPriority(feedback.priority || 'medium');
      setEmotion(feedback.emotion || '');
      setFunctionValue(feedback.function || '');
      setIsPrivate(feedback.visibility === 'private');
      setError(null);
    }
  }, [open, feedback]);

  // Form validation
  const isFormValid = () => {
    return feedbackType && description.trim();
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isFormValid()) {
      setError('Bitte fülle die Pflichtfelder aus (Typ und Beschreibung)');
      return;
    }
    
    setIsSubmitting(true);
    setError(null);
    
    try {
      const feedbackData = {
        feedback_type: feedbackType,
        description,
        priority,
        emotion,
        function: functionValue,
        visibility: isPrivate ? 'private' : 'public'
      };
      
      const token = localStorage.getItem('accessToken');
      const url = ENDPOINTS.FEEDBACK_EDIT(feedback.id);
      
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(feedbackData)
      });
      
      if (!response.ok) {
        throw new Error(`Failed to update feedback: ${response.status}`);
      }
      
      // Close the dialog and notify parent component
      onClose();
      if (onFeedbackUpdated) {
        onFeedbackUpdated();
      }
      
    } catch (error) {
      console.error('Error updating feedback:', error);
      setError('Fehler beim Aktualisieren des Feedbacks. Bitte versuche es später erneut.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle dialog close - reset state
  const handleDialogClose = () => {
    // Don't reset the form state here, that happens in useEffect when dialog reopens
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleDialogClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: {
          borderRadius: '20px',
          minHeight: '680px',
          maxHeight: '90vh',
          width: '95%',
          margin: 'auto'
        }
      }}
    >
      <DialogTitle sx={{ py: 3 }}>Feedback bearbeiten</DialogTitle>
      
      <DialogContent sx={{ pt: 5, pb: 4 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {/* Description field with separate label */}
            <Box>
              <Typography 
                variant="subtitle1" 
                gutterBottom 
                sx={{ 
                  fontWeight: 500,
                  mb: 1,
                  '&::after': {
                    content: '" *"',
                    color: 'error.main'
                  }
                }}
              >
                Beschreibung
              </Typography>
              <TextField
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                placeholder="Beschreibe dein Feedback, deine Idee oder den gefundenen Fehler, teile uns mit, was immer du uns sagen möchtest."
                error={error && !description.trim()}
                variant="outlined"
                hiddenLabel
              />
            </Box>
            
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <FormControl fullWidth required error={error && !feedbackType}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mb: 0.75, 
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                    '&::after': {
                      content: '"*"',
                      color: 'error.main',
                      ml: 0.5
                    }
                  }}
                >
                  Wähle die Art deines Feedbacks
                </Typography>
                <Select
                  value={feedbackType}
                  displayEmpty
                  onChange={(e) => setFeedbackType(e.target.value)}
                  placeholder="Wähle einen Typ"
                >
                  <MenuItem disabled value="">
                    <em>Bitte wählen</em>
                  </MenuItem>
                  {FEEDBACK_TYPES.map((type) => (
                    <MenuItem key={`${type.value}-${type.label}`} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              <FormControl fullWidth>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mb: 0.75, 
                    fontWeight: 500,
                    color: theme.palette.text.secondary
                  }}
                >
                  Wie wichtig ist dein Anliegen?
                </Typography>
                <Select
                  value={priority}
                  displayEmpty
                  onChange={(e) => setPriority(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Optional</em>
                  </MenuItem>
                  {PRIORITY_LEVELS.map((level) => (
                    <MenuItem key={level.value} value={level.value}>
                      {level.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <FormControl fullWidth>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mb: 0.75, 
                    fontWeight: 500,
                    color: theme.palette.text.secondary
                  }}
                >
                  Wie fühlst du dich bei diesem Thema?
                </Typography>
                <Select
                  value={emotion}
                  displayEmpty
                  onChange={(e) => setEmotion(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Optional</em>
                  </MenuItem>
                  {EMOTION_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              <FormControl fullWidth>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mb: 0.75, 
                    fontWeight: 500, 
                    color: theme.palette.text.secondary
                  }}
                >
                  Zu welchem Bereich gehört dein Feedback?
                </Typography>
                <Select
                  value={functionValue}
                  displayEmpty
                  onChange={(e) => setFunctionValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Optional</em>
                  </MenuItem>
                  {FUNCTION_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            
            <FormControlLabel
              control={
                <Switch
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                />
              }
              label="Privat senden (nur für Admins sichtbar)"
            />
          </Stack>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ justifyContent: 'space-between', padding: '0 24px 24px 24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            variant="outlined"
            onClick={handleDialogClose}
            sx={{
              borderWidth: 2,
              borderStyle: 'solid',
              px: 3,
              py: 0.8,
              fontSize: '0.9rem'
            }}
          >
            Abbrechen
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting || !isFormValid()}
            sx={{
              px: 3,
              py: 1,
              minWidth: '180px',
              fontSize: '0.9rem',
              fontWeight: 'medium'
            }}
          >
            {isSubmitting ? 'Speichern...' : 'Änderungen speichern'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

EditFeedbackDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  feedback: PropTypes.object,
  onFeedbackUpdated: PropTypes.func
};

export default EditFeedbackDialog; 