import { useEffect, useRef } from 'react';
import { fetchQuestionCreationRequestById } from '../services/QuestionCreationRequestService';

const usePollQuizzes = (
  creationRequestId,
  onUpdate,                     // stable callback
  setQuizError,                 // stable callback
  setCurrentCreationRequestId   // stable callback
) => {
  // We store everything in refs so it doesn't reset on re-renders
  const forcedCompleteRef = useRef(false);
  const lastProgressRef = useRef(null);
  const lastProgressChangedTimeRef = useRef(0);
  const prevRequestIdRef = useRef(null);

  useEffect(() => {
    // 1) If no ID, do nothing
    if (!creationRequestId) return;

    // 2) If this is a *new* ID (diff from previous), we reset
    if (creationRequestId !== prevRequestIdRef.current) {
      console.log('[usePollQuizzes] New creationRequestId => reset polling refs');
      forcedCompleteRef.current = false;
      lastProgressRef.current = null;
      lastProgressChangedTimeRef.current = 0;
      prevRequestIdRef.current = creationRequestId; // track this ID
    } else {
      // if it’s the *same* ID, do *not* reset
      console.log('[usePollQuizzes] Same creationRequestId => keep refs');
    }

    let intervalId;
    const STUCK_THRESHOLD_MS = 5000;
    const POLL_INTERVAL_MS = 3000;

    const pollQuiz = async () => {
      // If forced complete, skip
      if (forcedCompleteRef.current) return;

      try {
        const data = await fetchQuestionCreationRequestById(creationRequestId);
        console.log('[usePollQuizzes] Polled data =>', data);

        // Check if any file is failed
        const requestFiles = data.request_files || [];
        const anyFileFailed = requestFiles.some((f) => f.status === 'failed');
        if (anyFileFailed || data.error_details?.errors?.length > 0) {
          data.status = 'failed';
          onUpdate(data);
          setQuizError('Quiz creation failed.');
          setCurrentCreationRequestId(null);
          clearInterval(intervalId);
          return;
        }

        // Evaluate progress
        const currentProgress = Math.round((data.progress_percentage ?? 0) * 100) / 100;

        // Check if stuck >=90% for >5s
        if (currentProgress >= 90) {
          const now = Date.now();
          const timeSinceLastChange = now - lastProgressChangedTimeRef.current;

          // If we've previously recorded a time and it hasn't changed in 5s => forced
          if (
            lastProgressRef.current !== null &&
            timeSinceLastChange > STUCK_THRESHOLD_MS
          ) {
            console.warn(
              '[usePollQuizzes] Forcing completion at ' +
                currentProgress +
                '% after ' +
                timeSinceLastChange / 1000 +
                's'
            );
            data.status = 'completed';
            data.progress_percentage = 100;
            forcedCompleteRef.current = true;
            onUpdate(data);
            setCurrentCreationRequestId(null);
            clearInterval(intervalId);
            return;
          }
        }

        // If the progress changed by >= 0.01, update the refs
        if (
          lastProgressRef.current === null ||
          Math.abs(currentProgress - lastProgressRef.current) >= 0.01
        ) {
          lastProgressRef.current = currentProgress;
          lastProgressChangedTimeRef.current = Date.now();
        }

        // Normal update
        onUpdate(data);

        // If server says not in_progress => done
        if (data.status !== 'in_progress' && data.status !== 'pending') {
          setCurrentCreationRequestId(null);
          clearInterval(intervalId);
        }
      } catch (err) {
        console.error('[usePollQuizzes] Error:', err);
        setQuizError('Error fetching creation request details');
        clearInterval(intervalId);
      }
    };

    // Kick off
    pollQuiz();
    intervalId = setInterval(pollQuiz, POLL_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [
    creationRequestId, 
    onUpdate, 
    setQuizError, 
    setCurrentCreationRequestId
  ]);
};

export default usePollQuizzes;
