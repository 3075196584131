import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import ENDPOINTS from '../../utils/apiConfig';

export default function EditSummaryDialog({ summaryId, open, onClose, onSummaryUpdated }) {
    const [summaryName, setSummaryName] = useState('');

    const fetchSummaryDetails = async () => {
        // Dummy function for now
        console.log('Fetching summary details for ID:', summaryId);
        setSummaryName('Dummy Summary Name'); // Just set a dummy name for now
    };

    useEffect(() => {
        if (open) {
            fetchSummaryDetails();
        }
    }, [open, summaryId]);

    const handleSave = async (event) => {
        event.preventDefault();
        console.log('Would update summary with name:', summaryName);
        // Show that this is not implemented yet
        alert('Diese Funktion ist noch nicht verfügbar.');
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{
            sx: {
                borderRadius: '20px'
            }
        }}>
            <DialogTitle>Zusammenfassungs-Namen bearbeiten</DialogTitle>
            <form onSubmit={handleSave}>
                <DialogContent>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Diese Funktion ist momentan noch in Entwicklung.
                    </Alert>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Zusammenfassungsname"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={summaryName}
                        onChange={(e) => setSummaryName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button type="submit" color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
} 