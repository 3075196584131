import React, { useState, useEffect } from 'react';
import RegistrationForm from '../../features/RegistrationForm/RegistrationForm';
import Button from '@mui/material/Button';
import { useNavigate, useOutletContext } from 'react-router-dom'; 
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material';
import usePublicTheme from '../../hooks/usePublicTheme';
import './RegistrationPage.css';

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { gradients, neutral } = usePublicTheme();

  // Get the updateThemeColor function from the PublicLayout context
  const { updateThemeColor } = useOutletContext() || { updateThemeColor: () => {} };

  // Set the theme color when component mounts
  useEffect(() => {
    updateThemeColor('purple'); // Use purple theme for registration page
  }, [updateThemeColor]);

  const handleBack = () => {
    navigate('/');
  };

  const handleRegistrationSuccess = () => {
    setShowForm(false); // Hide the form
    setTimeout(() => {
      setShowSuccessMessage(true); // Show the success message after a delay
    }, 500); // Adjust the timing as needed
    setTimeout(() => navigate('/login'), 5000); // Redirect after 5 seconds
  };

  // Keep fieldCompletion state for form validation, but it won't affect background anymore
  const [fieldCompletion, setFieldCompletion] = useState({
    firstName: false,
    lastName: false,
    username: false,
    gender: false,
    birthday: false,
    email: false,
    password: false,
    confirmPassword: false,
    hasAgreedToTerms: false
  });

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        background: gradients.secondaryLight,
        backgroundAttachment: 'fixed',
        position: 'relative',
        pb: 8, // Add padding at the bottom for the footer
      }}
    >
      <Container component="main" maxWidth="sm" sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        height: '100vh', 
        justifyContent: 'center', 
        position: 'relative', 
        zIndex: 2 
      }}>
        {showForm && (
          <>
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: neutral[950], mb: 4 }}>
              Hier kannst du dein Konto erstellen
            </Typography>
            <Box sx={{
                width: 550,
                backgroundColor: 'rgba(255, 255, 255, 0.97)',
                boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.3)',
                borderRadius: '30px',
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <RegistrationForm setFieldCompletion={setFieldCompletion} onSuccess={handleRegistrationSuccess} />
            </Box>
            <Button 
              variant="contained" 
              sx={{ 
                mt: 2, 
                backgroundColor: 'white', 
                color: neutral[800],
                '&:hover': {
                  backgroundColor: neutral[50],
                }
              }} 
              onClick={handleBack}
            >
              Zurück zur Startseite
            </Button>
          </>
        )}
        {showSuccessMessage && (
          <>
            <Typography 
              variant="h5" 
              color={neutral[950]}
              sx={{
                mt: 4, // Increased margin-top for more spacing
                fontSize: '6rem', // Larger font size
                textShadow: '0 6px 8px rgba(0,0,0,0.3)', // Subtle text shadow for a lift effect
                animation: 'fadeIn 5s ease-in-out forwards', // Using the fadeIn animation
              }}
            >
              Willkommen bei Thinki
            </Typography>
            <Typography
              variant="h5" // Smaller text for secondary information
              color={neutral[800]} // Less prominent color
              sx={{
                mt: 2, // Top margin for spacing from the previous message
                animation: 'fadeIn 8s ease-in-out forwards', 
              }}
            >
              Bitte bestätige deine Email-Adresse und logge dich nun ein. 
            </Typography>
          </>
        )}
      </Container>
    </Box>
  );
};

export default RegistrationPage;
