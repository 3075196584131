import React, { useEffect } from 'react';
import { useNavigate, useSearchParams, useOutletContext } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ENDPOINTS from '../../utils/apiConfig';
import { ThemeProvider } from '@mui/material';
import { authTheme } from '../../styles/authTheme';
import usePublicTheme from '../../hooks/usePublicTheme';

const ConfirmEmailPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { gradients } = usePublicTheme();
  const uid = searchParams.get('uid');
  const token = searchParams.get('token');
  
  // Get the updateThemeColor function from the PublicLayout context
  const { updateThemeColor } = useOutletContext() || { updateThemeColor: () => {} };

  // Set the theme color when component mounts
  useEffect(() => {
    updateThemeColor('purple'); // Use purple theme
  }, [updateThemeColor]);

  useEffect(() => {
    const handleActivateAccount = async () => {
      if (!uid || !token) {
        console.error('Activation parameters missing');
        navigate('/error'); // navigate to an error page if parameters are missing
        return;
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ uid, token })
      };

      try {
        const response = await fetch(`${ENDPOINTS.ACTIVATE}`, requestOptions);
        const data = await response.json();

        if (response.ok) {
          navigate('/account-activated'); // Navigate to a success page
        } else {
          const error = data.error || 'An unexpected error occurred';
          console.error(data.error || 'An unexpected error occurred');
          navigate('/error', { state: { error } });
        }
      } catch (error) {
        console.error('Network error:', error);
        navigate('/error');
      }
    };

    handleActivateAccount();
  }, [navigate, uid, token]);

  return (
    <ThemeProvider theme={authTheme}> 
      <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
          background: gradients.secondaryLight,
          backgroundAttachment: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          p: 3
        }}
      >
        <CircularProgress sx={{ color: 'white', mb: 3 }} size={60} />
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white', fontWeight: 500 }}>
          Aktiviere deinen Account...
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default ConfirmEmailPage;


