/**
 * Utility functions for securely fetching and displaying images
 */

/**
 * Securely fetches an image using authentication
 * @param {string} url - The URL to fetch the image from
 * @returns {Promise<string>} - A Promise that resolves to a Blob URL for the image
 */
export const fetchSecureImage = async (url) => {
  const accessToken = localStorage.getItem('accessToken');
  
  console.log(`Fetching image from: ${url}`);
  
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    
    console.log(`Image fetch status: ${response.status}`);
    
    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        console.error('Authentication error when fetching image');
        throw new Error('Unauthorized access to image');
      }
      
      // Try to get more details from the error response
      let errorDetails = '';
      try {
        const errorData = await response.text();
        errorDetails = errorData;
      } catch (e) {
        // Ignore error parsing errors
      }
      
      console.error(`Failed to fetch image: ${response.status}`, errorDetails);
      throw new Error(`Failed to fetch image: ${response.status}`);
    }
    
    // Get the image data as a blob
    const blob = await response.blob();
    console.log(`Successfully fetched image, size: ${blob.size} bytes, type: ${blob.type}`);
    
    // Create a URL for the blob
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error fetching secure image:', error);
    throw error;
  }
};

/**
 * Revokes a blob URL to free up memory
 * @param {string} url - The blob URL to revoke
 */
export const revokeBlobUrl = (url) => {
  if (url && url.startsWith('blob:')) {
    URL.revokeObjectURL(url);
  }
}; 