import React, { useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';
import { authTheme } from '../../styles/authTheme';
import usePublicTheme from '../../hooks/usePublicTheme';

const ActivationErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { gradients } = usePublicTheme();
  const { error } = location.state || { error: "Activation failed due to an unexpected issue." }; // Default message
  
  // Get the updateThemeColor function from the PublicLayout context
  const { updateThemeColor } = useOutletContext() || { updateThemeColor: () => {} };

  // Set the theme color when component mounts
  useEffect(() => {
    updateThemeColor('purple'); // Use purple theme
  }, [updateThemeColor]);

  const handleNavigateToHome = () => {
    navigate('/');
  };

  return (
    <ThemeProvider theme={authTheme}> 
      <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
          background: gradients.secondaryLight,
          backgroundAttachment: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          p: 3
        }}
      >
        <Box 
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '16px',
            p: 4,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            maxWidth: '600px',
            width: '90%'
          }}
        >
          <Box sx={{ mb: 3 }}>
            <iframe src="https://giphy.com/embed/RWUqVYucDBD4A" width="320" height="211" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
          </Box>
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 600, color: authTheme.palette.primary.main }}>
            Oopsie! Da ist bei uns etwas ordentlich schief gelaufen.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
            Dein Account konnte nicht aktiviert werden.
          </Typography>
          <Typography variant="body1" paragraph>
            Vielleicht ist der Link schon abgelaufen?
          </Typography>
          <Typography variant="body1" paragraph sx={{ mb: 3 }}>
            Versuche es gerne noch einmal oder wende dich an unseren Support
          </Typography>
          <Button 
            variant="contained" 
            onClick={handleNavigateToHome} 
            sx={{ 
              padding: '10px 30px',
              fontSize: '16px'
            }}
          >
            Zur Startseite zurück
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ActivationErrorPage;
